import { Box, Typography, useTheme } from "@mui/material";

export default function Summary({ summary }) {
	const theme = useTheme();
	return (
		<Box>
			<Box display="flex" flexDirection="column" alignItems="left" p={2}>
				{summary.summary.map((paragraph, i) => (
					<Typography
						key={i}
						color={theme.palette.neutral.light}
						variant="h5"
						lineHeight="24px"
						fontWeight="600"
						style={{ textIndent: "2em", marginBottom: "1.5em" }}
					>
						{paragraph}
					</Typography>
				))}
			</Box>
		</Box>
	);
}
