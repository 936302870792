import { Box, useTheme, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { firebaseClient, logAnalyticsEvent } from "../../firebase/firebaseClient";
import { Navigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import GoogleButton from "react-google-button";
import TextField from "@mui/material/TextField";

import {
	getAuth,
	sendSignInLinkToEmail,
	signInWithRedirect,
	GoogleAuthProvider,
	getRedirectResult,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(firebaseClient);

const actionCodeSettings = {
	// URL you want to redirect back to. The domain (www.example.com) for this
	// URL must be in the authorized domains list in the Firebase Console.
	url: window.location.origin,
	// This must be true.
	handleCodeInApp: true,
};

async function signInEmail(emailString, setSendSuccess, setIsValidEmail) {
	sendSignInLinkToEmail(auth, emailString, actionCodeSettings)
		.then(() => {
			// The link was successfully sent. Inform the user.
			// Save the email locally so you don't need to ask the user for it again
			// if they open the link on the same device.
			window.localStorage.setItem("emailForSignIn", emailString);
			setSendSuccess(true);
			// ...
		})
		.catch((error) => {
			console.error(error);
			setIsValidEmail(false);
		});
}

async function signInGoogle() {
	const provider = new GoogleAuthProvider();
	signInWithRedirect(auth, provider);
	getRedirectResult().catch((error) => {
		console.error(error);
	});
}

const SignIn = () => {
	const [user] = useAuthState(auth);
	const theme = useTheme();

	logAnalyticsEvent("Sign In Page viewed");

	const [emailString, setEmailString] = useState("");
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [sendSuccess, setSendSuccess] = useState(false);
	if (user) {
		return <Navigate to="/" />;
	}
	if (sendSuccess) {
		return (
			<Box display="flex" flexDirection="column" alignItems="center">
				<Box justifyContent="center" alignItems="center">
					<Box justifyContent="center" textAlign="center">
						<Typography
							variant="h1"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ m: "10px 10px 5px 10px" }}
						>
							Login Link Sent.
						</Typography>
						<Typography
							variant="h5"
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.secondary.main}
						>
							Please check your email.
						</Typography>
					</Box>
				</Box>
			</Box>
		);
	} else {
		return (
			<Box display="flex" flexDirection="column" alignItems="center" p={2}>
				<Box justifyContent="center" alignItems="center" p={2}>
					<Box justifyContent="center" textAlign="center">
						<Typography
							variant="h1"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ m: "10px 10px 5px 10px" }}
						>
							Sign In
						</Typography>
						<Typography
							variant="h5"
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.secondary.main}
						>
							Choose from any of the auth providers below, or login with email.
						</Typography>
					</Box>
				</Box>

				<Box
					mx={1}
					minWidth={450}
					maxWidth={450}
					display="flex"
					justifyContent="center"
					alignItems="center"
					backgroundColor={theme.palette.primary.accent}
					p={1}
				>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						component="form"
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							sx={{ m: 1, width: "45ch" }}
							placeholder="Sign in with email"
							label="Email"
							variant="standard"
							onChange={(event) => {
								setEmailString(event.target.value);
							}}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									event.preventDefault();
									signInEmail(emailString, setSendSuccess, setIsValidEmail);
								}
							}}
							error={!isValidEmail}
							helperText={!isValidEmail && "Please enter a valid email address"}
						/>
						<IconButton
							color={theme.palette.neutral.light}
							onClick={() => {
								signInEmail(emailString, setSendSuccess, setIsValidEmail);
							}}
						>
							<NavigateNextIcon />
						</IconButton>
					</Box>
				</Box>
				<Box
					justifyContent="center"
					textAlign="center"
					minWidth={450}
					maxWidth={450}
					backgroundColor={theme.palette.primary.accent}
				>
					<Typography
						variant="h6"
						color={theme.palette.neutral.light}
						fontWeight="bold"
						sx={{ m: "10px 10px 5px 10px" }}
					>
						Or
					</Typography>
				</Box>
				<Box
					mx={1}
					minWidth={450}
					maxWidth={450}
					display="flex"
					flexDirection="row"
					flexWrap="wrap"
					justifyContent="center"
					alignItems="center"
					backgroundColor={theme.palette.primary.accent}
					p={2}
				>
					<Box p={2}>
						<GoogleButton onClick={() => signInGoogle()}></GoogleButton>
					</Box>
				</Box>
			</Box>
		);
	}
};
export default SignIn;
