import * as React from "react";
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Card, Box, CardContent, Typography, Collapse, IconButton, CardActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyCard from './keyCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useTheme } from "@emotion/react";
  export default function CatCard({ data, key_sentences }) {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const ExpandMore = styled((props) => {
      const { expand, ...other } = props;
      return <IconButton {...other} />;
    })(({ theme, expand }) => ({
      transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    }));

    return (
      <Card sx={{ backgroundColor: theme.palette.primary.accent, color: theme.palette.secondary.main }}>
        <CardContent>

          <Box p ={2}>
            <Typography variant="h5" >
              {data.name} 
            </Typography>
          </Box>
          {/* <Box p ={2}>
            <Typography variant="h5" paragraph>{data.explanation}</Typography>
          </Box> */}
          
          {/* ... more code using data object */}
        </CardContent>
        <CardActions disableSpacing>

                <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                    <ExpandMoreIcon />
                </ExpandMore>                     


        </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
      {/* Additional content here */}


      
      

      <Container maxWidth ="xl">
      <Grid container spacing={2} justifyContent="center">
      {data.sentences.map((sentence) => {
        // Check if the Value is not empty

      return (

        <Grid item xs={12} sm={12} md={12} lg={12} key={data.id}>
          <KeyCard sentence={sentence} text = {key_sentences[sentence.id]}/>
        </Grid>

      );
    })}
    </Grid>
    </Container>



      {/* Additional content here */}
    </CardContent>
  </Collapse>
      </Card>
    );
}