import { useState } from "react";
import { styled, IconButton, Box, Tooltip, Typography, useTheme, Grid, Container } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import getBSValues from "./bsalternates";
import getISValues from "./isalternates";
import getCFValues from "./cfalternates";
import getEquityValues from "./equityalternates";
import getRatios from "./ratioAlternates";
import FSCard from "./fsCard"; // Adjust the path as necessary
import SSCard from "./ssCard";
import noData from "../../../components/NoData";

export default function SnapShot({ data,priceF }) {
  const theme = useTheme();
  const BalanceSheetTotals = JSON.parse(data["BalanceSheetTotals"]);
  const IncomeStatementTotals = JSON.parse(data["IncomeStatementTotals"]);
  const CashFlowTotals = JSON.parse(data["CashFlowTotals"]);

  

  const bsAccounts = getBSValues({ BalanceSheetTotals });
  const isAccounts = getISValues({ IncomeStatementTotals });
  const cfAccounts = getCFValues({ CashFlowTotals });
  const equityAccounts=getEquityValues({BalanceSheetTotals})

  const ratios = getRatios(bsAccounts, isAccounts, cfAccounts,priceF,equityAccounts);
  const liquidRatios = {
    Current: ratios["Current"],
    Quick: ratios["Quick"],
    DebtEquity: ratios["DebtEquity"],
  };
  const profitRatios = {
    GrossMargin: ratios["GrossMargin"],
    OpMargin: ratios["OpMargin"],
    NetMargin: ratios["NetMargin"],
  };
  const cashRatios = {
    OpCF: ratios["OpCF"],
    CFSales: ratios["CFSales"],
    CFIncome: ratios["CFIncome"],
    //Maybe CFMargin if created
  };
  const liquidAccounts = {
    Assets: bsAccounts["Assets"],
    CurrentAssets: bsAccounts["CurrentAssets"],
    Liabilities: bsAccounts["Liabilities"],
    CurrentLiabilities: bsAccounts["CurrentLiabilities"],
  };
  const profitAccounts = {
    Revenue: isAccounts["Revenue"],
    COGS: isAccounts["COGS"],
  };
  const cashAccounts = {
    CFOperating: cfAccounts["CFOperating"],
    CFInvesting: cfAccounts["CFInvesting"],
    CFFinancing: cfAccounts["CFFinancing"],
    TotalCFChange: cfAccounts["TotalCFChange"],
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [expandedStates, setExpandedStates] = useState([false, false, false, false, false, false]);
  const areAnyExpanded = expandedStates.some(state => state === true);
  const toggleExpandAll = () => {
    setExpandedStates(expandedStates.map(() => !areAnyExpanded));
  };

  const handleToggle = (index) => {
    const newStates = [...expandedStates];
    newStates[index] = !newStates[index];
    setExpandedStates(newStates);
  };

  if (Object.keys(CashFlowTotals).length < 1 && 
      Object.keys(IncomeStatementTotals).length < 1 &&
      Object.keys(BalanceSheetTotals).length < 1) {
    return noData("SnapShot");
  }

  return (
    <Container maxWidth="xl">
      <Box textAlign={"right"} p={2} display="flex" alignItems="center" justifyContent={"space-between"}>
        <Typography color={theme.palette.neutral.main} variant="h6" style={{ marginRight: '50px' }}>Values in thousands</Typography>
        
          <ExpandMore
              expand={areAnyExpanded} onClick={() => toggleExpandAll(areAnyExpanded)}
            >
              <Tooltip title={areAnyExpanded ? "Collapse All": "Expand All"}>
              <ExpandMoreIcon />
              </Tooltip>
            </ExpandMore>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} key={"balance-sheet"}>
          <FSCard data={bsAccounts} text={"Balance Sheet"} expanded={expandedStates[0]} handleToggle={() => handleToggle(0)} ExpandMore={ExpandMore} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} key="income-statement">
          <FSCard data={isAccounts} text={"Income Statement"} expanded={expandedStates[1]} handleToggle={() => handleToggle(1)} ExpandMore={ExpandMore} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} key="cash-flow">
          <FSCard data={cfAccounts} text={"Statement of Cash Flow"} expanded={expandedStates[2]} handleToggle={() => handleToggle(2)} ExpandMore={ExpandMore} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} key="liquidity">
          <SSCard
            ratios={liquidRatios}
            accounts={liquidAccounts}
            text="Liquidity Snap Shot"
            expanded={expandedStates[3]} handleToggle={() => handleToggle(3)}
            ExpandMore = {ExpandMore}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} key="profitability">
          <SSCard
            ratios={profitRatios}
            accounts={profitAccounts}
            text="Profitability Snap Shot"
            expanded={expandedStates[4]} handleToggle={() => handleToggle(4)}
            ExpandMore = {ExpandMore}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} key="cash">
          <SSCard
            ratios={cashRatios}
            accounts={cashAccounts}
            text="Cash Snap Shot"
            expanded={expandedStates[5]} handleToggle={() => handleToggle(5)}
            ExpandMore = {ExpandMore}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
