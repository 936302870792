import { firebaseClient } from "../firebase/firebaseClient";
import { doc, getDoc, getFirestore } from "firebase/firestore";

import { useState, useEffect } from "react";

import { useDocument } from "react-firebase-hooks/firestore";

const db = getFirestore(firebaseClient);

//userFollowing would be an empty array initially, and
//userRef would be null
export function useFollowing(user) {
	const [userFollowing, setUserFollowing] = useState([]);
	const [followingLoading, setLoading] = useState(true);
	const [userDoc] = useDocument(user && doc(db, "users", user.uid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});
	useEffect(() => {
		async function getFollowingReports() {
			if (user) {
				const userRef = doc(db, "users", user.uid);

				const userDoc = await getDoc(userRef);
				setUserFollowing(userDoc.data().following || []);
				setLoading(false);
			}
		}
		getFollowingReports();
	}, [userDoc, user]);

	return { userFollowing: userFollowing, followingLoading: followingLoading };
}
