import * as React from "react";
import { Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";

function Copyright() {
	return (
		<React.Fragment>
			{"© "}
			<Link color="inherit" href="/">
				Abridged Due Diligence
			</Link>{" "}
			{new Date().getFullYear()}
		</React.Fragment>
	);
}

export default function Footer() {
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100px"
			position="relative"
			clear="both"
			width="100%"
		>
			<Box>
				<Copyright />
			</Box>
			<Box>
				<Link
					color="inherit"
					href={require("./tos.pdf")}
					download="Abridged-Due-Diligence-TOS.pdf"
				>
					{"  TOS  "}
				</Link>
			</Box>
			<Box>
				<Link
					color="inherit"
					href={require("./pp.pdf")}
					download="Abridged-Due-Diligence-Privacy-Policy.pdf"
				>
					{"  Privacy Policy  "}
				</Link>
			</Box>
			<Box>
				<Typography color="inherit">{"  Not financial advice "}</Typography>
			</Box>
		</Box>
	);
}
