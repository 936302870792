import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Error = () => {
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<Grid container justifyContent="center" alignItems="center" height="100vh">
			<Box textAlign={"center"}>
				<Typography
					variant="h2"
					color={theme.palette.neutral.light}
					fontWeight="bold"
					sx={{ m: "10px 10px 5px 10px" }}
				>
					Page not available
				</Typography>
				<Typography
					variant="h4"
					color={theme.palette.neutral.light}
					fontWeight="bold"
					sx={{ m: "10px 10px 5px 10px" }}
				>
					Check to see if you're logged in and have premium access.
				</Typography>
				<Typography
					variant="h4"
					color={theme.palette.neutral.light}
					fontWeight="bold"
					sx={{ m: "10px 10px 5px 10px" }}
				>
					You can checkout free filings by clicking below.
				</Typography>
				<Box
					display="block"
					m="30px 30px"
					justifyContent="center"
					alignItems="center"
					align="center"
				>
					<Button
						variant="contained"
						style={{
							color: theme.palette.neutral.dark,
							backgroundColor: theme.palette.secondary.main,
						}}
						onClick={() => navigate("/discover")}
					>
						Discover the Details!
					</Button>
				</Box>
			</Box>
		</Grid>
	);
};

export default Error;
