import { Box, Typography, useTheme } from "@mui/material";

import CheckoutButton from "./CheckoutButton";

const PricingTile = ({ title, subtitle, type, fixed_colors }) => {
	const theme = useTheme();

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			p={2}
		>
			<Box gridColumn="span 8" p={2}>
				<Box display="flex" justifyContent="space-between">
					<Typography
						variant="h5"
						fontWeight="bold"
						sx={{
							color: fixed_colors ? "#3d3d3d" : theme.palette.neutral.light,
						}}
					>
						{title}
					</Typography>
				</Box>

				<Box display="flex" justifyContent="space-between">
					<Typography
						variant="h6"
						sx={{
							color: fixed_colors ? "#3d3d3d" : theme.palette.neutral.light,
						}}
					>
						{subtitle}
					</Typography>
				</Box>
			</Box>
			<CheckoutButton type={type} />
		</Box>
	);
};

export default PricingTile;
