import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import useColorChange from "./useColorChange"; // Import the function

const HoverColorChangeImage = styled("img")(({ theme }) => ({
	backgroundColor: "transparent",
	transition: "filter 0.3s ease",
	"&:hover": {
		filter: `drop-shadow(0px 0px 12px ${theme.palette.secondary.main})`, // Change 'red' to your desired color
	},
}));
const ImageContainer = ({ image }) => {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			width="50%"
			//mb="50px"
		>
			<HoverColorChangeImage
				src={image}
				alt="Icon Image"
				style={{ height: "250px", width: "auto", display: "block" }}
			/>
		</Box>
	);
};

function parseText(text) {
	const parts = text.split(/\*([^*]+)\*/g); // Split the text by '*'

	return parts.map((part, index) => {
		if (index % 2 === 0) {
			return part; // return the normal text
		} else {
			return (
				<span key={index} className="highlight">
					{part}
				</span>
			); // wrap the highlighted text with a span
		}
	});
}

const BulletBox = ({
	title,
	subtitle,
	subtitle2,
	subtitle3,
	subtitle4,
	subtitle5,
	image,
	start,
	fixed_colors,
}) => {
	const { textColor, handleButtonClick,handleButtonUnClick } = useColorChange();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const dependantStart = isSmallScreen ? false : start;
	const HighlightedText = styled(Typography)(({ theme, textColor }) => ({
		"& .highlight": {
			color: textColor || theme.palette.neutral.light, // default color
			transition: "color 0.3s ease, text-shadow 0.3s ease",
		},
	}));
	const StyledBox = styled(Box)({
		"&:hover .highlight": {
			color: theme.palette.neutral.light, // the color when the box is hovered
			textShadow: `0 0 5px ${theme.palette.secondary.main}, 0 0 5px ${theme.palette.secondary.main}`,
		},
	});
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			flexDirection={isSmallScreen ? "column" : "row"} // flexDirection changes based on screen width
			width="100%"
			height="Auto"
		>	
			{dependantStart ? null : <ImageContainer image={image} />}
			<Box
				p={5}
				backgroundColor={theme.palette.primary.accent}
				maxWidth="50%"
				minWidth="350px"
				height="100%"
				borderRadius={16}
				onMouseEnter={handleButtonClick}
				onMouseLeave={handleButtonUnClick}
				
			>
				<StyledBox display="Auto">
					<HighlightedText
						variant="h2"
						fontWeight="bold"
						style={{ textAlign: "center" }}
						sx={{
						  color: theme.palette.neutral.light,
						}}
					>
						{parseText(title)}
					</HighlightedText>
				</StyledBox>
				<StyledBox display="flex" mt="20px" className="hoverEffect">
					<HighlightedText
						variant="h4"
						textColor={textColor}
													//sx={{
								//color: theme.palette.neutral.light,
							//}}
					>
						{parseText(subtitle)}
					</HighlightedText>
				</StyledBox>
				{subtitle2 ? (
					<StyledBox display="flex" mt="20px" className="hoverEffect">
						<HighlightedText
							variant="h4"
							textColor={textColor}
							//sx={{
								//color: theme.palette.neutral.light,
							//}}
						>
							{parseText(subtitle2)}
						</HighlightedText>
					</StyledBox>
				) : null}
				{subtitle3 ? (
					<StyledBox display="flex" mt="20px" className="hoverEffect">
						<HighlightedText
							variant="h4"
							textColor={textColor}
							//sx={{
								//color: theme.palette.neutral.light,
							//}}
						>
							{parseText(subtitle3)}
						</HighlightedText>
					</StyledBox>
				) : null}
				{subtitle4 ? (
					<StyledBox display="flex" mt="20px" className="hoverEffect">
						<HighlightedText
							variant="h4"
							textColor={textColor}
							//sx={{
								//color: theme.palette.neutral.light,
							//}}
						>
							{parseText(subtitle4)}
						</HighlightedText>
					</StyledBox>
				) : null}
				{subtitle5 ? (
					<StyledBox display="flex" mt="20px" className="hoverEffect">
						<HighlightedText
							variant="h4"
							textColor={textColor}
							//sx={{
								//color: theme.palette.neutral.light,
							//}}
						>
							{parseText(subtitle5)}
						</HighlightedText>
					</StyledBox>
				) : null}
			</Box>
			{dependantStart ? <ImageContainer image={image} /> : null}
		</Box>
	);
};

export default BulletBox;
