import { useState, useEffect } from "react";
import usePremiumStatus from "../stripe/usePremiumStatus";
import dayjs from "dayjs";

export function useAccessLevel(user) {
	const [isPremium, loading] = usePremiumStatus(user);

	const [maxDate, setMaxDate] = useState(
		dayjs().subtract(1, "month").endOf("day")
	);
	const [endDate, setEndDate] = useState(maxDate);
	const [startDate, setStartDate] = useState(
		endDate.subtract(1, "month").startOf("day")
	);

	useEffect(() => {
		const date = isPremium
			? dayjs().endOf("day")
			: dayjs().subtract(1, "month").endOf("day");
		setEndDate(date);
		setMaxDate(date);
		setStartDate(date.subtract(1, "month").startOf("day"));
	}, [isPremium]);

	return {
		maxDate: maxDate,
		startDate: startDate,
		setStartDate: setStartDate,
		endDate: endDate,
		setEndDate: setEndDate,
		loading: loading,
		isPremium: isPremium,
	};
}
