import StatBox from "../../components/StatBox";
import { BuySellMap } from "../../theme";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

export default function BuySellCards({ row }) {
	return (
		<>
			{/* ROW 1 */}

			<StatBox
				title={"Strong"}
				progress={row.strong_buy_score.toPrecision(4)}
				increase={(row.strong_buy_score * 100).toPrecision(4) + "%"}
				backgroundColor={BuySellMap["Strong"]}
				fixed_colors={true}
				icon={
					<KeyboardDoubleArrowUpIcon
						sx={{ color: "#141414", fontSize: "26px" }}
					/>
				}
			/>

			<StatBox
				title="Good"
				progress={row.buy_score.toPrecision(4)}
				increase={(row.buy_score * 100).toPrecision(4) + "%"}
				backgroundColor={BuySellMap["Good"]}
				fixed_colors={true}
				icon={
					<KeyboardArrowUpIcon
						sx={{ color: "#141414", fontSize: "26px" }}
					/>
				}
			/>

			<StatBox
				title="Average"
				progress={row.hold_score.toPrecision(4)}
				increase={(row.hold_score * 100).toPrecision(4) + "%"}
				backgroundColor={BuySellMap["Average"]}
				fixed_colors={true}
				icon={
					<KeyboardArrowRightIcon
						sx={{ color: "#141414", fontSize: "26px" }}
					/>
				}
			/>

			<StatBox
				title="Poor"
				progress={row.sell_score.toPrecision(4)}
				increase={(row.sell_score * 100).toPrecision(4) + "%"}
				backgroundColor={BuySellMap["Poor"]}
				fixed_colors={true}
				icon={
					<KeyboardArrowDownIcon
						sx={{ color: "#141414", fontSize: "26px" }}
					/>
				}
			/>

			<StatBox
				title="Weak"
				progress={row.strong_sell_score.toPrecision(4)}
				increase={(row.strong_sell_score * 100).toPrecision(4) + "%"}
				backgroundColor={BuySellMap["Weak"]}
				fixed_colors={true}
				icon={
					<KeyboardDoubleArrowDownIcon
						sx={{ color: "#141414", fontSize: "26px" }}
					/>
				}
			/>
		</>);
}