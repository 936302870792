import { darken, lighten, styled } from "@mui/material/styles";
import CustomGridToolbar from "./CustomGridToolbar";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

const getBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.5);

const getHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.5) : lighten(color, 0.4);

const getSelectedBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.4) : lighten(color, 0.3);

const getSelectedHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.3) : lighten(color, 0.2);

const StyledDataGridBase = styled(DataGridPro)(({ theme }) => ({
	"& .super-app-theme--Good": {
		backgroundColor: getBackgroundColor(
			theme.palette.success.light,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.success.light,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.success.light,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.success.light,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Strong": {
		backgroundColor: getBackgroundColor(
			theme.palette.success.dark,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.success.dark,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.success.dark,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.success.dark,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Average": {
		backgroundColor: getBackgroundColor(
			theme.palette.warning.light,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.warning.light,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.warning.light,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.warning.light,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Poor": {
		backgroundColor: getBackgroundColor(
			theme.palette.error.light,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.error.light,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.error.light,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.error.light,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Weak": {
		backgroundColor: getBackgroundColor(
			theme.palette.error.dark,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.error.dark,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.error.dark,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.error.dark,
					theme.palette.mode
				),
			},
		},
	},
}));

export default function StyledDataGrid({
	rows,
	columns,
	onRowClick,
	getRowId,
	getRowClassName,
	loading,
	isPremium,
}) {
	const apiRef = useGridApiRef();

	return (
		<StyledDataGridBase
			rows={rows}
			columns={columns}
			slots={{
				toolbar: () => <CustomGridToolbar isPremium={isPremium} />,
			}}
			slotProps={{ filterPanel: { sx: { maxWidth: "100vw" } } }}
			sx={{
				flexGrow: 1,
				overflow: "auto",
			}}
			apiRef={apiRef}
			getRowId={getRowId}
			getRowClassName={getRowClassName}
			onRowClick={onRowClick}
			autoHeight={false}
			loading={loading}
			pagination
			initialState={{
				columns: {
					columnVisibilityModel: {
						// Hide accession number by defualt
						"acc-no": false,
					},
				},
				sorting: {
					sortModel: [{ field: "date_filed", sort: "desc" }],
				},
			}}
		/>
	);
}
