export default function getCFValues({CashFlowTotals}) {
	const cfAccounts= {
	  CFOperating:null,
		IncDecAp:null,
		IncDecAR:null,
		IncDecPP:null,
		IncDecInventory:null,
		DepreciationAmortization:null,
	  CFInvesting:null,
		PPPE:null,
		SPPE:null,
  
	  CFFinancing:null,
		PrefferedDiv:null,
  
		CommonStockIssue:null,
		StockOptionProceeds:null,
		LongTermDebtProceeds:null,
		CommonDiv:null,
  
  
	  TotalCFChange:null,
	};
	// CFOperating:
	if (CashFlowTotals?.NetCashProvidedByUsedInOperatingActivities) {
	  cfAccounts.CFOperating = {
		  LastYear: CashFlowTotals.NetCashProvidedByUsedInOperatingActivities.LastYear,
		  CurrentValue: CashFlowTotals.NetCashProvidedByUsedInOperatingActivities.CurrentValue,
		  AbsChange: CashFlowTotals.NetCashProvidedByUsedInOperatingActivities.AbsChange,
		  PercentChange: CashFlowTotals.NetCashProvidedByUsedInOperatingActivities.PercentChange,
		  SourceName:"NetCashProvidedByUsedInOperatingActivities",
		  DisplayName:'Operating'
	  };
	}
  
	//IncDecAp:null, IncreaseDecreaseInAccountsPayable
	if (CashFlowTotals?.IncreaseDecreaseInAccountsPayable) {
	  cfAccounts.IncDecAp = {
		  LastYear: CashFlowTotals.IncreaseDecreaseInAccountsPayable.LastYear,
		  CurrentValue: CashFlowTotals.IncreaseDecreaseInAccountsPayable.CurrentValue,
		  AbsChange: CashFlowTotals.IncreaseDecreaseInAccountsPayable.AbsChange,
		  PercentChange: CashFlowTotals.IncreaseDecreaseInAccountsPayable.PercentChange,
		  SourceName:"IncreaseDecreaseInAccountsPayable",
		  DisplayName:'Change in AP'
	  };
	}
   // IncDecAR:null,IncreaseDecreaseInAccountsReceivable
   if (CashFlowTotals?.IncreaseDecreaseInAccountsReceivable) {
	cfAccounts.IncDecAR = {
		LastYear: CashFlowTotals.IncreaseDecreaseInAccountsReceivable.LastYear,
		CurrentValue: CashFlowTotals.IncreaseDecreaseInAccountsReceivable.CurrentValue,
		AbsChange: CashFlowTotals.IncreaseDecreaseInAccountsReceivable.AbsChange,
		PercentChange: CashFlowTotals.IncreaseDecreaseInAccountsReceivable.PercentChange,
		SourceName:"IncreaseDecreaseInAccountsReceivable",
		DisplayName:'Change in AR'
	};
  }
	//IncDecPP:null, IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssets
	if (CashFlowTotals?.IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssets) {
	  cfAccounts.IncDecPP = {
		  LastYear: CashFlowTotals.IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssets.LastYear,
		  CurrentValue: CashFlowTotals.IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssets.CurrentValue,
		  AbsChange: CashFlowTotals.IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssets.AbsChange,
		  PercentChange: CashFlowTotals.IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssets.PercentChange,
		  SourceName:"IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssets",
		  DisplayName:'Change in Prepaids'
	  };
	}
	//IncDecInventory:null,IncreaseDecreaseInInventories
	if (CashFlowTotals?.IncreaseDecreaseInInventories) {
	  cfAccounts.IncDecInventory = {
		  LastYear: CashFlowTotals.IncreaseDecreaseInInventories.LastYear,
		  CurrentValue: CashFlowTotals.IncreaseDecreaseInInventories.CurrentValue,
		  AbsChange: CashFlowTotals.IncreaseDecreaseInInventories.AbsChange,
		  PercentChange: CashFlowTotals.IncreaseDecreaseInInventories.PercentChange,
		  SourceName:"IncreaseDecreaseInInventories",
		  DisplayName:'Change in Inventory'
	  };
	}
  
  
  
	//  DepreciationAmortization
	if (CashFlowTotals?.DepreciationDepletionAndAmortization) {
	  cfAccounts.DepreciationAmortization = {
		  LastYear: CashFlowTotals.DepreciationDepletionAndAmortization.LastYear,
		  CurrentValue: CashFlowTotals.DepreciationDepletionAndAmortization.CurrentValue,
		  AbsChange: CashFlowTotals.DepreciationDepletionAndAmortization.AbsChange,
		  PercentChange: CashFlowTotals.DepreciationDepletionAndAmortization.PercentChange,
		  SourceName:"DepreciationDepletionAndAmortization",
		  DisplayName:'Dep and Amort'
	  };
	}
	//  AmortizationEXP
	//if (CashFlowTotals?.AmortizationOfIntangibleAssets) {
   //   cfAccounts.AmortizationEXP = {
	//      LastYear: CashFlowTotals.AmortizationOfIntangibleAssets.LastYear,
	//      CurrentValue: CashFlowTotals.AmortizationOfIntangibleAssets.CurrentValue,
	 //     AbsChange: CashFlowTotals.AmortizationOfIntangibleAssets.AbsChange,
	 //     PercentChange: CashFlowTotals.AmortizationOfIntangibleAssets.PercentChange
	 // };
   // }
	//CFInvesting
	if (CashFlowTotals?.NetCashProvidedByUsedInInvestingActivities) {
	  cfAccounts.CFInvesting = {
		  LastYear: CashFlowTotals.NetCashProvidedByUsedInInvestingActivities.LastYear,
		  CurrentValue: CashFlowTotals.NetCashProvidedByUsedInInvestingActivities.CurrentValue,
		  AbsChange: CashFlowTotals.NetCashProvidedByUsedInInvestingActivities.AbsChange,
		  PercentChange: CashFlowTotals.NetCashProvidedByUsedInInvestingActivities.PercentChange,
		  SourceName:"NetCashProvidedByUsedInInvestingActivities",
		  DisplayName:'Investing'
	  };
	}
  
	//PaymentsToAcquirePropertyPlantAndEquipment:null,
	if (CashFlowTotals?.PaymentsToAcquirePropertyPlantAndEquipment) {
	  cfAccounts.PPPE = {
		  LastYear: CashFlowTotals.PaymentsToAcquirePropertyPlantAndEquipment.LastYear,
		  CurrentValue: CashFlowTotals.PaymentsToAcquirePropertyPlantAndEquipment.CurrentValue,
		  AbsChange: CashFlowTotals.PaymentsToAcquirePropertyPlantAndEquipment.AbsChange,
		  PercentChange: CashFlowTotals.PaymentsToAcquirePropertyPlantAndEquipment.PercentChange,
		  SourceName:"PaymentsToAcquirePropertyPlantAndEquipment",
		  DisplayName:'Payments for PPE'
	  };
	}
  
	//ProceedsFromSaleOfPropertyPlantAndEquipment:null,
  
  
  
	if (CashFlowTotals?.ProceedsFromSaleOfPropertyPlantAndEquipment) {
	  cfAccounts.SPPE = {
		  LastYear: CashFlowTotals.ProceedsFromSaleOfPropertyPlantAndEquipment.LastYear,
		  CurrentValue: CashFlowTotals.ProceedsFromSaleOfPropertyPlantAndEquipment.CurrentValue,
		  AbsChange: CashFlowTotals.ProceedsFromSaleOfPropertyPlantAndEquipment.AbsChange,
		  PercentChange: CashFlowTotals.ProceedsFromSaleOfPropertyPlantAndEquipment.PercentChange,
		  SourceName:"ProceedsFromSaleOfPropertyPlantAndEquipment",
		  DisplayName:'Sales of PPE'
	  };
	}
	//CFFinancing
  
	if (CashFlowTotals?.NetCashProvidedByUsedInFinancingActivities) {
	  cfAccounts.CFFinancing = {
		  LastYear: CashFlowTotals.NetCashProvidedByUsedInFinancingActivities.LastYear,
		  CurrentValue: CashFlowTotals.NetCashProvidedByUsedInFinancingActivities.CurrentValue,
		  AbsChange: CashFlowTotals.NetCashProvidedByUsedInFinancingActivities.AbsChange,
		  PercentChange: CashFlowTotals.NetCashProvidedByUsedInFinancingActivities.PercentChange,
		  SourceName:"NetCashProvidedByUsedInFinancingActivities",
		  DisplayName:'Financing'
	  };
	}
  
  
  
	//  PrefferedDiv
	if (CashFlowTotals?.DividendsPreferredStock) {
	  cfAccounts.PrefferedDiv = {
		  LastYear: CashFlowTotals.DividendsPreferredStock.LastYear,
		  CurrentValue: CashFlowTotals.DividendsPreferredStock.CurrentValue,
		  AbsChange: CashFlowTotals.DividendsPreferredStock.AbsChange,
		  PercentChange: CashFlowTotals.DividendsPreferredStock.PercentChange,
		  SourceName:"DividendsPreferredStock",
		  DisplayName:'Preffered Dividends'
	  };
	}
  
  
   // ProceedsFromIssuanceOfCommonStock:null,
   if (CashFlowTotals?.ProceedsFromIssuanceOfCommonStock) {
	cfAccounts.CommonStockIssue = {
		LastYear: CashFlowTotals.ProceedsFromIssuanceOfCommonStock.LastYear,
		CurrentValue: CashFlowTotals.ProceedsFromIssuanceOfCommonStock.CurrentValue,
		AbsChange: CashFlowTotals.ProceedsFromIssuanceOfCommonStock.AbsChange,
		PercentChange: CashFlowTotals.ProceedsFromIssuanceOfCommonStock.PercentChange,
		SourceName:"ProceedsFromIssuanceOfCommonStock",
		DisplayName:'Common Stock Issue'
	};
  }
  
  
   // ProceedsFromStockOptionsExercised:null,
   if (CashFlowTotals?.ProceedsFromStockOptionsExercised) {
	cfAccounts.StockOptionProceeds = {
		LastYear: CashFlowTotals.ProceedsFromStockOptionsExercised.LastYear,
		CurrentValue: CashFlowTotals.ProceedsFromStockOptionsExercised.CurrentValue,
		AbsChange: CashFlowTotals.ProceedsFromStockOptionsExercised.AbsChange,
		PercentChange: CashFlowTotals.ProceedsFromStockOptionsExercised.PercentChange,
		SourceName:"ProceedsFromStockOptionsExercised",
		DisplayName:'Stock Option Proceeds'
	};
  }
  
  
  
   // ProceedsFromIssuanceOfLongTermDebt:null,
  
   if (CashFlowTotals?.ProceedsFromIssuanceOfLongTermDebt) {
	cfAccounts.LongTermDebtProceeds = {
		LastYear: CashFlowTotals.ProceedsFromIssuanceOfLongTermDebt.LastYear,
		CurrentValue: CashFlowTotals.ProceedsFromIssuanceOfLongTermDebt.CurrentValue,
		AbsChange: CashFlowTotals.ProceedsFromIssuanceOfLongTermDebt.AbsChange,
		PercentChange: CashFlowTotals.ProceedsFromIssuanceOfLongTermDebt.PercentChange,
		SourceName:"ProceedsFromIssuanceOfLongTermDebt",
		DisplayName:'Long Term Debt Proceeds'
	};
  }
  // PaymentsOfDividendsCommonStock:null,
  
  if (CashFlowTotals?.PaymentsOfDividendsCommonStock) {
	cfAccounts.CommonDiv = {
		LastYear: CashFlowTotals.PaymentsOfDividendsCommonStock.LastYear,
		CurrentValue: CashFlowTotals.PaymentsOfDividendsCommonStock.CurrentValue,
		AbsChange: CashFlowTotals.PaymentsOfDividendsCommonStock.AbsChange,
		PercentChange: CashFlowTotals.PaymentsOfDividendsCommonStock.PercentChange,
		SourceName:"PaymentsOfDividendsCommonStock",
		DisplayName:'Common Dividends'
	};
  }
  
  
  
  
  
	//TotalCFChange CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffect
	if (CashFlowTotals?.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseIncludingExchangeRateEffect) {
	  cfAccounts.TotalCFChange = {
		  LastYear: CashFlowTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseIncludingExchangeRateEffect.LastYear,
		  CurrentValue: CashFlowTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseIncludingExchangeRateEffect.CurrentValue,
		  AbsChange: CashFlowTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseIncludingExchangeRateEffect.AbsChange,
		  PercentChange: CashFlowTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseIncludingExchangeRateEffect.PercentChange,
		  SourceName:"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseIncludingExchangeRateEffect",
		  DisplayName:'Total Change'
	  };
	}  else if (CashFlowTotals?.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffect) {
		cfAccounts.TotalCFChange = {
			LastYear: CashFlowTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffect.LastYear,
			CurrentValue: CashFlowTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffect.CurrentValue,
			AbsChange: CashFlowTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffect.AbsChange,
			PercentChange: CashFlowTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffect.PercentChange,
			SourceName:"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffect",
			DisplayName:'Total Change'
		};
	  } 
  
  
  
	  return cfAccounts;
  }