import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import noData from "../../../components/NoData";
import getBSValues from "./bsalternates";
import getISValues from "./isalternates";
import getCFValues from "./cfalternates";
import getEquityValues from "./equityalternates";
import getRatios from "./ratioAlternates";
import RatioCard from "./ratioCard"; // Adjust the path as necessary
export default function Ratios({ data,priceF }) {
  const BalanceSheetTotals = JSON.parse(data["BalanceSheetTotals"]);
  const IncomeStatementTotals = JSON.parse(data["IncomeStatementTotals"]);
  const CashFlowTotals = JSON.parse(data["CashFlowTotals"]);

  const bsAccounts = getBSValues({ BalanceSheetTotals });
  const isAccounts = getISValues({ IncomeStatementTotals });
  const cfAccounts = getCFValues({ CashFlowTotals });
  const equityAccounts=getEquityValues({BalanceSheetTotals})

  const ratios = getRatios(bsAccounts, isAccounts, cfAccounts,priceF,equityAccounts);
  const cardData = Object.values(ratios).filter(
    (value) => value !== null && isFinite(value.Value)
  );

  if (Object.keys(CashFlowTotals).length < 1 && 
      Object.keys(IncomeStatementTotals).length < 1 &&
      Object.keys(BalanceSheetTotals).length < 1) {
    return noData("Ratios");
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} >
        {cardData.map((data, index) => {
          // Check if the Value is not empty
          if (!data.Value || data.Value === "") {
            // Skip rendering this card
            return null;
          }

          return (
            <Grid item xs={12} sm={12} md={12} lg={6} key={data.uniqueId || index}>
              <RatioCard data={data} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
