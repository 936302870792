export default function getISValues({IncomeStatementTotals}) {
  const isAccounts= {
      Revenue:null,
      COGS:null,
    GrossProfit:null,
    OpExp:null,
      AdExp:null,
      GenAdExp:null,
      DepAmorExp:null,
    OpIncome:null,
      IntExp:null,
      TaxExp:null,
    NetIncome:null,  
  };
//  Revenue***
if (IncomeStatementTotals?.Revenues) {
  isAccounts.Revenue = {
      LastYear: IncomeStatementTotals.Revenues.LastYear,
      CurrentValue: IncomeStatementTotals.Revenues.CurrentValue,
      AbsChange: IncomeStatementTotals.Revenues.AbsChange,
      PercentChange: IncomeStatementTotals.Revenues.PercentChange,
      SourceName:"Revenues",
      DisplayName:'Revenue'
  };
}
else if (IncomeStatementTotals?.RevenuesNetOfInterestExpense) {
  isAccounts.Revenue = {
      LastYear: IncomeStatementTotals.RevenuesNetOfInterestExpense.LastYear,
      CurrentValue: IncomeStatementTotals.RevenuesNetOfInterestExpense.CurrentValue,
      AbsChange: IncomeStatementTotals.RevenuesNetOfInterestExpense.AbsChange,
      PercentChange: IncomeStatementTotals.RevenuesNetOfInterestExpense.PercentChange,
      SourceName:"RevenuesNetOfInterestExpense",
      DisplayName:'Revenue'
  };
}
else if(IncomeStatementTotals?.RevenueFromContractWithCustomerExcludingAssessedTax){
  isAccounts.Revenue = {
    LastYear: IncomeStatementTotals.RevenueFromContractWithCustomerExcludingAssessedTax.LastYear,
    CurrentValue: IncomeStatementTotals.RevenueFromContractWithCustomerExcludingAssessedTax.CurrentValue,
    AbsChange: IncomeStatementTotals.RevenueFromContractWithCustomerExcludingAssessedTax.AbsChange,
    PercentChange: IncomeStatementTotals.RevenueFromContractWithCustomerExcludingAssessedTax.PercentChange,
    SourceName:"RevenueFromContractWithCustomerExcludingAssessedTax",
    DisplayName:'Revenue'
};
}
//  COGS***
if (IncomeStatementTotals?.CostOfGoodsAndServicesSold) {
  isAccounts.COGS = {
      LastYear: IncomeStatementTotals.CostOfGoodsAndServicesSold.LastYear,
      CurrentValue: IncomeStatementTotals.CostOfGoodsAndServicesSold.CurrentValue,
      AbsChange: IncomeStatementTotals.CostOfGoodsAndServicesSold.AbsChange,
      PercentChange: IncomeStatementTotals.CostOfGoodsAndServicesSold.PercentChange,
      SourceName:"CostOfGoodsAndServicesSold",
      DisplayName:'Cost Of Goods Sold'
  };
}
else if(IncomeStatementTotals?.CostOfRevenue){
  isAccounts.COGS = {
  LastYear: IncomeStatementTotals.CostOfRevenue.LastYear,
  CurrentValue: IncomeStatementTotals.CostOfRevenue.CurrentValue,
  AbsChange: IncomeStatementTotals.CostOfRevenue.AbsChange,
  PercentChange: IncomeStatementTotals.CostOfRevenue.PercentChange,
  SourceName:"CostOfRevenue",
  DisplayName:'Cost Of Goods Sold'
};
}
else if(IncomeStatementTotals?.CostOfGoodsAndServiceExcludingDepreciationDepletionAndAmortization){
  isAccounts.COGS = {
  LastYear: IncomeStatementTotals.CostOfGoodsAndServiceExcludingDepreciationDepletionAndAmortization.LastYear,
  CurrentValue: IncomeStatementTotals.CostOfGoodsAndServiceExcludingDepreciationDepletionAndAmortization.CurrentValue,
  AbsChange: IncomeStatementTotals.CostOfGoodsAndServiceExcludingDepreciationDepletionAndAmortization.AbsChange,
  PercentChange: IncomeStatementTotals.CostOfGoodsAndServiceExcludingDepreciationDepletionAndAmortization.PercentChange,
  SourceName:"CostOfGoodsAndServiceExcludingDepreciationDepletionAndAmortization",
  DisplayName:'Cost Of Goods Sold'
};
}
///GrossProfit:null, 
if (IncomeStatementTotals?.GrossProfit) {
  isAccounts.GrossProfit = {
      LastYear: IncomeStatementTotals.GrossProfit.LastYear,
      CurrentValue: IncomeStatementTotals.GrossProfit.CurrentValue,
      AbsChange: IncomeStatementTotals.GrossProfit.AbsChange,
      PercentChange: IncomeStatementTotals.GrossProfit.PercentChange,
      SourceName:"GrossProfit",
      DisplayName:'Gross Profit'
  };
}

//OperatingExpenses
if (IncomeStatementTotals?.OperatingExpenses) {
  isAccounts.OpExp = {
      LastYear: IncomeStatementTotals.OperatingExpenses.LastYear,
      CurrentValue: IncomeStatementTotals.OperatingExpenses.CurrentValue,
      AbsChange: IncomeStatementTotals.OperatingExpenses.AbsChange,
      PercentChange: IncomeStatementTotals.OperatingExpenses.PercentChange,
      SourceName:"OperatingExpenses",
      DisplayName:'Operating Expenses'
  };
}

//AdvertisingExpense:null,  AdvertisingExpense
if (IncomeStatementTotals?.OperatingExpenses) {
  isAccounts.OpExp = {
      LastYear: IncomeStatementTotals.OperatingExpenses.LastYear,
      CurrentValue: IncomeStatementTotals.OperatingExpenses.CurrentValue,
      AbsChange: IncomeStatementTotals.OperatingExpenses.AbsChange,
      PercentChange: IncomeStatementTotals.OperatingExpenses.PercentChange,
      SourceName:"OperatingExpenses",
      DisplayName:'Operating Expenses'
  };
}
//GenralAndAdministrativeExpsese:null, GenralAndAdministrativeExpsese
if (IncomeStatementTotals?.GenralAndAdministrativeExpsese) {
  isAccounts.GenAdExp = {
      LastYear: IncomeStatementTotals.GenralAndAdministrativeExpsese.LastYear,
      CurrentValue: IncomeStatementTotals.GenralAndAdministrativeExpsese.CurrentValue,
      AbsChange: IncomeStatementTotals.GenralAndAdministrativeExpsese.AbsChange,
      PercentChange: IncomeStatementTotals.GenralAndAdministrativeExpsese.PercentChange,
      SourceName:"GenralAndAdministrativeExpsese",
      DisplayName:'General Administrative Expense'
  };
}
else if (IncomeStatementTotals?.SellingGeneralAndAdministrativeExpense) {
  isAccounts.GenAdExp = {
      LastYear: IncomeStatementTotals.SellingGeneralAndAdministrativeExpense.LastYear,
      CurrentValue: IncomeStatementTotals.SellingGeneralAndAdministrativeExpense.CurrentValue,
      AbsChange: IncomeStatementTotals.SellingGeneralAndAdministrativeExpense.AbsChange,
      PercentChange: IncomeStatementTotals.SellingGeneralAndAdministrativeExpense.PercentChange,
      SourceName:"SellingGeneralAndAdministrativeExpense",
      DisplayName:'General Administrative Expense'
  };
}

//DepAmorExp
if (IncomeStatementTotals?.DepreciationAndAmortization) {
  isAccounts.DepAmorExp= {
      LastYear: IncomeStatementTotals.DepreciationAndAmortization.LastYear,
      CurrentValue: IncomeStatementTotals.DepreciationAndAmortization.CurrentValue,
      AbsChange: IncomeStatementTotals.DepreciationAndAmortization.AbsChange,
      PercentChange: IncomeStatementTotals.DepreciationAndAmortization.PercentChange,
      SourceName:"DepreciationAndAmortization",
      DisplayName:'Dep and Amort'
  };
}
//OpIncome
if (IncomeStatementTotals?.OperatingIncomeLoss) {
  isAccounts.OpIncome = {
      LastYear: IncomeStatementTotals.OperatingIncomeLoss.LastYear,
      CurrentValue: IncomeStatementTotals.OperatingIncomeLoss.CurrentValue,
      AbsChange: IncomeStatementTotals.OperatingIncomeLoss.AbsChange,
      PercentChange: IncomeStatementTotals.OperatingIncomeLoss.PercentChange,
      SourceName:"OperatingIncomeLoss",
      DisplayName:'Operating Income'
  };
}
//  IntExp
if (IncomeStatementTotals?.InterestExpense) {
  isAccounts.IntExp= {
      LastYear: IncomeStatementTotals.InterestExpense.LastYear,
      CurrentValue: IncomeStatementTotals.InterestExpense.CurrentValue,
      AbsChange: IncomeStatementTotals.InterestExpense.AbsChange,
      PercentChange: IncomeStatementTotals.InterestExpense.PercentChange,
      SourceName:"InterestExpense",
      DisplayName:'Interest Expense'
  };
}

//TaxExpenseOrBenefit:null,
if (IncomeStatementTotals?.TaxExpenseOrBenefit) {
  isAccounts.TaxExp= {
      LastYear: IncomeStatementTotals.TaxExpenseOrBenefit.LastYear,
      CurrentValue: IncomeStatementTotals.TaxExpenseOrBenefit.CurrentValue,
      AbsChange: IncomeStatementTotals.TaxExpenseOrBenefit.AbsChange,
      PercentChange: IncomeStatementTotals.TaxExpenseOrBenefit.PercentChange,
      SourceName:"TaxExpenseOrBenefit",
      DisplayName:'Tax Expense'
  };
}
//NetIncome***  
if (IncomeStatementTotals?.NetIncomeLoss) {
  isAccounts.NetIncome = {
      LastYear: IncomeStatementTotals.NetIncomeLoss.LastYear,
      CurrentValue: IncomeStatementTotals.NetIncomeLoss.CurrentValue,
      AbsChange: IncomeStatementTotals.NetIncomeLoss.AbsChange,
      PercentChange: IncomeStatementTotals.NetIncomeLoss.PercentChange,
      SourceName:"NetIncomeLoss",
      DisplayName:'Net Income'
  };
}
else if(IncomeStatementTotals?.ProfitLoss){
  isAccounts.NetIncome = {
  LastYear: IncomeStatementTotals.ProfitLoss.LastYear,
  CurrentValue: IncomeStatementTotals.ProfitLoss.CurrentValue,
  AbsChange: IncomeStatementTotals.ProfitLoss.AbsChange,
  PercentChange: IncomeStatementTotals.ProfitLoss.PercentChange,
  SourceName:"ProfitLoss",
  DisplayName:'Net Income'
};
}
else if(IncomeStatementTotals?.NetIncomeLossAvailableToCommonStockholdersBasic){
  isAccounts.NetIncome = {
  LastYear: IncomeStatementTotals.NetIncomeLossAvailableToCommonStockholdersBasic.LastYear,
  CurrentValue: IncomeStatementTotals.NetIncomeLossAvailableToCommonStockholdersBasic.CurrentValue,
  AbsChange: IncomeStatementTotals.NetIncomeLossAvailableToCommonStockholdersBasic.AbsChange,
  PercentChange: IncomeStatementTotals.NetIncomeLossAvailableToCommonStockholdersBasic.PercentChange,
  SourceName:"NetIncomeLossAvailableToCommonStockholdersBasic",
  DisplayName:'Net Income'
};
}
else if(IncomeStatementTotals?.NetIncomeLossAttributableToReportingEntity){
  isAccounts.NetIncome = {
  LastYear: IncomeStatementTotals.NetIncomeLossAttributableToReportingEntity.LastYear,
  CurrentValue: IncomeStatementTotals.NetIncomeLossAttributableToReportingEntity.CurrentValue,
  AbsChange: IncomeStatementTotals.NetIncomeLossAttributableToReportingEntity.AbsChange,
  PercentChange: IncomeStatementTotals.NetIncomeLossAttributableToReportingEntity.PercentChange,
  SourceName:"NetIncomeLossAttributableToReportingEntity",
  DisplayName:'Net Income'
};
}
	return isAccounts;
}