import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import FinancialTree from "./financialTree";
import SnapShot from "./snapShot";
import Ratios from "./ratios";
import DataGlance from "./dataGlance";
import Error from "../../error";
import { panelSwitchThemeSettings } from "../../../theme";

import { firebaseClient, logAnalyticsEvent } from "../../../firebase/firebaseClient";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

export default function FinancialContainer({ row }) {
	const theme = useTheme();
	const id = row["acc-no"];
	const priceF = row.price.price;
	const db = getFirestore(firebaseClient);
	const [finances, financesLoading, financesError] = useDocumentOnce(
		doc(db, "financials", id)
	);
	logAnalyticsEvent("financial page viewed", row)

	return (
		<>
			{financesError && <Error />}
			{financesLoading && (
				<Grid
					item
					justifyContent="center"
					alignItems="center"
					height="100vh"
				>
					<Box textAlign={"center"}>
						<CircularProgress
							style={{
								color: theme.palette.secondary.main,
							}}
						/>
					</Box>
				</Grid>
			)}
			{finances?.data() ? (
				<>
					<Box
						display="grid"
						gridTemplateColumns="repeat(10, 1fr)"
						gridRow="span 1"
						gridColumn={{
							xs: "span 10",
							sm: "span 10",
							md: "span 10",
							lg: "span 6",
							xl: "span 6",
						}}
						gap="20px"
					>
						<><Box
							gridColumn="span 10"
							gridRow="span 2"
							backgroundColor={theme.palette.primary.accent}
						>
							<DataGlance data={finances.data()} priceF= {priceF} />
						</Box>

						</>

					</Box>
					<FinancialView data={finances.data()} priceF= {priceF} />
				</>) : (
				<Box p={2}>
					<Alert severity="info">
						Financial Data Not Available For This Organization.
					</Alert>
				</Box>
			)}
		</>
	);

}

function FinancialView({ data,priceF }) {
	const theme = useTheme();
	const panelSwitchTheme = createTheme(
		panelSwitchThemeSettings(theme.palette.mode)
	);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [index, setIndex] = useState("SnapShot");

	return (
		<Box
			gridColumn={{
				xs: "span 10",
				sm: "span 10",
				md: "span 10",
				lg: "span 10",
				xl: "span 10",
			}}
			height="660px"
			backgroundColor={theme.palette.primary.accent}
			overflow="auto"
			p={2}
			mb="37px"
		>
			<Box>
				<ThemeProvider theme={panelSwitchTheme}>
					<Box p={2}>
						<ToggleButtonGroup
							color="primary"
							value={index}
							exclusive
							onChange={(event, newIndex) => {
								if (newIndex) {
									setIndex(newIndex);
								} else {
									setIndex("SnapShpt");
								}
							}}
							aria-label="Platform"
						>
							<ToggleButton value="SnapShot">Snap Shot</ToggleButton>
							<ToggleButton disabled = {isSmallScreen} value="FinancialTree">Financial Tree</ToggleButton>
							
							<ToggleButton value="Ratios">Ratios</ToggleButton>
						</ToggleButtonGroup>
					</Box>
				</ThemeProvider>

				{/* ROW 3 */}
				{index === "SnapShot" && <SnapShot data={data} priceF={priceF} />}
				{index === "FinancialTree" && <FinancialTree data={data} />}
				{index === "Ratios" && <Ratios data={data} priceF={priceF} />}
			</Box>
		</Box>
	);
}
