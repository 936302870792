import { styled } from "@mui/material/styles";
import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Collapse,
  IconButton,
  CardActions,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";

export default function RatioCard({ data }) {
  const theme = useTheme();
  function Fraction({ numerator, denominator }) {
    return (
      <Box sx={{ display: "inline-block", textAlign: "center" }}>
        <Box sx={{ borderBottom: "1px solid", padding: "0 3px" }}>
          {numerator}
        </Box>
        <Box>{denominator}</Box>
      </Box>
    );
  }
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: expand ? "rotate(180deg)" : "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <Card sx={{ backgroundColor: theme.palette.primary.accent, color: theme.palette.neutral.light }}>
      <CardContent>
        <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h4" color={theme.palette.secondary.main }>
          {data.name}
        </Typography>
        <Typography variant="h2" color={theme.palette.secondary.main }>
          {data.PresentationValue}
        </Typography>
        </Box>
        {data.PYValue && (
          <Box display="flex" justifyContent={"space-between"}>
          <Typography variant="h5" color={theme.palette.secondary.main }>
            Prior Year 
          </Typography>
          <Typography variant="h3" color={theme.palette.secondary.main }>
          {data.PYPresentationValue}
          </Typography>
          </Box>
          )}
        {/* ... more code using data object */}
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* Additional content here */}
          
          <Box sx={{ padding: "10px", textAlign: "center" }}>
            <Fraction
              numerator={data.numerator}
              denominator={data.Denominator}
            />
          </Box>
          <Typography variant="h5" paragraph>
            Definition:
          </Typography>
          <Typography paragraph variant="h6">{data.Definition}</Typography>
          <Typography variant="h5" paragraph>
            Typical Interpretation:
          </Typography>
          <Typography paragraph variant="h6">{data.Interpretation}</Typography>
          {/* Additional content here */}
        </CardContent>
      </Collapse>
    </Card>
  );
}
