import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";

export default function LiquidGraph({ data, graphConfig }) {
	const graphData = Object.values(data).filter(
		(value) => value !== null
	);

	const theme = useTheme();
	

	if (graphData.length < 2) {
		return null;
	} else {
		return (
			<div style={{ height: 400 }}>

				<ResponsiveBar
					data={graphData}
					theme={{
						// added
						axis: {
							domain: {
								line: {
									stroke: theme.palette.neutral.light,
								},
							},
							legend: {
								text: {
									fill: theme.palette.neutral.light,
								},
							},
							ticks: {
								line: {
									stroke: theme.palette.neutral.light,
									strokeWidth: 1,
								},
								text: {
									fill: theme.palette.neutral.light,
								},
							},
						},
						grid: {
							line: {
								stroke: theme.palette.neutral.light,
							},
						},
						legends: {
							text: {
								fill: theme.palette.neutral.light,
							},
						},
					}}
					keys={graphConfig["keys"]}
					margin={{ top: 35, right: 15, bottom: 60, left: 45 }}
					padding={0.3}
					indexBy={graphConfig.indexBy}
					groupMode="grouped"
					valueScale={{ type: "linear" }}
					indexScale={{ type: "band", round: true }}
					colors={bar => {
						return graphConfig["colorMapping"][bar.id];
					}}
					borderColor={{
						from: "color",
						modifiers: [["darker", "1.6"]],
					}}
					isInteractive={false}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 0,
						tickPadding: 5,
						tickRotation: 0,
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						format: graphConfig.format
					}}
					enableLabel={false}
					role="application"
				/>

			</div>
		);
	}
}
