export default function getRatios(bsAccounts,isAccounts,cfAccounts,priceF,equityAccounts) {
  const formatAsDollar = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatAsPercent = (value) => {
    const formattedPercent = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  
    // Remove the '%' symbol from the formatted string
    return formattedPercent;
  };
  const formatAsRatio = (value) => {
    return new Intl.NumberFormat('en-US', {

      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  };
   const ratios= {
      Current:null,
      Quick:null,
      DebtEquity:null, 
      OpMargin:null,
      NetMargin:null,
      GrossMargin:null,
      EPS:null,
      InvTurnover:null,
      BVS:null,
      PE:null,
      ARTurnover:null,
      APTurnover:null,
      ROA:null,
      ROE:null,
      OpCF:null,
      CFSales:null,
      CFIncome:null,
      TotalDebt:null,
      EquityMultiplyer:null,
      TimesInterest:null,
      DividenPayout:null,
      EBITA:null,
  };
    //Current
      if(bsAccounts&&bsAccounts['CurrentAssets']&&bsAccounts['CurrentLiabilities']){
        ratios.Current = {
          name: 'Current Ratio',
          Value: bsAccounts['CurrentAssets']['CurrentValue'] / bsAccounts['CurrentLiabilities']['CurrentValue'],
          PresentationValue:formatAsRatio(bsAccounts['CurrentAssets']['CurrentValue'] / bsAccounts['CurrentLiabilities']['CurrentValue']),
          PYValue: bsAccounts['CurrentAssets']['LastYear'] / bsAccounts['CurrentLiabilities']['LastYear'],
          PYPresentationValue:formatAsRatio(bsAccounts['CurrentAssets']['LastYear'] / bsAccounts['CurrentLiabilities']['LastYear']),
          numerator: 'Current Assets',
          Denominator:'Current Liabilities' ,
          Definition: "Measures a company's ability to pay its short-term obligations with its short-term assets.",
          Interpretation:"Above 1 is generally good, indicating current assets exceed liabilities. Industry context is important." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //Quick
      if(bsAccounts&&bsAccounts['CurrentAssets']&&bsAccounts['CurrentLiabilities']&&bsAccounts['Inventory']){
        ratios.Quick = {
          name: 'Quick Ratio',
          Value: (bsAccounts['CurrentAssets']['CurrentValue']-bsAccounts['Inventory']['CurrentValue']) / bsAccounts['CurrentLiabilities']['CurrentValue'],
          PresentationValue:formatAsRatio((bsAccounts['CurrentAssets']['CurrentValue']-bsAccounts['Inventory']['CurrentValue']) / bsAccounts['CurrentLiabilities']['CurrentValue']),
          PYValue: (bsAccounts['CurrentAssets']['LastYear']-bsAccounts['Inventory']['LastYear']) / bsAccounts['CurrentLiabilities']['LastYear'],
          PYPresentationValue:formatAsRatio((bsAccounts['CurrentAssets']['LastYear']-bsAccounts['Inventory']['LastYear']) / bsAccounts['CurrentLiabilities']['LastYear']),
          numerator: '(Current Assets - Inventory)',
          Denominator:'Current Liabilities' ,
          Definition: "Evaluates a company's short-term liquidity, considering only quick assets like cash, marketable securities, and receivables.",
          Interpretation:"A higher ratio indicates better liquidity." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //DebtEquity 
      if(bsAccounts&&bsAccounts['Liabilities']&&bsAccounts['Equity']){
        ratios.DebtEquity = {
          name: 'Equity to Debt Ratio',
          Value: (bsAccounts['Equity']['CurrentValue']/bsAccounts['Liabilities']['CurrentValue']) ,
          PresentationValue:formatAsRatio((bsAccounts['Equity']['CurrentValue'])/bsAccounts['Liabilities']['CurrentValue']),
          PYValue: (bsAccounts['Equity']['LastYear']) / bsAccounts['Liabilities']['LastYear'],
          PYPresentationValue:formatAsRatio((bsAccounts['Equity']['LastYear']) / bsAccounts['Liabilities']['LastYear']),
          numerator: 'Equity',
          Denominator:'Liabilities' ,
          Definition: "Indicates the proportion of a company's operations financed by shareholders' equity versus its debt obligations.",
          Interpretation:"A higher ratio is typically preferred, indicating a company relies more on equity for financing, which could imply lower financial risk." ,
          Type:'Ratio' ,
          get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
        } 
        };
      }
      //OpMargin
      if(isAccounts&&isAccounts['Revenue']&&isAccounts['OpIncome']){
        ratios.OpMargin = {
          name: 'Operating Margin',
          Value:(isAccounts['OpIncome']['CurrentValue']) / isAccounts['Revenue']['CurrentValue'],
          PresentationValue:formatAsPercent((isAccounts['OpIncome']['CurrentValue']) / isAccounts['Revenue']['CurrentValue']),
          PYValue:(isAccounts['OpIncome']['LastYear']) / isAccounts['Revenue']['LastYear'],
          PYPresentationValue:formatAsPercent((isAccounts['OpIncome']['LastYear']) / isAccounts['Revenue']['LastYear']),
          numerator: 'Operating Income',
          Denominator:'Revenue' ,
          Definition: "Shows the percentage of revenue that remains after paying for variable costs of production.",
          Interpretation:"Higher is better, indicating more efficient operation." ,
          Type:'Percent' ,
          get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
        } 
        };
      }
      //NetMargin
      if(isAccounts&&isAccounts['Revenue']&&isAccounts['NetIncome']){
        ratios.NetMargin = {
          name: 'Net Margin',
          Value:(isAccounts['NetIncome']['CurrentValue']) / isAccounts['Revenue']['CurrentValue'],
          PresentationValue:formatAsPercent((isAccounts['NetIncome']['CurrentValue']) / isAccounts['Revenue']['CurrentValue']),
          PYValue:(isAccounts['NetIncome']['LastYear']) / isAccounts['Revenue']['LastYear'],
          PYPresentationValue:formatAsPercent((isAccounts['NetIncome']['LastYear']) / isAccounts['Revenue']['LastYear']),
          numerator: 'Net Income',
          Denominator:'Revenue' ,
          Definition: "Indicates how much profit a company generates from its revenues after all expenses.",
          Interpretation:"Higher percentages indicate better profitability." ,
          Type:'Percent' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //GrossMargin
      if(isAccounts && isAccounts['Revenue'] && isAccounts['COGS']){
        ratios.GrossMargin = {
          name: 'Gross Margin',
          Value:((isAccounts['Revenue']['CurrentValue'] - isAccounts['COGS']['CurrentValue']) / isAccounts['Revenue']['CurrentValue']),
          PresentationValue:formatAsPercent(((isAccounts['Revenue']['CurrentValue'] - isAccounts['COGS']['CurrentValue']) / (isAccounts['Revenue']['CurrentValue']*100))),
          PYValue:((isAccounts['Revenue']['LastYear'] - isAccounts['COGS']['LastYear']) / isAccounts['Revenue']['LastYear']),
          PYPresentationValue:formatAsPercent(((isAccounts['Revenue']['LastYear'] - isAccounts['COGS']['LastYear']) / (isAccounts['Revenue']['LastYear']*100))),
          numerator: '(Revenue - Cost of Goods Sold)',
          Denominator:'Revenue' ,
          Definition: "Reflects the percentage of revenue that exceeds the cost of goods sold.",
          Interpretation:"Higher margins indicate a company's effectiveness in managing production costs." ,
          Type:'Percent' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //EPS
      if(isAccounts&&isAccounts['NetIncome']&&equityAccounts['WeightedShares']){
        ratios.EPS = {
          name: 'Earnings Per Share',
          Value:isAccounts['NetIncome']['CurrentValue'] / equityAccounts['WeightedShares']['CurrentValue'],
          PresentationValue:formatAsDollar(isAccounts['NetIncome']['CurrentValue'] / equityAccounts['WeightedShares']['CurrentValue']),
          PYValue:isAccounts['NetIncome']['LastYear'] / equityAccounts['WeightedShares']['LastYear'],
          PYPresentationValue:formatAsDollar(isAccounts['NetIncome']['LastYear'] / equityAccounts['WeightedShares']['LastYear']),
          numerator: 'Net Income',
          Denominator:'Weighted Average Common Shares' ,
          Definition: "Measures a company's profit divided by the number of outstanding shares.",
          Interpretation:"Higher EPS indicates greater profitability." ,
          Type:'Value' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //InvTurnover
      if(bsAccounts&&isAccounts['COGS']&&bsAccounts['Inventory']&&bsAccounts['Inventory']['LastYear']){
        ratios.InvTurnover = {
          name: 'Inventory Turnover Ratio',
          Value: (isAccounts['COGS']['CurrentValue']) / ((bsAccounts['Inventory']['CurrentValue']+bsAccounts['Inventory']['LastYear'])/2),
          PresentationValue:formatAsRatio((isAccounts['COGS']['CurrentValue']) / ((bsAccounts['Inventory']['CurrentValue']+bsAccounts['Inventory']['LastYear'])/2)),
          PYValue:null,
          PYPresentationValue:null,
          numerator: 'Cost of Goods Sold',
          Denominator:'Average Inventory' ,
          Definition: "Assesses how efficiently a company manages its Inventory.",
          Interpretation:"Higher turnover indicates efficient Inventory management." ,
          Type:'Ratio' ,
          get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
        } 
        };
      }
      //BVS
      if(bsAccounts&&bsAccounts['Equity']&&equityAccounts['CommonStock']){
        ratios.BVS = {
          name: 'Book Value Per Share',
          Value: (bsAccounts['Equity']['CurrentValue']) / equityAccounts['CommonStock']['CurrentValue'],
          PresentationValue:formatAsDollar((bsAccounts['Equity']['CurrentValue']) / equityAccounts['CommonStock']['CurrentValue']),
          PYValue: (bsAccounts['Equity']['LastYear']) / equityAccounts['CommonStock']['LastYear'],
          PYPresentationValue:formatAsDollar((bsAccounts['Equity']['LastYear']) / equityAccounts['CommonStock']['LastYear']),
          numerator: 'Equity',
          Denominator:'Common Shares Outstanding' ,
          Definition: "Represents a company's net asset value on a per-share basis.",
          Interpretation:"Gives an estimate of what shareholders would receive if the company was liquidated." ,
          Type:'Value' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //PE
//*************************** */
      if(ratios.EPS &&priceF){
        ratios.PE = {
          name: 'Price to Earnings Ratio',
          Value:priceF/ratios.EPS.Value,//isAccounts['NetIncome']['CurrentValue'] / bsAccounts['CommonStock']['CurrentValue'],
          PresentationValue:formatAsRatio(priceF/ratios.EPS.Value),//formatAsDollar(isAccounts['NetIncome']['CurrentValue'] / bsAccounts['CommonStock']['CurrentValue']),
          PYValue:null,//isAccounts['NetIncome']['LastYear'] / bsAccounts['CommonStock']['LastYear'],
          PYPresentationValue:null,//formatAsDollar(isAccounts['NetIncome']['LastYear'] / bsAccounts['CommonStock']['LastYear']),
          numerator: 'Share Price at Filing',
          Denominator:'EPS' ,
          Definition: "The P/E ratio measures a company's current share price relative to its per-share earnings. It's a widely used metric to gauge a company's valuation.",
          Interpretation:"A High P/E Ratio typically indicates that investors expect higher earnings growth in the future compared to companies with a lower P/E. However, it can also suggest that a stock is overvalued. A low P/E ratio may suggest that a stock is undervalued or that the company is possibly facing difficulties. It can also indicate that the market has overlooked the stock. Best used to compare other stocks in the same industry in conjunction with other metrics." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
//*************************** */


      //ARTurnover
      if(bsAccounts&&bsAccounts['AR']&&isAccounts['Revenue']){
        ratios.ARTurnover = {
          name: 'Accounts Receivable Turnover',
          Value: (isAccounts['Revenue']['CurrentValue']) / ((bsAccounts['AR']['CurrentValue']+bsAccounts['AR']['LastYear'])/2),
          PresentationValue:formatAsRatio((isAccounts['Revenue']['CurrentValue']) / ((bsAccounts['AR']['CurrentValue']+bsAccounts['AR']['LastYear'])/2)),    
          PYValue:null,
          PYPresentationValue:null,
          numerator: 'Revenue',
          Denominator:'Average AR' ,
          Definition: "Measures how efficiently a company collects revenue from its customers.",
          Interpretation:"Higher turnover means the company efficiently collects its receivables." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //APTurnover
      if(bsAccounts&&bsAccounts['AP']&&isAccounts['Revenue']){
        ratios.APTurnover = {
          name: 'Accounts Payable Turnover',
          Value: (isAccounts['Revenue']['CurrentValue']) / ((bsAccounts['AP']['CurrentValue']+bsAccounts['AP']['LastYear'])/2),
          PresentationValue:formatAsRatio((isAccounts['Revenue']['CurrentValue']) / ((bsAccounts['AP']['CurrentValue']+bsAccounts['AP']['LastYear'])/2)),    
          PYValue:null,
          PYPresentationValue:null,
          numerator: 'Revenue',
          Denominator:'Average AP' ,
          Definition: "Assesses the rate at which a company pays off its suppliers.",
          Interpretation:"Higher turnover suggests efficient payment management." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //ROA
      if(bsAccounts&&bsAccounts['Assets']&&isAccounts['NetIncome']){
        ratios.ROA = {
          name: 'Return on Assets',
          Value: (isAccounts['NetIncome']['CurrentValue']) / ((bsAccounts['Assets']['CurrentValue']+bsAccounts['Assets']['LastYear'])/2),
          PresentationValue:formatAsRatio((isAccounts['NetIncome']['CurrentValue']) / ((bsAccounts['Assets']['CurrentValue']+bsAccounts['Assets']['LastYear'])/2)),    
          PYValue:null,
           PYPresentationValue:null,
          numerator: 'Net Income',
          Denominator:'Average Total Assets' ,
          Definition: "Indicates how efficiently a company uses its assets to generate profit.",
          Interpretation:"Higher ROA indicates effective asset use to generate profit." ,
          Type:'Ratio' , 
          get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //ROE
      if(bsAccounts&&bsAccounts['Equity']&&isAccounts['NetIncome']){
        ratios.ROE = {
          name: 'Return on Equity',
          Value: (isAccounts['NetIncome']['CurrentValue']) / ((bsAccounts['Equity']['CurrentValue']+bsAccounts['Equity']['LastYear'])/2),
          PresentationValue:formatAsRatio((isAccounts['NetIncome']['CurrentValue']) / ((bsAccounts['Equity']['CurrentValue']+bsAccounts['Equity']['LastYear'])/2)),    
          PYValue:null,
           PYPresentationValue:null,
          numerator: 'Net Income',
          Denominator:'Average Shareholder Equity' ,
          Definition: "Measures the profitability of equity investments in the company.",
          Interpretation:"Higher ROE indicates efficient use of equity capital." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //OpCF 
      if(bsAccounts&&bsAccounts['CurrentLiabilities']&&cfAccounts['CFOperating']){
        ratios.OpCF  = {
          name: 'Operating Cash Flow Ratio',
          Value: (cfAccounts['CFOperating']['CurrentValue']) / (bsAccounts['CurrentLiabilities']['CurrentValue']),
          PresentationValue:formatAsRatio((cfAccounts['CFOperating']['CurrentValue']) / (bsAccounts['CurrentLiabilities']['CurrentValue'])),
          PYValue: (cfAccounts['CFOperating']['LastYear']) / (bsAccounts['CurrentLiabilities']['LastYear']),
           PYPresentationValue:formatAsRatio((cfAccounts['CFOperating']['LastYear']) / (bsAccounts['CurrentLiabilities']['LastYear'])),
          numerator: 'Cash Flow From Operating Activities',
          Denominator:'Current Liabilities' ,
          Definition: "It indicates how well a company can meet its short-term liabilities with the cash it generates from its day-to-day activities.",
          Interpretation:" higher ratio suggests better liquidity, meaning the company is more capable of covering its current obligations without needing additional financing." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //CFSales 
      if(bsAccounts&&isAccounts['Revenue']&&cfAccounts['CFOperating']){
        ratios.CFSales  = {
          name: 'Cash Flow from Operating Activities to Revenue',
          Value: (cfAccounts['CFOperating']['CurrentValue']) / (isAccounts['Revenue']['CurrentValue']),
          PresentationValue:formatAsRatio((cfAccounts['CFOperating']['CurrentValue']) / (isAccounts['Revenue']['CurrentValue'])),
          PYValue: (cfAccounts['CFOperating']['LastYear']) / (isAccounts['Revenue']['LastYear']),
           PYPresentationValue:formatAsRatio((cfAccounts['CFOperating']['LastYear']) / (isAccounts['Revenue']['LastYear'])),
          numerator: 'Cash Flow From Operating Activities',
          Denominator:'Revenue' ,
          Definition: "This ratio measures how efficiently a company turns its revenue into cash. ",
          Interpretation:"A higher ratio suggests that a company is very efficient in managing its operations to generate cash." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //CFIncome 
      if(bsAccounts&&isAccounts['NetIncome']&&cfAccounts['CFOperating']){
        ratios.CFIncome  = {
          name: 'Cash Flow Margin Ratio',
          Value: (cfAccounts['CFOperating']['CurrentValue']) / (isAccounts['NetIncome']['CurrentValue']),
          PresentationValue:formatAsRatio((cfAccounts['CFOperating']['CurrentValue']) / (isAccounts['NetIncome']['CurrentValue'])),
          PYValue: (cfAccounts['CFOperating']['LastYear']) / (isAccounts['NetIncome']['LastYear']),
           PYPresentationValue:formatAsRatio((cfAccounts['CFOperating']['LastYear']) / (isAccounts['NetIncome']['LastYear'])),
          numerator: 'Cash Flow From Operating Activities',
          Denominator:'Net Income' ,
          Definition: "This financial ratio is used to assess a company's efficiency in generating cash from its net income.",
          Interpretation:"Higher values are typically preferred, as they indicate a company's ability to turn profits into cash effectively." ,
          Type:'Ratio' ,
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //TotalDebt
      if(bsAccounts&&bsAccounts['Assets']&&bsAccounts['Liabilities']){
        ratios.TotalDebt = {
          name: 'Asstes to Debt Ratio',
          Value: (bsAccounts['Assets']['CurrentValue']) / bsAccounts['Liabilities']['CurrentValue'],
          PresentationValue:formatAsRatio((bsAccounts['Assets']['CurrentValue']) / bsAccounts['Liabilities']['CurrentValue']),
          PYValue: (bsAccounts['Assets']['LastYear']) / bsAccounts['Liabilities']['LastYear'],
           PYPresentationValue:formatAsRatio((bsAccounts['Assets']['LastYear']) / bsAccounts['Liabilities']['LastYear']),
          numerator: 'Assets' ,
          Denominator:'Liabilities',
          Definition: "It measures the extent to which a company's assets cover its liabilities, providing insight into the company's solvency and financial stability",
          Interpretation:"Higher ratios are preferred, indicating less reliance on debt." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //EquityMultiplyer
      if(bsAccounts&&bsAccounts&&bsAccounts['Assets']&&bsAccounts['Equity']){
        ratios.EquityMultiplyer = {
          name: 'Inverse Equity Multiplyer',
          Value: (bsAccounts['Equity']['CurrentValue']/bsAccounts['Assets']['CurrentValue']),
          PresentationValue:formatAsRatio((bsAccounts['Equity']['CurrentValue']/bsAccounts['Assets']['CurrentValue'])),
          PYValue: (bsAccounts['Equity']['LastYear']/bsAccounts['Assets']['LastYear']),
           PYPresentationValue:formatAsRatio((bsAccounts['Equity']['LastYear']/bsAccounts['Assets']['LastYear'])),
          numerator: 'Equity',
          Denominator: 'Assets',
          Definition: "Indicates a larger portion of the company's assets is financed through equity, suggesting financial stability and lower leverage.",
          Interpretation:"A higher ratio is seen as more favorable as it indicates a stronger equity position and lower debt levels." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //TimesInterest
      if(cfAccounts&&isAccounts['IntExp']&&isAccounts['OpIncome']){
        ratios.TimesInterest = {
          name: 'Times Interest Ratio',
          Value:(isAccounts['OpIncome']['CurrentValue']/isAccounts['IntExp']['CurrentValue']),
          PresentationValue:formatAsRatio((isAccounts['OpIncome']['CurrentValue']/isAccounts['IntExp']['CurrentValue'])),
          PYValue:(isAccounts['OpIncome']['LastYear']/isAccounts['IntExp']['LastYear']),
          PYPresentationValue:formatAsRatio((isAccounts['OpIncome']['LastYear']/isAccounts['IntExp']['LastYear'])),
          numerator: 'Operating Income',
          Denominator:'Interest Expense' ,
          Definition: "Measures a company's ability to cover its interest obligations.",
          Interpretation:"Higher ratio shows greater ability to cover interest expenses." ,
          Type:'Ratio' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      //DividenPayout
//*************************** */
      //EBITA ***********
      if(cfAccounts&&cfAccounts['DepreciationEXP']&&isAccounts['OpIncome']){
        ratios.EBITA = {
          name: 'EBITA',
          Value:(isAccounts['OpIncome']['CurrentValue']+ cfAccounts['DepreciationEXP']['CurrentValue']),
          PresentationValue:formatAsDollar((isAccounts['OpIncome']['CurrentValue']+ cfAccounts['Depreciation']['CurrentValue']+cfAccounts['Amortization']['CurrentValue'])),
          PYValue:(isAccounts['OpIncome']['LastYear']+ cfAccounts['DepreciationEXP']['LastYear']),
          PYPresentationValue:formatAsDollar((isAccounts['OpIncome']['LastYear']+ cfAccounts['Depreciation']['LastYear']+cfAccounts['Amortization']['LastYear'])),
          numerator: '(Operating Income+ Depreciation+Amortization)',
          Denominator:"",
          Definition: "Earnings before interest, taxes, and amortization, a measure of a company's profitability.",
          Interpretation:"Higher values indicate better operational efficiency and profitability." ,
          Type:'Value' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }
      else if(cfAccounts&&isAccounts['DepAmorExp']&&isAccounts['OpIncome']){
        ratios.EBITA = {
          name: 'EBITA',
          Value:(isAccounts['OpIncome']['CurrentValue']+ isAccounts['DepAmorExp']['CurrentValue']),
          PresentationValue:formatAsDollar((isAccounts['OpIncome']['CurrentValue']+ isAccounts['DepAmorExp']['CurrentValue'])),
          PYValue:(isAccounts['OpIncome']['LastYear']+ isAccounts['DepAmorExp']['LastYear']),
          PYPresentationValue:formatAsDollar((isAccounts['OpIncome']['LastYear']+ isAccounts['DepAmorExp']['LastYear'])),
          numerator: '(Operating Income+ Depreciation+Amortization)',
          Denominator:"",
          Definition: "Earnings before interest, taxes, and amortization, a measure of a company's profitability.",
          Interpretation:"Higher values indicate better operational efficiency and profitability." ,
          Type:'Value' , 
         get perChange() {
            if(this.PYValue)
            {
            return ((this.Value-this.PYValue)/ Math.abs(this.PYValue));
            }
            else
            {
              return ("Not Available")
            }
            
        }
        };
      }

	return ratios;
}