import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import { logAnalyticsEvent } from "../../firebase/firebaseClient";

const FAQ = () => {
	const theme = useTheme();
	logAnalyticsEvent("FAQ Page viewed");
	return (
		<Box
			display="flex"
			flexDirection="column"
			flexWrap="wrap"
			justifyContent="center"
			alignItems="center"
		>
			<Box m="20px" maxWidth={750}>
				<Header title="FAQ" subtitle="Frequently Asked Questions" />

				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							What do the "Strong", "Good", "Weak", etc. classifications mean?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							The model was trained to determine which 10Ks are likely to lead
							to the best returns. The scores between the 5 categories add up to
							1.0, or 100%. A score near 1.0 would indicate the model is very
							confident in a particular category. 0.5 in one and 0.5 in the
							other would indicate that it's a tossup between two categories. If
							the algorithm isn't confident in anyparticular classification, a
							report would get a 0.2 score across the 5 categories.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							What are the Key Sentences?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							The Key Sentences are selected based on how likely they are to
							influence the decision of the model. "This is a 10k annual report
							filing." would not typically be included, but a sentence such as
							"We expect to begin distribution of dividends next quarter" would
							be more impactful.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							How is the summary determined?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							The summary promt is comprised of the key sentences. It is then
							sent through to the Open AI api, where the summary is generated by
							GPT 3.5 based on those sentences.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							Is Open AI classifying these reports and providing the
							classifications too?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							No, the classification model is trained and deployed completely in
							house. Only the summary section comes from the Open AI API.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							How accurate is your model?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							It's important to keep in mind that financial reports are only
							part of the story that makes up a given companies valuation. That
							said, random guessing would result in 20% accuracy, and our model
							achieves 30% when evaluated on 2022 market performance. ie. 50%
							better than guessing.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							Why can't the model perform any better than that?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							The model only knows what is written in a given report. It does
							not know anything else about a company. It can identify statements
							that stand out as potentially impactful, but it is ultimately up
							to the individual investor to consider the postive and negative
							portions of a financial report with the overall picture of the
							economy and industries it is relevant to. We are setting out to
							make that last part much easier, but right now it cannot be fully
							automated.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							When are new financial reports added to AbridgedDD? Do they
							coincide with Earnings releases?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Financial reports are ingested by our models typically within a
							minute of them being filed by the SEC. Though note that there is
							no guarentee made on availability. Companies do not have to file
							10Ks or 10Qs at the same time they report earnings numbers. You
							can find grace period durations defined on the SEC website, and
							these grace periods vary between filing entity classification.
							Certain companies file annual and quarterly reports within days of
							earnings release, and some may take over a month. The full SEC
							filings will virtually always contain more details than
							information gathered in press relases or earnings calls.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							Why do some reports have a high Strong score and a high Weak
							score?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							That pattern would indicate that there were many postive and
							negative forward looking statements. A mixed bag. If everything
							were to go well for the company then it is likely to outperform
							the market in the coming trading sessions, and if things were to
							go poorly then it would likely underperform. That distribution can
							imply volatility in the price of the underlying. ie. "We expect to
							deliver 10 million units in 2023. We have not delivered anything
							in 2022 and there are still risks to overcome before we can
							deliver in 2023."
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							Where can I reach you with questions, comments and concerns?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>Please email admin@abridgeddd.com</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							How does the premium subscription work?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							You may have noticed that you can't view any details on reports
							filed less than a month ago. The premium subscription would
							eliminate this restriction, as well as increase the query limit on
							the discover view, to enable more useful sorting and filtering
							options. See the pricing page for more details and rates.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							If I sign up for a premium subscription, how can I manage my
							subscription?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Once you complete the checkout process, you will have an option to
							manage your subscription in the sidebar. Please only subscribe
							while signed in with an email address you will retain access too.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							What is planned for the future?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							There is a plan for a 'following" system, where a user can be
							notified when a certain company files an SEC report. There are
							also plans for a "trending" view where the most followed and
							viewed reports are listed in order of relevant interest.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion style={{ backgroundColor: theme.palette.primary.accent }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography color={theme.palette.neutral.light} variant="h5">
							Success Stories?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box>
							<Typography>
								This platform isn't really designed to identify one off events,
								but instead provide a more holistic picture of a companies
								reported strengths and weaknesses. That said the writing can
								sometimes be on the wall. Silicon Valley Holdings ($SIVB) filed
								a 10K in late February and received a mostly poor/weak rating.
								They experienced a bankrun and collapsed less than a month
								later. The key sentences picked up on not having CCAR stress
								testing completed yet, and the high number of venture capital
								investment accounts held by the bank. You can check out the
								detail page for yourself{" "}
								<Link color="inherit" href="/details/0000719739-23-000021">
									here
								</Link>
							</Typography>
						</Box>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Box>
	);
};

export default FAQ;
