import Header from "../../components/Header";
import FilterTable from "../../components/filter_table/FilterTable";
import { mockDataReportsColumns } from "../../data/mockReportData";
import Error from "../error";
import { tokens } from "../../theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import { firebaseClient, logAnalyticsEvent } from "../../firebase/firebaseClient";
import {
	doc,
	updateDoc,
	getFirestore,
	collection,
	query,
	limit,
	where,
} from "firebase/firestore";

import { useFollowing } from "../../firebase/useFollowing";
import { useCollectionData } from "react-firebase-hooks/firestore";

const db = getFirestore(firebaseClient);

const Following = ({
	user,
	userLoading,
	isPremium,
	setSelectedRow,
	maxDate,
}) => {
	logAnalyticsEvent("Following Page viewed");
	const theme = useTheme();
	const themeSettings = (mode) => {
		const colors = tokens(mode);

		return {
			palette: {
				mode: mode,

				primary: {
					main: colors.greenAccent[400],
					accent: colors.primary[700],
				},
				secondary: {
					main: colors.greenAccent[400],
				},
				neutral: {
					dark: colors.grey[900],
					main: colors.grey[500],
					light: colors.grey[100],
				},
			},
			typography: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 12,
			},
		};
	};
	const datePickerTheme = createTheme(themeSettings(theme.palette.mode));
	const { userFollowing, followingLoading } = useFollowing(user);

	const reportsRef = collection(db, "reports");

	const q = query(
		reportsRef,
		where("ticker", "in", userFollowing.length > 0 ? userFollowing : [""]),
		limit(process.env.REACT_APP_PREMIUM_REPORT_LIMIT)
	);
	const [reports, loading] = useCollectionData(q, {
		idField: "id",
	});

	if (!user && !userLoading) {
		return <Error></Error>;
	}

	return (
		<Box m="20px">
			<Box textAlign={"left"}>
				{/* HEADER */}
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Header
						title="Following"
						subtitle="View followed tickers, notifications coming soon!"
					/>
				</Box>
				<ThemeProvider theme={datePickerTheme}>
					<Box
						display="grid"
						gridTemplateColumns="repeat(12, 1fr)"
						gridAutoRows="140px"
						gap="20px"
					>
						{/* ROW 3 */}
						<Box
							gridColumn="span 12"
							gridRow="span 6"
							backgroundColor={theme.palette.primary.accent}
							p={2}
							display="flex"
							flexDirection="column"
						>
							{loading || followingLoading || userLoading ? (
								<Box display="flex" alignItems="center" justifyContent="center">
									<CircularProgress
										style={{
											color: theme.palette.secondary.main,
										}}
									/>
								</Box>
							) : reports.length === 0 ? (
								<Alert severity="info">
									You're not following any tickers, you can follow tickers from
									the Discover page after selecting a report.
								</Alert>
							) : (
								<FilterTable
									rows={reports}
									columns={mockDataReportsColumns}
									maxDate={maxDate}
									setSelectedRow={setSelectedRow}
									isPremium={isPremium}
								/>
							)}
						</Box>
					</Box>
					<Box p={2}>
						{userFollowing.map((ticker) => (
							<Accordion
								style={{ backgroundColor: theme.palette.primary.accent }}
								key={userFollowing.indexOf(ticker)}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography color={theme.palette.neutral.light} variant="h6">
										{ticker}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Button
										variant="contained"
										color="error"
										onClick={async () => {
											const userRef = doc(db, "users", user.uid);
											await updateDoc(userRef, {
												following: userFollowing.filter(
													(tick) => tick !== ticker
												),
											});
										}}
									>
										Unfollow
									</Button>
								</AccordionDetails>
							</Accordion>
						))}
					</Box>
				</ThemeProvider>
			</Box>
		</Box>
	);
};

export default Following;
