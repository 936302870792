
export default function getBSValues({BalanceSheetTotals}) {
	const bsAccounts= {
	  Assets: null,
		CurrentAssets: null,
		Cash:null,
		Inventory: null,
		AR:null,
		NonCurrentAssets:null,
		PPE:null,
	  Liabilities: null,
		CurrentLiabilities:null,
		AP:null,
		NonCurrentLiabilities:null,
		LongTermDebt:null,
	  Equity:null,
		RetainedEarning:null,
		CommonStock:null,
		PrefferredStockValue: null,
		APIC:null
	};
	//Assets
	if (BalanceSheetTotals?.Assets) {
	  bsAccounts.Assets = {
		  LastYear: BalanceSheetTotals.Assets.LastYear,
		  CurrentValue: BalanceSheetTotals.Assets.CurrentValue,
		  AbsChange: BalanceSheetTotals.Assets.AbsChange,
		  PercentChange: BalanceSheetTotals.Assets.PercentChange,
		  SourceName:'Assets',
		  DisplayName:'Assets'
	  };
	}
	// CurrentAssets
	if (BalanceSheetTotals?.AssetsCurrent) {
	  bsAccounts.CurrentAssets = {
		  LastYear: BalanceSheetTotals.AssetsCurrent.LastYear,
		  CurrentValue: BalanceSheetTotals.AssetsCurrent.CurrentValue,
		  AbsChange: BalanceSheetTotals.AssetsCurrent.AbsChange,
		  PercentChange: BalanceSheetTotals.AssetsCurrent.PercentChange,
		  SourceName:'AssetsCurrent',
		  DisplayName:'Current Assets'
	  };
	}
	// Cash
	if (BalanceSheetTotals?.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalents) {
	  bsAccounts.Cash = {
		  LastYear: BalanceSheetTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalents.LastYear,
		  CurrentValue: BalanceSheetTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalents.CurrentValue,
		  AbsChange: BalanceSheetTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalents.AbsChange,
		  PercentChange: BalanceSheetTotals.CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalents.PercentChange,
		  SourceName:'CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalents',
		  DisplayName:'Cash'
	  };
	}
	else 
	if (BalanceSheetTotals?.CashAndCashEquivalentsAtCarryingValue) {
	  bsAccounts.Cash = {
		  LastYear: BalanceSheetTotals.CashAndCashEquivalentsAtCarryingValue.LastYear,
		  CurrentValue: BalanceSheetTotals.CashAndCashEquivalentsAtCarryingValue.CurrentValue,
		  AbsChange: BalanceSheetTotals.CashAndCashEquivalentsAtCarryingValue.AbsChange,
		  PercentChange: BalanceSheetTotals.CashAndCashEquivalentsAtCarryingValue.PercentChange,
		  SourceName:'CashAndCashEquivalentsAtCarryingValue',
		  DisplayName:"Cash"
	  };
	}
	// Inventory***
	if (BalanceSheetTotals?.InventoryNet) {
	  bsAccounts.Inventory = {
		  LastYear: BalanceSheetTotals.InventoryNet.LastYear,
		  CurrentValue: BalanceSheetTotals.InventoryNet.CurrentValue,
		  AbsChange: BalanceSheetTotals.InventoryNet.AbsChange,
		  PercentChange: BalanceSheetTotals.InventoryNet.PercentChange,
		  SourceName:'InventoryNet',
		  DisplayName:'Net Inventory'
	  };
	}
	else if(BalanceSheetTotals?.InventoryFinishedGoodsNetOfReserves){
	  bsAccounts.Inventory = {
	  LastYear: BalanceSheetTotals.InventoryFinishedGoodsNetOfReserves.LastYear,
	  CurrentValue: BalanceSheetTotals.InventoryFinishedGoodsNetOfReserves.CurrentValue,
	  AbsChange: BalanceSheetTotals.InventoryFinishedGoodsNetOfReserves.AbsChange,
	  PercentChange: BalanceSheetTotals.InventoryFinishedGoodsNetOfReserves.PercentChange,
	  SourceName:'InventoryFinishedGoodsNetOfReserves',
	  DisplayName:'Net Inventory'
	};
	}
	else if(BalanceSheetTotals?.RetailRelatedInventoryMerchandise){
	  bsAccounts.Inventory = {
	  LastYear: BalanceSheetTotals.RetailRelatedInventoryMerchandise.LastYear,
	  CurrentValue: BalanceSheetTotals.RetailRelatedInventoryMerchandise.CurrentValue,
	  AbsChange: BalanceSheetTotals.RetailRelatedInventoryMerchandise.AbsChange,
	  PercentChange: BalanceSheetTotals.RetailRelatedInventoryMerchandise.PercentChange,
	  SourceName:'RetailRelatedInventoryMerchandise',
	  DisplayName:'Net Inventory'
	};
	}
	//AR
	if (BalanceSheetTotals?.AccountsReceivableNetCurrent) {
	  bsAccounts.AR = {
		  LastYear: BalanceSheetTotals.AccountsReceivableNetCurrent.LastYear,
		  CurrentValue: BalanceSheetTotals.AccountsReceivableNetCurrent.CurrentValue,
		  AbsChange: BalanceSheetTotals.AccountsReceivableNetCurrent.AbsChange,
		  PercentChange: BalanceSheetTotals.AccountsReceivableNetCurrent.PercentChange,
		  SourceName:'AccountsReceivableNetCurrent',
		  DisplayName:'Accounts Receivable'
	  };
	}
	else if (BalanceSheetTotals?.AccountsNotesAndLoansReceivableNetCurrent) {
	  bsAccounts.AR = {
		  LastYear: BalanceSheetTotals.AccountsNotesAndLoansReceivableNetCurrent.LastYear,
		  CurrentValue: BalanceSheetTotals.AccountsNotesAndLoansReceivableNetCurrent.CurrentValue,
		  AbsChange: BalanceSheetTotals.AccountsNotesAndLoansReceivableNetCurrent.AbsChange,
		  PercentChange: BalanceSheetTotals.AccountsNotesAndLoansReceivableNetCurrent.PercentChange,
		  SourceName:'AccountsNotesAndLoansReceivableNetCurrent',
		  DisplayName:'Accounts Receivable'
	  };
	}
  
	///NonCurrentAssets
  
	if (BalanceSheetTotals?.Assets&&BalanceSheetTotals?.AssetsCurrent) {
	  bsAccounts.NonCurrentAssets = {
		  LastYear: BalanceSheetTotals.Assets.LastYear-BalanceSheetTotals.AssetsCurrent.LastYear,
		  CurrentValue: BalanceSheetTotals.Assets.CurrentValue-BalanceSheetTotals.AssetsCurrent.CurrentValue,
		  AbsChange: BalanceSheetTotals.Assets.AbsChange-BalanceSheetTotals.AssetsCurrent.AbsChange,
		  PercentChange: BalanceSheetTotals.Assets.PercentChange-BalanceSheetTotals.AssetsCurrent.PercentChange,
		  SourceName:'Assets-AssetsCurrent',
		  DisplayName:'NonCurrent Assets'
	  };
	}
  
  
  
	///PPE PropertyPlantAndEquipmentNet
  
	if (BalanceSheetTotals?.PropertyPlantAndEquipmentNet) {
	  bsAccounts.PPE = {
		  LastYear: BalanceSheetTotals.PropertyPlantAndEquipmentNet.LastYear,
		  CurrentValue: BalanceSheetTotals.PropertyPlantAndEquipmentNet.CurrentValue,
		  AbsChange: BalanceSheetTotals.PropertyPlantAndEquipmentNet.AbsChange,
		  PercentChange: BalanceSheetTotals.PropertyPlantAndEquipmentNet.PercentChange,
		  SourceName:'PropertyPlantAndEquipmentNet',
		  DisplayName:'PPE'
	  };
	}
  
	//Liabilities*****
	if (BalanceSheetTotals?.Liabilities) {
	  bsAccounts.Liabilities = {
		  LastYear: BalanceSheetTotals.Liabilities.LastYear,
		  CurrentValue: BalanceSheetTotals.Liabilities.CurrentValue,
		  AbsChange: BalanceSheetTotals.Liabilities.AbsChange,
		  PercentChange: BalanceSheetTotals.Liabilities.PercentChange,
		  SourceName: 'Liabilities',
		  DisplayName:'Liabilities'
	  };
	}
	else if (BalanceSheetTotals?.LiabilitiesAndStockholdersEquity&&BalanceSheetTotals?.StockholdersEquity) {
	  bsAccounts.Liabilities = {
		  LastYear: (BalanceSheetTotals.LiabilitiesAndStockholdersEquity.LastYear-BalanceSheetTotals.StockholdersEquity.LastYear),
		  CurrentValue: (BalanceSheetTotals.LiabilitiesAndStockholdersEquity.CurrentValue-BalanceSheetTotals.StockholdersEquity.CurrentValue),
		  AbsChange: (BalanceSheetTotals.LiabilitiesAndStockholdersEquity.AbsChange-BalanceSheetTotals.StockholdersEquity.AbsChange),
		  PercentChange: (BalanceSheetTotals.LiabilitiesAndStockholdersEquity.PercentChange-BalanceSheetTotals.StockholdersEquity.PercentChange),
		  SourceName:'LiabilitiesAndStockholdersEquity-StockholdersEquity ',
		  DisplayName:'Liabilities'
	  };
	}
	//  CurrentLiabilities
	if (BalanceSheetTotals?.LiabilitiesCurrent) {
	  bsAccounts.CurrentLiabilities = {
		  LastYear: BalanceSheetTotals.LiabilitiesCurrent.LastYear,
		  CurrentValue: BalanceSheetTotals.LiabilitiesCurrent.CurrentValue,
		  AbsChange: BalanceSheetTotals.LiabilitiesCurrent.AbsChange,
		  PercentChange: BalanceSheetTotals.LiabilitiesCurrent.PercentChange,
		  SourceName:"LiabilitiesCurrent",
		  DisplayName:'Current Liabilities'
	  };
	}
	//  AP
	if (BalanceSheetTotals?.AccountsPayableCurrent) {
	  bsAccounts.AP = {
		  LastYear: BalanceSheetTotals.AccountsPayableCurrent.LastYear,
		  CurrentValue: BalanceSheetTotals.AccountsPayableCurrent.CurrentValue,
		  AbsChange: BalanceSheetTotals.AccountsPayableCurrent.AbsChange,
		  PercentChange: BalanceSheetTotals.AccountsPayableCurrent.PercentChange,
		  SourceName:"AccountsPayableCurrent",
		  DisplayName:'Accounts Payable'
	  };
	}
  
	//NonCurrentLiabilities
  
	if (BalanceSheetTotals?.Liabilities&&BalanceSheetTotals?.LiabilitiesCurrent) {
	  bsAccounts.NonCurrentLiabilities = {
		  LastYear: BalanceSheetTotals.Liabilities.LastYear-BalanceSheetTotals.LiabilitiesCurrent.LastYear,
		  CurrentValue: BalanceSheetTotals.Liabilities.CurrentValue-BalanceSheetTotals.LiabilitiesCurrent.CurrentValue,
		  AbsChange: BalanceSheetTotals.Liabilities.AbsChange-BalanceSheetTotals.LiabilitiesCurrent.AbsChange,
		  PercentChange: BalanceSheetTotals.Liabilities.PercentChange-BalanceSheetTotals.LiabilitiesCurrent.PercentChange,
		  SourceName:'Liabilities-LiabilitiesCurrent',
		  DisplayName:'NonCurrent Liabilities'
	  };
	}
  
	//LongTermDebt
	if (BalanceSheetTotals?.LongTermDebt) {
	  bsAccounts.LongTermDebt = {
		  LastYear: BalanceSheetTotals.LongTermDebt.LastYear,
		  CurrentValue: BalanceSheetTotals.LongTermDebt.CurrentValue,
		  AbsChange: BalanceSheetTotals.LongTermDebt.AbsChange,
		  PercentChange: BalanceSheetTotals.LongTermDebt.PercentChange,
		  SourceName:"LongTermDebt",
		  DisplayName:'Long Term Debt'
	  };
	}
  
	//Equity StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest
	if (BalanceSheetTotals?.StockholdersEquity) {
	  bsAccounts.Equity = {
		  LastYear: BalanceSheetTotals.StockholdersEquity.LastYear,
		  CurrentValue: BalanceSheetTotals.StockholdersEquity.CurrentValue,
		  AbsChange: BalanceSheetTotals.StockholdersEquity.AbsChange,
		  PercentChange: BalanceSheetTotals.StockholdersEquity.PercentChange,
		  SourceName:"StockholdersEquity",
		  DisplayName:"Equity"
	  };
	}
	else if (BalanceSheetTotals?.StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest) {
		bsAccounts.Equity = {
			LastYear: BalanceSheetTotals.StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest.LastYear,
			CurrentValue: BalanceSheetTotals.StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest.CurrentValue,
			AbsChange: BalanceSheetTotals.StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest.AbsChange,
			PercentChange: BalanceSheetTotals.StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest.PercentChange,
			SourceName:"StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest",
			DisplayName:"Equity"
		};
	  }
	  else if (BalanceSheetTotals?.LiabilitiesAndStockholdersEquity&&BalanceSheetTotals?.Liabilities) {
		bsAccounts.Equity = {
			LastYear: (BalanceSheetTotals.LiabilitiesAndStockholdersEquity.LastYear-BalanceSheetTotals.Liabilities.LastYear),
			CurrentValue: (BalanceSheetTotals.LiabilitiesAndStockholdersEquity.CurrentValue-BalanceSheetTotals.Liabilities.CurrentValue),
			AbsChange: (BalanceSheetTotals.LiabilitiesAndStockholdersEquity.AbsChange-BalanceSheetTotals.Liabilities.AbsChange),
			PercentChange: (BalanceSheetTotals.LiabilitiesAndStockholdersEquity.PercentChange-BalanceSheetTotals.Liabilities.PercentChange),
			SourceName:'LiabilitiesAndStockholdersEquity-Liabilities',
			DisplayName:'Liabilities'
		};
	  }
   // RetainedEarning
   if (BalanceSheetTotals?.RetainedEarningsAccumulatedDeficit) {
	bsAccounts.RetainedEarning = {
		LastYear: BalanceSheetTotals.RetainedEarningsAccumulatedDeficit.LastYear,
		CurrentValue: BalanceSheetTotals.RetainedEarningsAccumulatedDeficit.CurrentValue,
		AbsChange: BalanceSheetTotals.RetainedEarningsAccumulatedDeficit.AbsChange,
		PercentChange: BalanceSheetTotals.RetainedEarningsAccumulatedDeficit.PercentChange,
		SourceName:"RetainedEarningsAccumulatedDeficit",
		DisplayName:"Retained Earnings"
	};
  }
	//  CommonStockCommonStockValueOutstanding
	if (BalanceSheetTotals?.CommonStockValue) {
	  bsAccounts.CommonStock = {
		  LastYear: BalanceSheetTotals.CommonStockValue.LastYear,
		  CurrentValue: BalanceSheetTotals.CommonStockValue.CurrentValue,
		  AbsChange: BalanceSheetTotals.CommonStockValue.AbsChange,
		  PercentChange: BalanceSheetTotals.CommonStockValue.PercentChange,
		  SourceName:"CommonStockValue",
		  DisplayName:'Common Stock'
	  };
	} else if (BalanceSheetTotals?.CommonStockValueOutstanding) {
		bsAccounts.CommonStock = {
			LastYear: BalanceSheetTotals.CommonStockValueOutstanding.LastYear,
			CurrentValue: BalanceSheetTotals.CommonStockValueOutstanding.CurrentValue,
			AbsChange: BalanceSheetTotals.CommonStockValueOutstanding.AbsChange,
			PercentChange: BalanceSheetTotals.CommonStockValueOutstanding.PercentChange,
			SourceName:"CommonStockCommonStockValueOutstanding",
			DisplayName:'Common Stock'
		};
	  }

	//  PrefferredStockValue
	if (BalanceSheetTotals?.PreferredStockValue) {
	  bsAccounts.PrefferredStockValue = {
		  LastYear: BalanceSheetTotals.PreferredStockValue.LastYear,
		  CurrentValue: BalanceSheetTotals.PreferredStockValue.CurrentValue,
		  AbsChange: BalanceSheetTotals.PreferredStockValue.AbsChange,
		  PercentChange: BalanceSheetTotals.PreferredStockValue.PercentChange,
		  SourceName:"PreferredStockValue",
		  DisplayName:'Value of Preferred Stock'
	  };
	}
	//  APIC
	if (BalanceSheetTotals?.AdditionalPaidInCapitalCommonStock) {
		bsAccounts.APIC = {
			LastYear: BalanceSheetTotals.AdditionalPaidInCapitalCommonStock.LastYear,
			CurrentValue: BalanceSheetTotals.AdditionalPaidInCapitalCommonStock.CurrentValue,
			AbsChange: BalanceSheetTotals.AdditionalPaidInCapitalCommonStock.AbsChange,
			PercentChange: BalanceSheetTotals.AdditionalPaidInCapitalCommonStock.PercentChange,
			SourceName:"AdditionalPaidInCapitalCommonStock",
			DisplayName:'APIC'
		};
	  }
	  return bsAccounts;
  }