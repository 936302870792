
import { useTheme, useMediaQuery, CardActions, Grid, Card, CardContent, Typography, Collapse, Tooltip} from '@mui/material';
import { getColorFromPercentage } from '../../../theme';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FSCard({data, text, expanded, handleToggle, ExpandMore}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formatAsDollar = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value / 1000);
  };

  const formatAsPercent = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value / 100);
  };

  const fsData = Object.values(data).filter(value => value !== null);

  const cellStyle = isSmallScreen ? { padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis',backgroundImage: "none",backgroundColor:"transparent",height: '100%' } : { padding: '0px',backgroundImage: "none",backgroundColor:"transparent",height: '100%' };
  const cardStyle ={display: 'grid', placeItems: 'center', height: '100%', width: '100%',backgroundColor: "transparent",boxShadow: 'none',border: 'none',opacity: 1,backgroundImage: "none",padding: '0px'}
  return (
    <Card sx={{ backgroundColor: theme.palette.primary.accent, color: theme.palette.secondary.main,padding: '0px' }}>
      <CardContent>
        <Typography variant="h5">
          {text}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>

        <ExpandMore expand={expanded} onClick={handleToggle} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>


      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit >
        
      <Typography>Values in thousands</Typography>
          <Grid container >
          <Grid container item xs={12} >
               {/* Creating a card for each cell in the row */}
               <Grid item xs={12} >
               <Card square={true} sx={cellStyle}>
               <CardContent>
          <Grid container item xs={12} >
          <Grid item xs={3}>
          <Card style={cardStyle}>
              <CardContent sx={{ textAlign: 'center', p: 0, mt: 2 }}>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'}>{"Account"}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
          <Card style={cardStyle}>
              <CardContent sx={{ textAlign: 'center', p: 0, mt: 2 }}>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'}>{"Current Year"}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
          <Card style={cardStyle}>
              <CardContent sx={{ textAlign: 'center', p: 0, mt: 2 }}>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'}>{"Prior Year"}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
          <Card style={cardStyle}>
              <CardContent sx={{ textAlign: 'center', p: 0, mt: 2 }}>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'}>{"Percent Change"}</Typography>
              </CardContent>
            </Card>
          </Grid>
      </Grid>
      </CardContent>
            </Card>
            </Grid>
            </Grid>
            {fsData.map((item, index) => (
               <Grid container item xs={12} key={index}>
               {/* Creating a card for each cell in the row */}
               <Grid item xs={12} >
               <Card square={true} sx={{ backgroundColor: getColorFromPercentage(item.PercentChange),boxShadow: 'none',border: 'none',backgroundImage: "none" }}>
               <CardContent>
        <Grid container item xs={12} >
          {/* Creating a card for each cell in the row */}
          <Grid item xs={3}>
          <Tooltip title={item.SourceName} arrow>
          <Card style={{ display: 'grid', placeItems: 'center', height: '100%', width: '100%',backgroundColor: "transparent",boxShadow: 'none',border: 'none',backgroundImage: "none"}}>
              <CardContent sx={{ textAlign: 'center', p: 0, mt: 2 }}>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'}>{item.DisplayName}</Typography>
              </CardContent>
            </Card>
          </Tooltip>
          </Grid>
          <Grid item xs={3}>
          <Card style={cardStyle}>
              <CardContent sx={{ textAlign: 'center', p: 0, mt: 2 }}>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'}>{formatAsDollar(item.CurrentValue)}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} >
            <Card style={cardStyle}>
              <CardContent sx={{ textAlign: 'center', p: 0, mt: 2 }}>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'}>{formatAsDollar(item.LastYear)}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} >
          <Card style={cardStyle}>
              <CardContent sx={{ textAlign: 'center', p: 0, mt: 2}}>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'}>{formatAsPercent(item.PercentChange)}</Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* More cells/cards as needed */}
        </Grid>
        </CardContent>
            </Card>
            </Grid>
            </Grid>
      ))}
      
    </Grid>



           {/* Render other components or additional tables <FSTable data={fsData} /> */}   

      {/* Additional content here */}
        
      </Collapse>
    </Card>
  );
}