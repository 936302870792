import { Box, Typography, useTheme } from "@mui/material";
import { BuySellMap } from "../../theme";
import CheckoutButton from "../../components/CheckoutButton";
import PricingDisclaimer from "./pricingDisclaimer";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import StarIcon from "@mui/icons-material/Star";

const DesktopLayout = () => {
	const theme = useTheme();

	return (
		<Box p={2}>
			<Box
				display="grid"
				gridTemplateColumns="repeat(12, 1fr)"
				columnGap="20px"
				rowGap="2px"
			>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box backgroundColor={theme.palette.primary.main}></Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Free Tier
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<StarIcon style={{ color: theme.palette.premium.main }} />
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Premium Monthly
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<StarIcon style={{ color: theme.palette.premium.main }} />
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Premium Annual
						</Typography>
					</Box>
				</Box>

				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
							fontWeight="bold"
						>
							Monthly Price
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.secondary.main}
						>
							Free
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.secondary.main}
						>
							$4.49
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.secondary.main}
						>
							$2.99
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
							fontWeight="bold"
						>
							Live Data
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CancelIcon sx={{ fill: BuySellMap["Weak"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							One Month Delay
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CheckCircleIcon sx={{ fill: BuySellMap["Strong"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							No Delay
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CheckCircleIcon sx={{ fill: BuySellMap["Strong"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							No Delay
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
							fontWeight="bold"
						>
							Query Limits
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<KeyboardDoubleArrowDownIcon
							sx={{ fontSize: 30, fill: BuySellMap["Weak"] }}
						/>
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Max {process.env.REACT_APP_FREE_REPORT_LIMIT} results, no export
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<KeyboardDoubleArrowUpIcon
							sx={{ fontSize: 30, fill: BuySellMap["Strong"] }}
						/>
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Max {process.env.REACT_APP_PREMIUM_REPORT_LIMIT} results, with
							export
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<KeyboardDoubleArrowUpIcon
							sx={{ fontSize: 30, fill: BuySellMap["Strong"] }}
						/>
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Max {process.env.REACT_APP_PREMIUM_REPORT_LIMIT} results, with
							export
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
							fontWeight="bold"
						>
							Following Page
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CancelIcon sx={{ fill: BuySellMap["Weak"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Cannot follow tickers
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CheckCircleIcon sx={{ fill: BuySellMap["Strong"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Follow any ticker
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CheckCircleIcon sx={{ fill: BuySellMap["Strong"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Follow any ticker
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant="h4"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
							fontWeight="bold"
						>
							Value Pricing
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CancelIcon sx={{ fill: BuySellMap["Weak"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							No
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CancelIcon sx={{ fill: BuySellMap["Weak"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							No
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CheckCircleIcon sx={{ fill: BuySellMap["Strong"] }} />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							Yes! Lock in a low price!
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box backgroundColor={theme.palette.primary.main}></Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CheckoutButton type="free" />
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CheckoutButton type="monthly" />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							$4.49 Billed Monthly
						</Typography>
					</Box>
				</Box>
				<Box display="grid" gridRow="span 2" gridColumn="span 3" gap="20px">
					<Box
						p="15px"
						display="flex "
						flexDirection={"column"}
						spacing={15}
						justifyContent="space-between"
						alignItems="center"
						textAlign={"center"}
						backgroundColor={theme.palette.primary.accent}
					>
						<CheckoutButton type="annual" />
						<Typography
							variant="h6"
							backgroundcolor={theme.palette.primary.accent}
							sx={{ m: "10px 10px 5px 10px" }}
							color={theme.palette.neutral.light}
						>
							$35.88 Billed Annually
						</Typography>
					</Box>
				</Box>
			</Box>

			<PricingDisclaimer />
		</Box>
	);
};

export default DesktopLayout;
