import {
	Box,
	FormControl,
	NativeSelect,
	InputLabel,
	IconButton,
	useTheme,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { tokens } from "../../theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import Header from "../../components/Header";
import FilterTable from "../../components/filter_table/FilterTable";
import { firebaseClient, logAnalyticsEvent } from "../../firebase/firebaseClient";

import {
	getFirestore,
	collection,
	query,
	orderBy,
	limit,
	where,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
const db = getFirestore(firebaseClient);

const Discover = ({
	setSelectedRow,
	startDate,
	endDate,
	maxDate,
	isPremium,
	setStartDate,
	setEndDate,
}) => {
	logAnalyticsEvent("Discover Page viewed");
	const theme = useTheme();
	const themeSettings = (mode) => {
		const colors = tokens(mode);

		return {
			palette: {
				mode: mode,

				primary: {
					main: colors.greenAccent[400],
					accent: colors.primary[700],
				},
				secondary: {
					main: colors.greenAccent[400],
				},
				neutral: {
					dark: colors.grey[900],
					main: colors.grey[500],
					light: colors.grey[100],
				},
			},
			typography: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 12,
			},
		};
	};

	const [index, setIndex] = useState("SP500");
	const reportsRef = collection(db, "reports");

	const indexMap = {
		Any: ["Any"],
		DOW: ["DOW", "Dow Jones"],
		SP100: ["SP100", "S&P 100"],
		SP500: ["SP500", "S&P 500"],
		Nasdaq: ["Nasdaq", "NASDAQ"],
	};

	const [searchTerm, setSearchTerm] = useState(null);
	const [queryLimit, setSearchTermLimit] = useState(isPremium ? 40 : 200);

	function buildQuery() {
		var q = query(reportsRef);
		//if there's a search term, that overrides the other filters.
		if (searchTerm) {
			q = query(
				q,
				where("ticker", ">=", searchTerm),
				orderBy("ticker", "asc"),
				limit(10)
			);
			return q;
		}
		q = query(
			q,
			where("date_filed", ">=", startDate.toDate()),
			where("date_filed", "<=", endDate.toDate())
		);

		if (index !== "Any") {
			q = query(q, where("indicies", "array-contains-any", indexMap[index]));
		}
		q = query(q, orderBy("date_filed", "desc"), limit(queryLimit));
		return q;
	}

	const reportsQuery = buildQuery();

	const [reports, loading] = useCollectionData(reportsQuery, {
		idField: "id",
	});

	const datePickerTheme = createTheme(themeSettings(theme.palette.mode));

	function valid_date(date) {
		return date;
	}
	const isEndDateDisabled = (date) => {
		if (date < startDate) {
			return true;
		}
		return false;
	};

	const isStartDateDisabled = (date) => {
		if (date > endDate) {
			return true;
		}
		return false;
	};

	return (
		<Box p={2} flexDirection="column" height="100vh">
			<Helmet>
				<title>Abridged Due Diligence - Discover The Details!</title>
				<meta
					name="description"
					content="The latest SEC filings are analyzed by the latest in artificial intelligence technology. Uncover and learn about forward looking statements within 10-K and 10-Q reports. Updated in real time as they are filed with the SEC"
				/>
			</Helmet>
			{/* header */}
			<Box
				maxHeight="10vh"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			> {isPremium ? (
				<Header title="Discover" subtitle=  "Latest Filings" />
			  ) : (
				<Header title="Discover" subtitle=  "Latest Filings- Free accounts are limited to reports filed 30 days ago or more" />
			  )}
				
			</Box>

			{/* filters and table */}
			<Box
				p={2}
				backgroundColor={theme.palette.primary.accent}
				display="flex"
				flexDirection="column"
				flexGrow={1}
			>
				<ThemeProvider theme={datePickerTheme}>
					{/* Filters */}

					<Box
						display="flex"
						flexGrow={1}
						justifyContent={"space-between"}
						gap="20px"
					>
						<Box
							display="flex"
							flexWrap="wrap"
							alignItems="left"
							gap="20px"
							width={"80%"}
						>
							<Box
								display="flex"
								backgroundColor={theme.palette.primary.main}
								borderRadius="3px"
							>
								<InputBase
									sx={{
										ml: 2,
										flex: 1,
									}}
									defaultValue={query}
									placeholder="Search by Ticker"
									onChange={(event) => {
										setSearchTerm(event.target.value.toUpperCase());
									}}
								/>
								<IconButton type="button" sx={{ p: 1 }}>
									<SearchIcon />
								</IconButton>
							</Box>

							<Box
								display="flex"
								flexDirection="column"
								color={theme.palette.neutral.light}
							>
								<DatePicker
									label="From"
									value={startDate}
									onChange={(newValue) =>
										setStartDate(valid_date(newValue.startOf("day")))
									}
									shouldDisableDate={isStartDateDisabled}
								/>
							</Box>
							<Box
								display="flex"
								flexDirection="column"
								color={theme.palette.neutral.light}
							>
								<DatePicker
									label="To"
									value={endDate}
									onChange={(newValue) =>
										setEndDate(valid_date(newValue.endOf("day")))
									}
									shouldDisableDate={isEndDateDisabled}
								/>
							</Box>
							<ToggleButtonGroup
								color="primary"
								value={index}
								exclusive
								onChange={(event, newIndex) => {
									if (newIndex) {
										setIndex(newIndex);
									} else {
										setIndex("Any");
									}
								}}
								aria-label="Platform"
							>
								<ToggleButton value="Any">Any</ToggleButton>
								<ToggleButton value="DOW">Dow</ToggleButton>
								<ToggleButton value="SP100">S&P 100</ToggleButton>
								<ToggleButton value="SP500">S&P 500</ToggleButton>
								<ToggleButton value="Nasdaq">Nasdaq</ToggleButton>
							</ToggleButtonGroup>
						</Box>
						{/* Server Result Count */}
						<Box width={"20%"}>
							<FormControl fullWidth>
								<InputLabel variant="standard" htmlFor="uncontrolled-native">
									Result Count
								</InputLabel>
								<NativeSelect
									value={queryLimit}
									onChange={(event) => {
										setSearchTermLimit(event.target.value);
									}}
									inputProps={{
										name: "server-result-count",
										id: "uncontrolled-native",
									}}
								>
									<option value={40}>40</option>
									<option value={200}>200</option>
									<option disabled={!isPremium} value={1000}>
										1000
									</option>
									<option disabled={!isPremium} value={5000}>
										5000
									</option>
								</NativeSelect>
							</FormControl>
						</Box>
					</Box>
					<Box flexGrow={1} height="100%">
						<FilterTable
							maxDate={maxDate}
							rows={reports}
							loading={loading}
							setSelectedRow={setSelectedRow}
							isPremium={isPremium}
						/>
					</Box>
				</ThemeProvider>
			</Box>
		</Box>
	);
};

export default Discover;
