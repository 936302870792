import { getColorFromPercentage } from "../../../theme";
import { useTheme, Card, CardContent, Typography, Tooltip,Grid } from "@mui/material";

export default function GlanceCard({ data }) {
  const theme = useTheme();
  const cardStyle = {
    backgroundColor: getColorFromPercentage(data.perChange * 100),
    width: "100%",
    overflow: "hidden",
    padding: "0px", // Padding inside the card
    margin: "0px", // Margin around the card
    
  };
  const formatAsPercent = (value) => {
    const formattedPercent = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  
    // Remove the '%' symbol from the formatted string
    return formattedPercent;
  };
  return (
    <Tooltip title={data.name}>
      <Card style={cardStyle}>
        <CardContent p={0} spacing={0}>
        <Grid container p={0} alignItems="center">
        <Grid  item xs={12} sm={12} md={12} lg={12} maxHeight="50px" p={2} >
        <Typography
            align={"center"}
            sx={{ fontSize: 16, color: theme.palette.neutral.light, whiteSpace: 'nowrap',  // Prevents text wrapping
            overflow: 'hidden',   // Hides overflowed text
            textOverflow: 'ellipsis' }} // Adds ellipsis to overflowed text}}
          >
            {data.name}
          </Typography>
          
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          {data.PYValue && (
        <Typography
            align="center"
            sx={{ fontSize: 16, color: theme.palette.neutral.light }}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            P Y
          </Typography>
            
            )}
          <Typography align="center" sx={{ fontSize: 16, color: theme.palette.neutral.light }}>
            {data.PYPresentationValue}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          {data.PYValue && (
        <Typography
            align="center"
            sx={{ fontSize: 16, color: theme.palette.neutral.light }}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            C Y
          </Typography>
            
            )}
          <Typography align="center" sx={{ fontSize: 16, color: theme.palette.neutral.light }}>
            {data.PresentationValue}
          </Typography>
        </Grid>

        
        <Grid item xs={4} sm={4} md={4} lg={4}>
        {data.PYValue && (
        <Typography
            align="center"
            sx={{ fontSize: 16, color: theme.palette.neutral.light }}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            % ∆
          </Typography>
           )}
          {data.PYValue && (
          <Typography align="center" sx={{ fontSize: 16, color: theme.palette.neutral.light }}>
            {formatAsPercent(data.perChange)}
          </Typography>
           )}
        </Grid>
      </Grid>
        </CardContent>
      </Card>
      </Tooltip>
  );
}
