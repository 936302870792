import { Typography, Box, useTheme } from "@mui/material";

const Header = ({ title, subtitle }) => {
	const theme = useTheme();

	return (
		<Box mb="30px">
			<Typography
				variant="h2"
				color={theme.palette.neutral.light}
				fontWeight="bold"
				sx={{ m: "10px 10px 5px 10px" }}
			>
				{title}
			</Typography>
			<Typography
				variant="h5"
				sx={{ m: "10px 10px 5px 10px" }}
				color={theme.palette.secondary.main}
			>
				{subtitle}
			</Typography>
		</Box>
	);
};

export default Header;
