import { Box, useTheme } from "@mui/material";

const ProgressCircle = ({
	backgroundColor,
	progress = "0.75",
	size = "40",
}) => {
	const theme = useTheme();

	const angle = progress * 360;
	return (
		<Box
			sx={{
				background: `radial-gradient(${
					backgroundColor ?? theme.palette.primary.accent
				} 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${"#141414"} ${angle}deg 360deg),
            ${"#e0e0e0"}`,
				borderRadius: "50%",
				width: `${size}px`,
				height: `${size}px`,
			}}
		/>
	);
};

export default ProgressCircle;
