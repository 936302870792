import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { mockDataReportsColumns } from "../../data/mockReportData";
import StyledDataGrid from "./StyledDataGrid";

const FilterTable = ({ maxDate, rows, loading, setSelectedRow, isPremium }) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const [invalid, setInvalid] = useState(false);

	const handleEvent = (params) => {
		if (params.row.date_filed.toDate() > maxDate.toDate()) {
			setInvalid(true);
		} else {
			setInvalid(false);
			setSelectedRow(params.row);
			navigate(`/details/${params.row["acc-no"]}`);
		}
	};

	const handleRowContextMenu = (event, params) => {
		event.preventDefault(); // Prevent the default context menu from appearing
		
		// Here, you can open the row's details page in a new tab
		const accNo = params.row["acc-no"];
		const url = `/details/${accNo}`;
		window.open(url, '_blank'); // Open the URL in a new tab
	  };

	const scoremap = ["Strong", "Good", "Average", "Poor", "Weak"];

	function top_rating(scores) {
		const max = Math.max(...scores);
		if (max < 0.01) {
			return "N/A";
		}
		return scoremap[scores.indexOf(max)];
	}

	function addTopModelScore(row_data) {
		const updated_rows = row_data.map((row) => {
			const scores = [
				row.strong_buy_score,
				row.buy_score,
				row.hold_score,
				row.sell_score,
				row.strong_sell_score,
			];
			return {
				...row,
				top_rating: top_rating(scores),
			};
		});

		return updated_rows;
	}

	function obfuscateModelScores() {
		const updated_rows = rows.map((row) => {
			if (row.date_filed.toDate() > maxDate.toDate()) {
				return {
					...row,
					strong_buy_score: 0.0,
					buy_score: 0.0,
					hold_score: 0.0,
					sell_score: 0.0,
					strong_sell_score: 0.0,
				};
			}
			return row;
		});

		return updated_rows;
	}

	function modifyData() {
		const obfuscated_rows = obfuscateModelScores();
		const updated_rows = addTopModelScore(obfuscated_rows);
		return updated_rows;
	}

	return (
		<Box
			m="20px 0 0 0"
			height="90vh"
			sx={{
				"& .MuiDataGrid-root": {
					border: "none",
				},
				"& .MuiDataGrid-cell": {
					borderBottom: "none",
				},
				"& .name-column--cell": {
					color: theme.palette.secondary.main,
				},
				"& .MuiDataGrid-columnHeaders": {
					backgroundColor: theme.palette.primary.accent,
					borderBottom: "none",
				},
				"& .MuiDataGrid-virtualScroller": {
					backgroundColor: theme.palette.primary.accent,
				},
				"& .MuiDataGrid-footerContainer": {
					borderTop: "none",
					backgroundColor: theme.palette.primary.accent,
				},
				"& .MuiCheckbox-root": {
					color: `${theme.palette.secondary.main} !important`,
				},
				"& .MuiDataGrid-toolbarContainer .MuiButton-text": {
					color: `${theme.palette.neutral.light} !important`,
				},
			}}
		>
			{invalid ? (
				<Alert severity="error">
					Free Users are limited to a 1 month delay.
				</Alert>
			) : null}
			{rows ? (
				<StyledDataGrid
					rows={modifyData()}
					columns={mockDataReportsColumns}
					onRowClick={handleEvent}
					onRowContextMenu={handleRowContextMenu} 
					getRowId={(row) => row["acc-no"]}
					getRowClassName={(params) =>
						`super-app-theme--${params.row.top_rating}`
					}
					loading={loading}
					isPremium={isPremium}
				/>
			) : (
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					height="100vh"
				>
					<Box textAlign={"center"}>
						<CircularProgress
							style={{
								color: theme.palette.secondary.main,
							}}
						/>
					</Box>
				</Grid>
			)}
		</Box>
	);
};

export default FilterTable;
