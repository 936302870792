import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import createCheckoutSession from "../stripe/createCheckoutSession";
import { firebaseClient, logAnalyticsEvent } from "../firebase/firebaseClient";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useAccessLevel } from "../firebase/useAccessLevel";
import redirectToCustomerPortal from "../stripe/redirectToCustomerPortal";
import Button from "@mui/material/Button";

export default function CheckoutButton({ type }) {
	const theme = useTheme();

	const button_stlye = {
		color: theme.palette.neutral.dark,
		backgroundColor: theme.palette.secondary.main,
	};

	const [loading, setLoading] = useState(false);
	const [user] = useAuthState(getAuth(firebaseClient));
	const navigate = useNavigate();
	const { isPremium } = useAccessLevel(user);

	function launchCustomerPortal() {
		setLoading(true);
		redirectToCustomerPortal();
	}
	async function checkout(uid, type) {
		setLoading(true);
		const price = type === "annual" ? 35.88 : 4.49;

		const checkoutParams = {
			currency: "USD",
			value: price,
			type: type,
		};

		logAnalyticsEvent("begin checkout", checkoutParams);

		await createCheckoutSession(uid, type);
	}

	if (loading) {
		return (
			<Box gridColumn="span 4" m="30px 30px">
				<CircularProgress
					style={{
						color: theme.palette.secondary.main,
					}}
				/>
			</Box>
		);
	} else if (isPremium) {
		if (!loading) {
			return (
				<Box m="30px 30px" gridColumn="span 4">
					<Button
						variant="contained"
						style={button_stlye}
						onClick={() => {
							launchCustomerPortal();
						}}
					>
						Manage Account
					</Button>
				</Box>
			);
		}
	} else if (user) {
		if (type === "free") {
			return null;
		}
		return (
			<Box m="30px 30px" gridColumn="span 4">
				<Button
					variant="contained"
					style={button_stlye}
					onClick={() => checkout(user.uid, type)}
				>
					Continue
				</Button>
			</Box>
		);
	} else {
		return (
			<Box gridColumn="span 4" m="30px 30px">
				<Button
					variant="contained"
					style={button_stlye}
					onClick={() => navigate("/signIn")}
				>
					Sign In
				</Button>
			</Box>
		);
	}
}
