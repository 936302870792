import {useState} from "react";
import { Box, Tooltip, Fab, Typography, useTheme } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import noData from "../../../components/NoData";
import RemoveIcon from '@mui/icons-material/Remove';
import {
	StyledTreeItem,
	CloseSquare,
	PlusSquare,
	MinusSquare,
} from "../../../components/tree/styleComponents";

export default function FinancialTree({ data }) {
	const theme = useTheme();

	const BalanceSheet = JSON.parse(data["BalanceSheetSkeleton"]);
	const CashFlow = JSON.parse(data["CashFlowSkeleton"]);
	const IncomeStatement = JSON.parse(data["IncomeStatementSkeleton"]);
	
	const BalanceSheetTotals = JSON.parse(data["BalanceSheetTotals"]);
	const CashFlowTotals = JSON.parse(data["CashFlowTotals"]);
	const IncomeStatementTotals = JSON.parse(data["IncomeStatementTotals"]);

	const opa = JSON.parse(data["OnePercentOfAssets"]);

    const [expanded, setExpanded] = useState([]);
    const handleCollapseAll = () => {
        setExpanded([]);
    };

	function titleCase(str) {
		const noAbstract = str.replace(/Abstract$/gi, "");
		return noAbstract.replace(/([A-Z])/g, " $1").trim();
	}
	function formatAsDollar(value, units) {
		value = value / 1000;
		if(units){
			return new Intl.NumberFormat('en-US', { style: 'currency', 
													currency: 'USD', 
													minimumFractionDigits: 0,  
													maximumFractionDigits: 0 }).format(value);
		}
		return value;
	}
	function formatAsPercent(value) {
		try{
			return (value.toFixed(2)).toString() + '%'
		}
		catch{
			return Infinity
		}
	}

	// Recursive helper function to calculate total value for a given node
	function extractValueFromNode(node, name, totals) {
		name = name.replace(/Abstract$/gi, "")
		const rootValues = ["StatementOfFinancialPosition", "IncomeStatement", "StatementOfCashFlows"]
		if (rootValues.includes(name)){
			return {
				labelPreviousValue: "Last Year",
				labelCurrentValue: "Current Value",
				labelAbsoluteChange: "Abs Change",
				labelPercentChange: "% Change",
			};
		}
		
		try{
			const obj = totals[name]
			const err = obj["Calculated"]
			if(err != null){
				let units = obj["Units"] === "USD";
				return {
					labelPreviousValue: formatAsDollar(obj["LastYear"], units),
					labelCurrentValue: formatAsDollar(obj["CurrentValue"], units),
					labelAbsoluteChange: formatAsDollar(obj["AbsChange"], units), 
					labelPercentChange: formatAsPercent(obj["PercentChange"]),
				};
			}
			else{
				return {
					labelPreviousValue: null,
					labelCurrentValue: null,
					labelAbsoluteChange: null,
					labelPercentChange: null,
				};
			}
		}
		catch (e){
			return {
						labelPreviousValue: null,
						labelCurrentValue: null,
						labelAbsoluteChange: null,
						labelPercentChange: null,
					};
		}
		// name = name.replace(/Abstract$/gi, "")
		// const rootValues = ["StatementOfFinancialPosition", "IncomeStatement", "StatementOfCashFlows"]
		// if (rootValues.includes(name)){
		// 	return {
		// 		labelPreviousValue: "Last Year",
		// 		labelCurrentValue: "Current Value",
		// 		labelAbsoluteChange: "Abs Change",
		// 		labelPercentChange: "% Change",
		// 	};
		// }
		// // If node is not a leaf
		// if (!CompanyFacts[name]?.values) {
		// 	return {
		// 		labelPreviousValue: null,
		// 		labelCurrentValue: null,
		// 		labelAbsoluteChange: null,
		// 		labelPercentChange: null,
		// 	};
		// }
		// let arr = CompanyFacts[name]["values"];
		// let units = CompanyFacts[name]["units"] === "USD";
		// let lastElement = arr[arr.length - 1];
		
		
		// let currentFY = lastElement["fy"];
		// let currentFP = lastElement["fp"];
		// let oneYearEarlier = arr.find(
		// 	(item) => item.fp === currentFP && item.fy === currentFY - 1
		// );
		// oneYearEarlier = oneYearEarlier || {val: 0.00}


		// let change = lastElement.val - oneYearEarlier.val;
		// let percentChange = (change / Math.abs(oneYearEarlier.val)) * 100;
		// if(change < opa){
		// 	return {
		// 		labelPreviousValue: null,
		// 		labelCurrentValue: null,
		// 		labelAbsoluteChange: null,
		// 		labelPercentChange: null,
		// 	};
		// }
		// return {
		// 	labelPreviousValue: formatAsDollar(oneYearEarlier.val, units),
		// 	labelCurrentValue: formatAsDollar(lastElement.val, units),
		// 	labelAbsoluteChange: formatAsDollar(Math.abs(change), units), 
		// 	labelPercentChange: (isNaN(percentChange) ? 0.00: percentChange.toFixed(2)).toString() + '%',
		// };
	}

	let nodeId = 0;
	function renderTree(node, name, parentId = "0", totals) {
		//totals = null;
		const currentId = `${parentId}-${nodeId++}`;
		const { labelPreviousValue, labelCurrentValue, labelAbsoluteChange, labelPercentChange } =
			extractValueFromNode(node, name, totals);
		return (
			<StyledTreeItem
				key={currentId}
				nodeId={currentId}
				labelText={titleCase(name)}
				labelPreviousValue={labelPreviousValue || ""}
				labelCurrentValue={labelCurrentValue || ""}
				labelAbsoluteChange={labelAbsoluteChange || ""}
				labelPercentChange={labelPercentChange || ""}
			>
				{Object.entries(node)
                .filter(([childName]) => totals[childName] && totals[childName].AbsChange >= opa)
                .map(([childName, childNode]) =>
                    renderTree(childNode, childName, currentId, totals)
                )
            }
			</StyledTreeItem>
		);
	}

	return (
		<Box>
			<Box textAlign={"right"} p = {2} display="flex" alignItems= "center" justifyContent={"space-between"}>
				<Typography color= {theme.palette.neutral.main} variant="h6" style={{ marginRight: '50px' }}>Values in thousands- Only changes larger the 1% of assets are shown. </Typography>
				<Tooltip title="Collapse All">
					<Fab style={{
							height: '20px',
							width: '20px',
							minHeight: '20px',
							minWidth: '20px'
						}} color={theme.palette.neutral.main} aria-label="add" onClick={handleCollapseAll}>
						<RemoveIcon />
					</Fab>
				</Tooltip>
			</Box>
			<TreeView
				aria-label="customized"
				expanded={expanded} // Use the expanded state here
                onNodeToggle={(event, nodeIds) => setExpanded(nodeIds)} 
				defaultCollapseIcon={<MinusSquare />}
				defaultExpandIcon={<PlusSquare />}
				defaultEndIcon={<CloseSquare />}
				sx={{ flexGrow: 1, overflowY: "auto" }}
			>
				{Object.keys(BalanceSheet).length
					? Object.entries(BalanceSheet).map(([key, value]) =>
							renderTree(value, key, "0", BalanceSheetTotals)
					)
					: noData("Balance Sheet")}
				{Object.keys(IncomeStatement).length
					? Object.entries(IncomeStatement).map(([key, value]) =>
							renderTree(value, key, "2000", IncomeStatementTotals)
					)
					: noData("Income Statement")}
				{Object.keys(CashFlow).length
					? Object.entries(CashFlow).map(([key, value]) =>
							renderTree(value, key, "4000", CashFlowTotals)
					)
					: noData("Cash Flow")}
			</TreeView>
		</Box>
	);
}
