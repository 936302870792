import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  Collapse,
  CardActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LiquidGraph from "./liquidGraphs";
import RatioCard from "./ratioCard";
export default function SSCard({ratios, accounts, text, expanded, handleToggle, ExpandMore }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));


    const graphConfig = {
      keys:["PYValue", "Value"],
      colorMapping: {
        Value: theme.palette.secondary.main, // Red
        PYValue: theme.palette.neutral.main, // Blue
      },
      format: value => value,
      indexBy:"name"
    }
    const BSGraphConfig = {
      keys :["LastYear", "CurrentValue"],
      colorMapping: {
        CurrentValue: theme.palette.secondary.main, // Red
        LastYear: theme.palette.neutral.main, // Blue
      },
      format: value => `${value / 1000000}m`,
      indexBy:"DisplayName"
    }
    
    const legendItems = [
      { title: "Prior Year", color: theme.palette.neutral.main },
      { title: "Current Year", color: theme.palette.secondary.main },
    ];


    
    const cardData = Object.values(ratios).filter(
      (value) => value !== null && isFinite(value.Value)
    );
    const checkData = Object.values(accounts).filter((value) => value !== null);
    if (checkData.length < 2) {
      return null;
    } else {
      return (
        <Card sx={{ backgroundColor: theme.palette.primary.accent, color: theme.palette.secondary.main }}>
          <CardContent>
            <Typography variant="h5">{text}</Typography>
            {/* ... more code using data object */}
          </CardContent>
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded} onClick={handleToggle}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {/* Additional content here */}

              <Grid container item xs={12} spacing={isSmallScreen ? 0.5 : 2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LiquidGraph data={ratios} graphConfig = {graphConfig} />
                </Grid>
                <Grid container justifyContent="center" spacing={2}>
                  {legendItems.map((item, index) => (
                    <Grid key={index} item>
                      <Box display="flex" alignItems="center">
                        <Box
                          width={20}
                          height={20}
                          bgcolor={item.color}
                          marginRight={1}
                        />
                        <Typography variant="h5" color = {item.color}>{item.title}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LiquidGraph  data={accounts} graphConfig = {BSGraphConfig} />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  justifyContent="center"
                >
                  {cardData.map((data, index) => {
                    // Check if the Value is not empty
                    if (!data.Value || data.Value === "") {
                      // Skip rendering this card
                      return null;
                    }

                    return (
                      <Grid item xs={12} sm={12} md={12} lg={12} key={data.uniqueId || index}>
                        <RatioCard data={data} />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
              </Grid>

              {/* Render other components or additional tables <FSTable data={fsData} /> */}

              {/* Additional content here */}
            </CardContent>
          </Collapse>
        </Card>
      );
    }
}
