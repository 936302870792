import { firebaseClient } from "../firebase/firebaseClient";
import { getFunctions, httpsCallable } from "firebase/functions";

export default async function redirectToCustomerPortal() {
	const firebase = firebaseClient;
	const functions = getFunctions(firebase);
	const createPortalLink = httpsCallable(
		functions,
		"ext-firestore-stripe-payments-createPortalLink"
	);

	createPortalLink({
		returnUrl: window.location.origin,
	}).then(({ data }) => {
		window.open(data.url, "_self");
	});
}
