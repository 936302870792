import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

//color design tokens
export const tokens = (mode) => ({
	...(mode === "dark"
		? {
				grey: {
					100: "#e0e0e0",
					200: "#c2c2c2",
					300: "#a3a3a3",
					400: "#858585",
					500: "#666666",
					600: "#525252",
					700: "#3d3d3d",
					800: "#292929",
					900: "#141414",
				},
				primary: {
					100: "#d6dbe6",
					200: "#adb7cd",
					300: "#8593b4",
					400: "#5c6f9b",
					500: "#334b82",
					600: "#293c68",
					700: "#1f2d4e",
					800: "#141e34",
					900: "#0a0f1a",
				},
				greenAccent: {
					100: "#dbf5ee",
					200: "#b7ebde",
					300: "#94e2cd",
					400: "#70d8bd",
					500: "#4cceac",
					600: "#3da58a",
					700: "#2e7c67",
					800: "#1e5245",
					900: "#0f2922",
				},
				redAccent: {
					100: "#f8dcdb",
					200: "#f1b9b7",
					300: "#e99592",
					400: "#e2726e",
					500: "#db4f4a",
					600: "#af3f3b",
					700: "#832f2c",
					800: "#58201e",
					900: "#2c100f",
				},
				blueAccent: {
					100: "#e1e2fe",
					200: "#c3c6fd",
					300: "#a4a9fc",
					400: "#868dfb",
					500: "#6870fa",
					600: "#535ac8",
					700: "#3e4396",
					800: "#2a2d64",
					900: "#151632",
				},
				gold: {
					100: "#f4ebce",
					200: "#e9d79c",
					300: "#ddc26b",
					400: "#d2ae39",
					500: "#c79a08",
					600: "#9f7b06",
					700: "#775c05",
					800: "#503e03",
					900: "#281f02",
				},
				buy: {
					100: "#ccffcc",
					200: "#99ff99",
					300: "#66ff66",
					400: "#33ff33",
					500: "#00ff00",
					600: "#00cc00",
					700: "#009900",
					800: "#006600",
					900: "#003300",
				},
				sell: {
					100: "#ffcccc",
					200: "#ff9999",
					300: "#ff6666",
					400: "#ff3333",
					500: "#ff0000",
					600: "#cc0000",
					700: "#990000",
					800: "#660000",
					900: "#330000",
				},
				hold: {
					100: "#ffffcc",
					200: "#ffff99",
					300: "#ffff66",
					400: "#ffff33",
					500: "#ffff00",
					600: "#cccc00",
					700: "#999900",
					800: "#666600",
					900: "#333300",
				},
		  }
		: {
				grey: {
					100: "#141414",
					200: "#292929",
					300: "#3d3d3d",
					400: "#525252",
					500: "#666666",
					600: "#858585",
					700: "#a3a3a3",
					800: "#c2c2c2",
					900: "#e0e0e0",
				},

				primary: {
					800: "#d6dbe6",
					700: "#adb7cd",
					600: "#8593b4",
					500: "#5c6f9b",
					400: "#334b82",
					300: "#293c68",
					200: "#1f2d4e",
					100: "#141e34",
				},

				greenAccent: {
					100: "#0f2922",
					200: "#1e5245",
					300: "#2e7c67",
					400: "#3da58a",
					500: "#4cceac",
					600: "#70d8bd",
					700: "#94e2cd",
					800: "#b7ebde",
					900: "#dbf5ee",
				},
				redAccent: {
					100: "#2c100f",
					200: "#58201e",
					300: "#832f2c",
					400: "#af3f3b",
					500: "#db4f4a",
					600: "#e2726e",
					700: "#e99592",
					800: "#f1b9b7",
					900: "#f8dcdb",
				},
				blueAccent: {
					100: "#151632",
					200: "#2a2d64",
					300: "#3e4396",
					400: "#535ac8",
					500: "#6870fa",
					600: "#868dfb",
					700: "#a4a9fc",
					800: "#c3c6fd",
					900: "#e1e2fe",
				},
				gold: {
					100: "#281f02",
					200: "#503e03",
					300: "#775c05",
					400: "#c79a08",
					500: "#9f7b06",
					600: "#d2ae39",
					700: "#ddc26b",
					800: "#e9d79c",
					900: "#f4ebce",
				},
				buy: {
					100: "#003300",
					200: "#006600",
					300: "#009900",
					400: "#00cc00",
					500: "#00ff00",
					600: "#33ff33",
					700: "#66ff66",
					800: "#99ff99",
					900: "#ccffcc",
				},
				sell: {
					100: "#330000",
					200: "#660000",
					300: "#990000",
					400: "#cc0000",
					500: "#ff0000",
					600: "#ff3333",
					700: "#ff6666",
					800: "#ff9999",
					900: "#ffcccc",
				},
				hold: {
					100: "#333300",
					200: "#666600",
					300: "#999900",
					400: "#cccc00",
					500: "#ffff00",
					600: "#ffff33",
					700: "#ffff66",
					800: "#ffff99",
					900: "#ffffcc",
				},
		  }),
});

//mui theme settings
export const themeSettings = (mode) => {
	const colors = tokens(mode);

	return {
		palette: {
			mode: mode,

			primary: {
				main: colors.primary[800],
				accent: colors.primary[700],
			},
			secondary: {
				main: colors.greenAccent[400],
			},
			neutral: {
				dark: colors.grey[900],
				main: colors.grey[300],
				light: colors.grey[100],
			},
			premium: {
				main: colors.gold[400],
			},
			background: {
				default: colors.primary[800],
			},
		},
		typography: {
			fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
			fontSize: 12,
			h1: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 64,
			},
			h2: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 48,
			},
			h3: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 32,
			},
			h4: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 24,
			},
			h5: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 20,
			},
			h6: {
				fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
				fontSize: 16,
			},
		},
	};
};

export const panelSwitchThemeSettings = (mode) => {
	const colors = tokens(mode);

	return {
		palette: {
			mode: mode,

			primary: {
				main: colors.greenAccent[400],
				accent: colors.primary[700],
			},
			secondary: {
				main: colors.greenAccent[400],
			},
			neutral: {
				dark: colors.grey[900],
				main: colors.grey[500],
				light: colors.grey[100],
			},
		},
		typography: {
			fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
			fontSize: 12,
		},
	};
};

export const BuySellMap = {
	Strong: "#388e3c",
	Good: "#81c784",
	Average: "#ffb74d",
	Poor: "#e57373",
	Weak: "#d32f2f",
};

// context for color mode
export const ColorModeContext = createContext({
	toggleColorMode: () => {},
});

export const useMode = () => {
	const [mode, setMode] = useState("dark");

	const colorMode = useMemo(
		() => ({
			toggleColorMode: () =>
				setMode((prevMode) => (prevMode === "dark" ? "light" : "dark")),
		}),
		[]
	);

	const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

	return [theme, colorMode];
};

function hexToRgb(hex) {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
    } else if (hex.length === 7) {
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
    }
    return `${+r}, ${+g}, ${+b}`;
}

export function getColorFromPercentage(percentage) {
  let colorRgb;
  let opacity;

  if (percentage >= 100) {
      colorRgb = hexToRgb(BuySellMap.Strong);
      opacity = 1;
  } else if (percentage <= -100) {
      colorRgb = hexToRgb(BuySellMap.Weak);
      opacity = 1;
  } else {
      // Adjust percentage to avoid undefined logarithm and extreme values
      const adjustedPercentage = Math.abs(percentage) + 1; // adding 1 to avoid log(0)
      // Logarithmic scaling for opacity
      opacity = Math.log(adjustedPercentage) / Math.log(100);
      colorRgb = percentage < 0 ? hexToRgb(BuySellMap.Weak) : hexToRgb(BuySellMap.Strong);
  }

  return `rgba(${colorRgb}, ${opacity})`;
}