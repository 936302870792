import { Box, Typography, useTheme } from "@mui/material";

import BulletBox from "../../components/BulletBox";
import PricingTile from "../../components/PricingTile";
import PricingDisclaimer from "./pricingDisclaimer";

const MobileLayout = () => {
	const theme = useTheme();

	return (
		<Box
			display="flex"
			flexDirection="column"
			flexWrap="wrap"
			justifyContent="center"
			alignItems="center"
			gap="20px"
		>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="stretch"
				flexWrap="wrap"
				p={2}
			>
				<Box
					mx={1}
					minWidth={450}
					maxWidth={450}
					backgroundColor={theme.palette.primary.accent}
					p={2}
				>
					<Box p={2}>
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
						>
							What you get
						</Typography>
					</Box>
					<BulletBox
						title="No Restictions"
						subtitle="Access to Filing classifications just as they're filed with the SEC. The one month delay will be eliminated."
					></BulletBox>
					<BulletBox
						title="Follow Tickers"
						subtitle="Premium Users will be able to access the following page, and easily view favorite tickers."
					></BulletBox>
					<BulletBox
						title="Increased Query Limits"
						subtitle={`The filings view will be able to query ${process.env.REACT_APP_PREMIUM_REPORT_LIMIT} reports at a time, vs the free-tier ${process.env.REACT_APP_FREE_REPORT_LIMIT} report limit.`}
					></BulletBox>
					<BulletBox
						title="Value"
						subtitle="Lock in a $2.99/month rate for the whole year. Market penetration pricing will be temporary."
					></BulletBox>
				</Box>

				<Box
					flexGrow={1}
					minWidth={450}
					maxWidth={450}
					backgroundColor={theme.palette.primary.accent}
					p={2}
				>
					<Box p={2}>
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
						>
							Select a plan
						</Typography>
					</Box>
					<PricingTile
						title="$2.99/month"
						subtitle="$35.88 billed annually"
						type="annual"
					></PricingTile>
					<PricingTile
						title="$4.49/month"
						subtitle="$4.49 billed monthly"
						type="monthly"
					></PricingTile>

					<PricingDisclaimer />
				</Box>
			</Box>
		</Box>
	);
};

export default MobileLayout;
