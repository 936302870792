import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Fragment } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";

import transparent_logo from "../transparent_logo.png";

import { firebaseClient, logAnalyticsEvent } from "../../../firebase/firebaseClient";

import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

const url =
	process.env.REACT_APP_VECTOR_API_URL ?? "https://vector-api.fly.dev/query";

function useVectorApi() {
	const auth = getAuth(firebaseClient);
	const [user] = useAuthState(auth);
	const [query, setQuery] = useState("");
	const [accNo, setAccNo] = useState("");
	const [k, setK] = useState(3);
	const [queryResult, setQueryResult] = useState([{ text: "" }]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const data = {
			queries: [
				{
					query: query,
					top_k: k,
					filter: {
						acc_no: accNo,
					},
				},
			],
		};
		async function makeQuery() {
			try {
				setIsLoading(true);
				const token = user ? await user.getIdToken(true) : null;
				const response = await fetch(url, {
					method: "POST",
					headers: {
						"Access-Control-Allow-Origin": "*",
						"Content-Type": "application/json",
						Authorization: `Bearer ${
							process.env.REACT_APP_DEV
								? process.env.REACT_APP_BEARER_TOKEN
								: token
						}`,
					},
					body: JSON.stringify(data),
				});
				const result = await response.json();
				setQueryResult(result.results[0].results);
			} catch (error) {
				setError(error);
			} finally {
				setIsLoading(false);
			}
		}
		if (query && accNo) {
			makeQuery();
		} else {
			setQueryResult([""]);
		}
	}, [query, accNo, k, user]);

	logAnalyticsEvent("Vector Search used");
	return {
		user,
		query,
		setQuery,
		accNo,
		setAccNo,
		k,
		setK,
		queryResult,
		isLoading,
		error,
	};
}

export default function VectorSearch({ row }) {
	const theme = useTheme();

	const { user, setQuery, setAccNo, k, isLoading, setK, queryResult, error } =
		useVectorApi();

	const handleChange = (event) => {
		setK(event.target.value);
	};

	const [searchBuffer, setSearchBuffer] = useState("");
	return (
		<Box>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box
					display="flex"
					colors={theme.palette.neutral.light}
					backgroundColor={theme.palette.primary.main}
					borderRadius="3px"
					width="100%"
					mr="15px"
				>
					<InputBase
						sx={{ ml: 2, mr: 2, flex: 1 }}
						defaultValue={""}
						placeholder="Search"
						onChange={(event) => {
							setSearchBuffer(event.target.value);
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								event.preventDefault();
								setQuery(event.target.value);
								setAccNo(row["acc-no"]);
							}
						}}
					/>
					<IconButton
						type="button"
						sx={{ p: 1 }}
						onClick={() => {
							setQuery(searchBuffer);
							setAccNo(row["acc-no"]);
						}}
					>
						<SearchIcon />
					</IconButton>
				</Box>
				<Box minWidth={120}>
					<FormControl fullWidth>
						<InputLabel
							id="demo-simple-select-label"
							style={{ color: theme.palette.neutral.light }}
						>
							Show Results
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={k}
							sx={{ color: theme.palette.neutral.light }}
							label="Show Results"
							onChange={handleChange}
						>
							<MenuItem value={1}>One</MenuItem>
							<MenuItem value={3}>Three</MenuItem>
							<MenuItem value={5}>Five</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Box>
			{!error && row.has_index ? (
				isLoading ? (
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						height="30vh"
					>
						<Box textAlign={"center"}>
							<CircularProgress
								style={{
									color: theme.palette.secondary.main,
								}}
								size={65}
							/>
						</Box>
					</Grid>
				) : queryResult.length > 1 ? (
					<List sx={{ width: "100%", bgcolor: theme.palette.primary.accent }}>
						{queryResult.map((sentence, i) => (
							<Fragment key={i}>
								<ListItem display="flex" alignItems="center" p="15px">
									<ListItemText>
										<Typography
											color={theme.palette.neutral.light}
											variant="h6"
											fontWeight="600"
										>
											{sentence.text}
										</Typography>
									</ListItemText>
								</ListItem>
								<Divider component="li" />
							</Fragment>
						))}
					</List>
				) : (
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						height="30vh"
					>
						<Box alignItems={"center"}>
							<img
								src={transparent_logo}
								alt="Transparent Logo"
								style={{ opacity: 0.5 }}
							/>
						</Box>
					</Grid>
				)
			) : (
				<Box mt="15px">
					<Alert severity="error">
						{user
							? "Vector search unavailable on this report"
							: "You need to be logged in to access vector search"}
					</Alert>
				</Box>
			)}
		</Box>
	);
}
