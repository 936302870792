
export default function getEquityValues({BalanceSheetTotals}) {
	const equityAccounts= {
		CommonStock:null,
		WeightedShares:null,
		PrefferredStock: null    
	};
	//  CommonStock
	if (BalanceSheetTotals?.CommonStockSharesOutstanding) {
	  equityAccounts.CommonStock = {
		  LastYear: BalanceSheetTotals.CommonStockSharesOutstanding.LastYear,
		  CurrentValue: BalanceSheetTotals.CommonStockSharesOutstanding.CurrentValue,
		  AbsChange: BalanceSheetTotals.CommonStockSharesOutstanding.AbsChange,
		  PercentChange: BalanceSheetTotals.CommonStockSharesOutstanding.PercentChange,
		  SourceName:"CommonStockSharesOutstanding",
		  DisplayName:'Common Stock Outsanding'
	  };
	} 
	  //WeightedAverageNumberOfDilutedSharesOutstanding
	if (BalanceSheetTotals?.WeightedAverageNumberOfDilutedSharesOutstanding) {
		equityAccounts.WeightedShares = {
			LastYear: BalanceSheetTotals.WeightedAverageNumberOfDilutedSharesOutstanding.LastYear,
			CurrentValue: BalanceSheetTotals.WeightedAverageNumberOfDilutedSharesOutstanding.CurrentValue,
			AbsChange: BalanceSheetTotals.WeightedAverageNumberOfDilutedSharesOutstanding.AbsChange,
			PercentChange: BalanceSheetTotals.WeightedAverageNumberOfDilutedSharesOutstanding.PercentChange,
			SourceName:"WeightedAverageNumberOfDilutedSharesOutstanding",
			DisplayName:'Common Stock Outsanding'
		};
	  }
	//  PrefferredStockValue
	if (BalanceSheetTotals?.PreferredStockSharesOutstanding) {
	  equityAccounts.PrefferredStock = {
		  LastYear: BalanceSheetTotals.PreferredStockSharesOutstanding.LastYear,
		  CurrentValue: BalanceSheetTotals.PreferredStockSharesOutstanding.CurrentValue,
		  AbsChange: BalanceSheetTotals.PreferredStockSharesOutstanding.AbsChange,
		  PercentChange: BalanceSheetTotals.PreferredStockSharesOutstanding.PercentChange,
		  SourceName:"PreferredStockSharesOutstanding",
		  DisplayName:'Value of Preferred Stock'
	  };
	}
	  return equityAccounts;
  }