import { Box, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import { logAnalyticsEvent } from "../../firebase/firebaseClient";

const CheckoutSplash = () => {
	const theme = useTheme();
	const navigate = useNavigate();

	logAnalyticsEvent("viewed checkout splash");

	return (
		<Box>
			<Box
				display="flex"
				flexDirection="column"
				flexWrap="wrap"
				justifyContent="center"
				alignItems="center"
				gap="20px"
				overflow="auto"
				mt="15px"
				pb="100px"
			>
				<Box justifyContent="center" alignItems="center">
					<Box justifyContent="center" textAlign="center">
						<Typography
							variant="h1"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ m: "10px 10px 5px 10px" }}
						>
							Abridged Due Diligence
						</Typography>
					</Box>
				</Box>
				<Box
					mx={1}
					maxWidth={450}
					width="100%"
					justifyContent="center"
					alignItems="center"
					backgroundColor={theme.palette.primary.accent}
					p={2}
					mb="100px"
				>
					<Box display="flex" justifyContent="center">
						<Box>
							<Typography
								variant="h4"
								fontWeight="bold"
								sx={{
									color: theme.palette.neutral.light,
								}}
							>
								Thank you for your purchase!
							</Typography>
						</Box>
					</Box>
					<Box display="flex" justifyContent="center" mt="2px" flexWrap="wrap">
						<Typography
							variant="h5"
							sx={{
								color: theme.palette.neutral.light,
							}}
						>
							Click below to check out the real-time discover page!
						</Typography>
					</Box>
					<Box
						display="block"
						m="30px 30px"
						justifyContent="center"
						alignItems="center"
						align="center"
					>
						<Button
							variant="contained"
							style={{
								color: theme.palette.neutral.dark,
								backgroundColor: theme.palette.secondary.main,
							}}
							onClick={() => navigate("/discover")}
						>
							Discover the Details!
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default CheckoutSplash;
