import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import {styled } from "@mui/system";
import { firebaseClient, logAnalyticsEvent } from "../../firebase/firebaseClient";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import BulletBox from "../../components/BulletBox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";

import DataMineImage from "./img/DataMineWhiteTransparent-01.png";
import CPUImage from "./img/ChipTransparentWhite-01.png";
import ClockImage from "./img/ClockTransparentWhite-01.png";
import LightBulbImage from "./img/LightBulbTransparentWhite-01.png";
import LogoImage from "./img/ADD_Neue_Transparent White.png";

import {
	getAuth,
	isSignInWithEmailLink,
	signInWithEmailLink,
} from "firebase/auth";

logAnalyticsEvent("Landing Page viewed");

const auth = getAuth(firebaseClient);
const HoverColorChangeImage = styled("img")(({ theme }) => ({
	backgroundColor: "transparent",
	transition: "filter 0.3s ease",
	"&:hover": {
		filter: `drop-shadow(0px 0px 12px ${theme.palette.secondary.main})`, // Change 'red' to your desired color
	},
}));

const LogoContainer = ({ isSmallScreen }) => {
	const height = isSmallScreen ? "300px" : "350px";
	return (
		<HoverColorChangeImage
			height={height}
			width="auto"
			src={LogoImage}
			alt="Description"
		/>
	);
};

const Landing = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [loginError, setLoginError] = useState(false);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	if (!loginError && isSignInWithEmailLink(auth, window.location.href)) {
		// Additional state parameters can also be passed via URL.
		// This can be used to continue the user's intended action before triggering
		// the sign-in operation.
		// Get the email if available. This should be available if the user completes
		// the flow on the same device where they started it.
		let email = window.localStorage.getItem("emailForSignIn");
		if (!email) {
			// User opened the link on a different device.
			setLoginError(true);
		} else {
			signInWithEmailLink(auth, email, window.location.href)
				.then(() => {
					// sign in was successful
					window.history.replaceState(null, null, window.location.origin);
					window.localStorage.removeItem("emailForSignIn");
					setLoginError(false);
				})
				.catch(() => {
					setLoginError(true);
				});
		}
	}

	return (
		<Box>
			<Box
			 	sx={{ m: "10px 10px 5px 10px" }}
				display="flex"
				flexDirection="column"
				flexWrap="wrap"
				gap="20px"
				overflow="auto"
				justifyContent="center"
				height="100%"
				width="Auto"
				mt={isSmallScreen ? "20px" : "100px"}
				mb={isSmallScreen ? "10px" : "100px"}
			>
				{loginError ? (
					<Alert severity="error">
						Log in failed. Please ensure you open the login link on the same
						device you used to send it. Email login uses cookies, and may not
						work in incognito modes. It is also possible that the login link was
						stale.
					</Alert>
				) : null}
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					flexDirection={isSmallScreen ? "column" : "row"} // flexDirection changes based on screen width
					ml={isSmallScreen ? "5%" : "12.5%"}
					mr={isSmallScreen ? "5%" : "12.5%"}
					width="Auto"//width={isSmallScreen ? "90%" : "75%"}
				>
					{isSmallScreen ? (
						<LogoContainer isSmallScreen={isSmallScreen} />
					) : null}
					<Box
						display="block"
						mt="50px"
						mb="50px"
						justifyContent="left"
						alignItems="left"
						align="left"
						width="Auto"
						//backgroundColor={theme.palette.primary.accent}
					>
						<Typography
							variant={isSmallScreen ? "h2" : "h1"}
							color={theme.palette.neutral.light}
							fontWeight="bold"
							mb="20px"
						>
							The fastest way to analyze financial reports
						</Typography>
						<Typography
							variant={isSmallScreen ? "h3" : "h2"}
							fontWeight="bold"
							color={theme.palette.secondary.main}
						>
							Use AI to find and understand new businesses and their
							fundamentals
						</Typography>
					</Box>
					{isSmallScreen ? null : (
						<LogoContainer isSmallScreen={isSmallScreen} />
					)}
				</Box>
				<Box
					display="block"
					mt="100px"
					mb="100px"
					justifyContent="center"
					alignItems="center"
					align="center"
				>
					<Button
						variant="contained"
						style={{
							color: theme.palette.neutral.dark,
							backgroundColor: theme.palette.secondary.main,
						}}
						onClick={() => navigate("/discover")}
						sx={{
							fontSize: "22px",
							padding: "20px 40px",
							borderRadius: "12px",
						}}
					>
						Discover the Details!
					</Button>
				</Box>

				<Box sx={{ m: "10px 10px 5px 10px",width : '100%', height:'100%',}}>
					<Box
						display="flex"
						justifyContent="flex-start"
						mt="50px"
						mb="50px"
						ml={isSmallScreen ? "0%" : "16.5%"}
						mr={isSmallScreen ? "0%" : "16.5%"}
					>
						<BulletBox
							title="Data Gold Mine"
							subtitle="Annual Reports are the best way to *learn about* businesses."
							subtitle2="*Thousands of Companies* file Annual and Quarterly Reports."
							subtitle3="These reports can take hours to read *and Analyze* fully"
							subtitle4="We bring the *key facts* and data To you *within Minutes*"
							image={DataMineImage}
							start={true}
						></BulletBox>
					</Box>
					<Box
						display="flex"
						justifyContent="flex-end"
						mt="50px"
						mb="50px"
						ml={isSmallScreen ? "0%" : "16.5%"}
						mr={isSmallScreen ? "0%" : "16.5%"}
					>
						<BulletBox
							title="Pioneering Analysis Technology"
							subtitle="*Access our advanced* neural network."
							subtitle2="Use our *tools* for both text *and* financial figures."
							subtitle3="*Find* Important Changes in essential accounts"
							subtitle4="Read GPT 3.5 generated summaries of *Essentials*."
							subtitle5="Search *within* individual *reports* with vector search"
							image={CPUImage}
							start={false}
						></BulletBox>
					</Box>

					<Box
						display="flex"
						justifyContent="flex-start"
						mt="50px"
						mb="50px"
						ml={isSmallScreen ? "0%" : "16.5%"}
						mr={isSmallScreen ? "0%" : "16.5%"}
					>
						<BulletBox
							title="Work Smarter"
							subtitle="Find opportunities and *ignore* speculation."
							subtitle2="Control for *market reactions* with Machine Learning."
							subtitle3="Get an unbiased view of your portfolio *and* their competitors"
							subtitle4="*Analyze* important *audited* financial *information* fast"
							image={LightBulbImage}
							start={true}
						></BulletBox>
					</Box>

					<Box
						display="flex"
						justifyContent="flex-end"
						mt="50px"
						mb="50px"
						ml={isSmallScreen ? "0%" : "16.5%"}
						mr={isSmallScreen ? "0%" : "16.5%"}
					>
						<BulletBox
							title="Abridged Due Diligence"
							subtitle="*Time-saving analysis of* interesting companies"
							subtitle2="Find *major value metrics* in a sea of padding and jargon"
							subtitle3=" See which companies are trading *at a discount*."
							image={ClockImage}
							start={false}
						></BulletBox>
					</Box>
					<Box
						display="block"
						mt="100px"
						justifyContent="center"
						alignItems="center"
						align="center"
					>
						<Button
							variant="contained"
							style={{
								color: theme.palette.neutral.dark,
								backgroundColor: theme.palette.secondary.main,
							}}
							onClick={() => navigate("/discover")}
							sx={{
								fontSize: "22px",
								padding: "20px 40px",
								borderRadius: "12px",
							}}
						>
							Discover the Details!
						</Button>
					</Box>
					
				</Box>
				<Footer />
			</Box>
			
		</Box>
	);
};

export default Landing;
