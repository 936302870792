import * as React from "react";
import { Box, styled, Button, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ColorModeContext } from "../../theme";
import usePremiumStatus from "../../stripe/usePremiumStatus";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import redirectToCustomerPortal from "../../stripe/redirectToCustomerPortal";

import StarIcon from "@mui/icons-material/Star";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CircularProgress from "@mui/material/CircularProgress";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LogoutIcon from "@mui/icons-material/Logout";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import BugReportIcon from "@mui/icons-material/BugReport";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-end",
}));

const Topbar = ({ auth, signIn, signOut }) => {
	const theme = useTheme();

	const colorMode = useContext(ColorModeContext);
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [isPremium] = usePremiumStatus(auth.currentUser);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				open={open}
				style={{ backgroundColor: theme.palette.primary.accent }}
			>
				<Toolbar
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Box display="flex" justifyContent="center" alignItems="center">
						<IconButton
							color={theme.palette.neutral.light}
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							sx={{ mr: 2, ...(open && { display: "none" }) }}
						>
							<MenuIcon />
						</IconButton>

						<img
							alt="profile-user"
							width="48px"
							height="48px"
							referrerPolicy="no-referrer"
							src={`../../android-chrome-192x192.png`}
							onClick={() => {
								handleDrawerClose();
								navigate("/");
							}}
						/>
					</Box>
					<Box display="flex" edge="end">
						{process.env.REACT_APP_DEV && (
							<Tooltip title="Development Mode Active">
								<IconButton>
									<BugReportIcon
										style={{ color: theme.palette.secondary.main }}
									/>
								</IconButton>
							</Tooltip>
						)}
						{isPremium && (
							<Tooltip title="Premium Account Settings">
								<IconButton
									onClick={() => {
										setLoading(true);
										redirectToCustomerPortal();
									}}
								>
									{loading ? (
										<CircularProgress
											style={{
												color: theme.palette.secondary.main,
											}}
											size={20}
										/>
									) : (
										<StarIcon style={{ color: theme.palette.premium.main }} />
									)}
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title="Toggle Light/Dark Mode">
							<IconButton onClick={colorMode.toggleColorMode}>
								{theme.palette.mode === "dark" ? (
									<DarkModeOutlinedIcon />
								) : (
									<LightModeOutlinedIcon />
								)}
							</IconButton>
						</Tooltip>

						{auth.currentUser ? (
							<Tooltip title="Sign Out">
								<IconButton onClick={() => signOut(auth)}>
									<LogoutIcon />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Sign In">
								<Button
									variant="contained"
									style={{
										color: theme.palette.neutral.dark,
										backgroundColor: theme.palette.secondary.main,
									}}
									onClick={() => {
										handleDrawerClose();
										navigate("/signIn");
									}}
								>
									Sign In
								</Button>
							</Tooltip>
						)}
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
						backgroundColor: theme.palette.primary.accent,
					},
				}}
				style={{}}
				variant="persistent"
				anchor="left"
				open={open}
			>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "ltr" ? (
							<ChevronLeftIcon />
						) : (
							<ChevronRightIcon />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<Box mb="25px" mt="25px">
					<Box display="flex" justifyContent="center" alignItems="center">
						<img
							alt="profile-user"
							width="75px"
							height="75px"
							referrerPolicy="no-referrer"
							src={
								auth.currentUser?.photoURL
									? auth.currentUser.photoURL
									: `../../assets/person-dummy.jpg`
							}
							style={{ cursor: "pointer", borderRadius: "50%" }}
						/>
					</Box>
					<Box textAlign="center">
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ m: "10px 0 0 0" }}
						>
							{auth.currentUser
								? auth.currentUser.displayName
								: "Anonymous User"}
						</Typography>
						<Typography
							variant="h6"
							color={theme.palette.neutral.light}
							sx={{ m: "10px 0 0 0" }}
						>
							{auth.currentUser ? auth.currentUser.email : null}
						</Typography>
						<Typography
							variant="h6"
							color={
								isPremium
									? theme.palette.premium.main
									: theme.palette.neutral.light
							}
							sx={{ m: "10px 0 0 0" }}
						>
							{isPremium ? "Premium Account" : "Free Tier"}
						</Typography>
					</Box>
				</Box>
				<List>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								handleDrawerClose();
								navigate("/");
							}}
						>
							<ListItemIcon>
								<HomeOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Landing Page" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								handleDrawerClose();
								navigate("/discover");
							}}
						>
							<ListItemIcon>
								<TravelExploreIcon />
							</ListItemIcon>
							<ListItemText primary="Discover" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								handleDrawerClose();
								navigate("/following");
							}}
						>
							<ListItemIcon>
								<BookmarkIcon />
							</ListItemIcon>
							<ListItemText primary="Following" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								handleDrawerClose();
								navigate("/pricing");
							}}
						>
							<ListItemIcon>
								<AttachMoneyIcon />
							</ListItemIcon>
							<ListItemText primary="Pricing" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								handleDrawerClose();
								navigate("/faq");
							}}
						>
							<ListItemIcon>
								<HelpOutlineOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="FAQ" />
						</ListItemButton>
					</ListItem>
					{isPremium && (
						<ListItem disablePadding>
							<ListItemButton
								onClick={() => {
									setLoading(true);
									redirectToCustomerPortal();
								}}
							>
								<ListItemIcon>
									{loading ? (
										<CircularProgress
											style={{
												color: theme.palette.secondary.main,
											}}
											size={20}
										/>
									) : (
										<ReceiptOutlinedIcon />
									)}
								</ListItemIcon>
								<ListItemText primary="Manage Subscription" />
							</ListItemButton>
						</ListItem>
					)}
				</List>
			</Drawer>

			<DrawerHeader />
		</Box>
	);
};

export default Topbar;
