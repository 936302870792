import { Box, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

export default function KeySentences({ summary }) {
	const theme = useTheme();
	return (
		<Box>
			<List sx={{ width: "100%", bgcolor: theme.palette.primary.accent }}>
				{summary.key_sentences.map((sentence, i) => (
					<Fragment key={i}>
						<ListItem display="flex" alignItems="center" p="15px">
							<ListItemText>
								<Typography
									color={theme.palette.neutral.light}
									variant="h5"
									fontWeight="600"
								>
									{i + 1 + ") " + sentence}
								</Typography>
							</ListItemText>
						</ListItem>
						<Divider component="li" />
					</Fragment>
				))}
			</List>
		</Box>
	);
}
