import { Typography, Box, useTheme } from "@mui/material";
export default function PricingDisclaimer() {
	const theme = useTheme();
	return (
		<Box p={2}>
			<Typography color={theme.palette.neutral.light}>
				Both premium plan options provide access to the same features previously
				listed, with the only differences being the term and price. Checkout
				flow is handled by Stripe. Abridgeddd.com does not store any personal
				information. All sales are final.
			</Typography>
		</Box>
	);
}
