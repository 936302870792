import { useState, useEffect } from "react";
import isUserPremium from "./isUserPremium";

export default function usePremiumStatus(user) {
	const [ispremium, setIsPremium] = useState(false);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const checkPremiumStatus = async function () {
			setIsPremium(await isUserPremium());
		};
		checkPremiumStatus().then(() => setLoading(false));
	}, [user]);

	return [ispremium, loading];
}
