import {
  useTheme,
  Grid,
  Box,
  Typography
} from "@mui/material";
import getBSValues from "./bsalternates";
import getISValues from "./isalternates";
import getCFValues from "./cfalternates";
import getEquityValues from "./equityalternates";
import getRatios from "./ratioAlternates";
import BSGraph from "./bsGraphs";
import GlanceCard from "./glanceCard";
import noData from "../../../components/NoData";

export default function DataGlance({ data, priceF }) {
  const theme = useTheme();

  const BalanceSheetTotals = JSON.parse(data["BalanceSheetTotals"]);
  const IncomeStatementTotals = JSON.parse(data["IncomeStatementTotals"]);
  const CashFlowTotals = JSON.parse(data["CashFlowTotals"]);

  if (Object.keys(CashFlowTotals).length < 1){
    return noData("SnapShot");
  }

  const bsAccounts = getBSValues({ BalanceSheetTotals });
  const isAccounts = getISValues({ IncomeStatementTotals });
  const cfAccounts = getCFValues({ CashFlowTotals });
  const equityAccounts = getEquityValues({ BalanceSheetTotals })

  const cashAccounts = {
    CFOperating: cfAccounts["CFOperating"],
    CFInvesting: cfAccounts["CFInvesting"],
    CFFinancing: cfAccounts["CFFinancing"],
    TotalCFChange: cfAccounts["TotalCFChange"],
  };
  function customSort(a, b) {
    const aValue = isNaN(a.perChange) ? 0 : Math.abs(a.perChange);
    const bValue = isNaN(b.perChange) ? 0 : Math.abs(b.perChange);

    return bValue - aValue;
  }

  const ratios = getRatios(bsAccounts, isAccounts, cfAccounts, priceF, equityAccounts);
  const ratioData = Object.values(ratios).filter(
    (value) => value !== null && isFinite(value.Value) && value.Value !== 0
  );
  const ratioSort = ratioData;
  ratioSort.sort(customSort);
  const cardData = ratioSort.slice(0, 6);

  const legendItems = [
    { title: "Prior Year", color: theme.palette.neutral.main },
    { title: "Current Year", color: theme.palette.secondary.main },
  ];

  

  return (

    <Grid container p={2} spacing={2} alignItems="center">
      {cardData.map((data, index) => {
        // Check if the Value is not empty
        if (!data.Value || data.Value === "") {
          // Skip rendering this card
          return null;
        }

        return (
          <Grid item xs={12} sm={6} md={4} lg={4} key={data.uniqueId || index}>
            <GlanceCard data={data} />
          </Grid>
        );
      })}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography style={{ textAlign: 'center' }} variant="h4">Cash Flows by Category (USD)</Typography>
        <BSGraph data={cashAccounts} />
        <Grid container justifyContent="center" spacing={2}>
          {legendItems.map((item, index) => (
            <Grid key={index} item>
              <Box display="flex" alignItems="center">
                <Box
                  width={20}
                  height={20}
                  bgcolor={item.color}
                  marginRight={1}
                />
                <Typography variant="h5" color={item.color}>{item.title}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>

  );
}
