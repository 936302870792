import { Box, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import DesktopLayout from "./desktopLayout";
import MobileLayout from "./mobileLayout";
import { logAnalyticsEvent } from "../../firebase/firebaseClient";

const Pricing = () => {
	const theme = useTheme();
	const [isMobile, setIsMobile] = useState(false);
	logAnalyticsEvent("Pricing Page viewed");

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 768);
		handleResize(); // Check initial size
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Box
			display="flex"
			flexDirection="column"
			flexWrap="wrap"
			justifyContent="center"
			alignItems="center"
			gap="20px"
		>
			<Box justifyContent="center" alignItems="center" p={2}>
				<Box justifyContent="center" alignItems="center" textAlign={"center"}>
					<Typography
						variant="h2"
						color={theme.palette.neutral.light}
						fontWeight="bold"
						sx={{ m: "10px 10px 5px 10px" }}
					>
						Unlimited financial report analysis, just as they're filed with the
						SEC
					</Typography>
					<Typography
						variant="h4"
						sx={{ m: "10px 10px 5px 10px" }}
						color={theme.palette.secondary.main}
					>
						Early adopter pricing, subject to change.
					</Typography>
				</Box>
			</Box>
			{isMobile ? <MobileLayout /> : <DesktopLayout />}
		</Box>
	);
};

export default Pricing;
