import CatCard from './catCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

function getSentences(category, key_sentences){
  const categorySentenceIds = category.sentences.map((sen) => sen.id)

  const indexHash = {};

  categorySentenceIds.forEach((id) => {
    try{
      indexHash[id] = key_sentences[id -1];
    }
    catch{
      //do nothing
    }
  });

  return indexHash;
}

export default function Analysis({ summary }) {

  const analysis = summary["analysis"];
  const key_sentences = summary["key_sentences"];
	return (
		<Container maxWidth ="xl">
      <Grid container spacing={2} justifyContent="center">
      {analysis.categories.map((data) => {
       

      return (

        <Grid item xs={12} sm={12} md={12} lg={12} key={data.id}>
          <CatCard data={data} key_sentences = {getSentences(data, key_sentences)}/>
        </Grid>

      );
    })}
    </Grid>
    </Container>

    );
}