import { firebaseClient } from "../firebase/firebaseClient";
import { getAuth } from "firebase/auth";

export default async function isUserPremium() {
	const user = getAuth(firebaseClient).currentUser;
	if (!user) return false;

	await user.getIdToken(true);
	const decodedToken = await user.getIdTokenResult();

	return decodedToken?.claims?.stripeRole ? true : false;
}
