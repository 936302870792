import { Box, useTheme } from "@mui/material";
import VectorSearch from "./vectorSearch";
import Summary from "./summary";
import KeySentences from "./keySentences";
import Analysis from "./analysis";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { panelSwitchThemeSettings } from "../../../theme";
import { useState } from "react";

export default function TextualContainer({ row, summary }) {
	const theme = useTheme();
	const panelSwitchTheme = createTheme(
		panelSwitchThemeSettings(theme.palette.mode)
	);
	const [index, setIndex] = useState("Summary");

	return (
		<Box
			gridColumn={{
				xs: "span 10",
				sm: "span 10",
				md: "span 10",
				lg: "span 10",
				xl: "span 10",
			}}
			height="660px"
			backgroundColor={theme.palette.primary.accent}
			overflow="auto"
			p={2}
		>
			<ThemeProvider theme={panelSwitchTheme}>
				<Box p={2}>
					<ToggleButtonGroup
						color="primary"
						value={index}
						exclusive
						onChange={(event, newIndex) => {
							if (newIndex) {
								setIndex(newIndex);
							} else {
								setIndex("Summary");
							}
						}}
						aria-label="Platform"
					>
						<ToggleButton value="Summary">Summary</ToggleButton>
						<ToggleButton value="Key">Key Sentences</ToggleButton>
						<ToggleButton disabled = {! ("analysis" in summary)} value="Analysis">Analysis</ToggleButton>
						<ToggleButton value="Vector">Vector Search</ToggleButton>
						
					</ToggleButtonGroup>
				</Box>
			</ThemeProvider>

			{/* ROW 3 */}
			{index === "Summary" && <Summary summary={summary} />}
			{index === "Key" && <KeySentences summary={summary} />}
			{index === "Vector" && <VectorSearch row={row} />}
			{index === "Analysis" && <Analysis summary={summary} />}
		</Box>
	);
}
