
import {useTheme } from "@mui/material";
import { useState } from "react";

function useColorChange() {
const theme = useTheme();
  const [textColor, setTextColor] = useState(theme.palette.neutral.light); // Initial text color

  const handleButtonClick = () => {
    // Change the text color when the button is clicked
    setTextColor(theme.palette.secondary.main); // Change to the desired color
  };

  const handleButtonUnClick = () => {
    // Change the text color when the button is clicked
    setTextColor(theme.palette.neutral.light); // Change to the desired color
  };

  return {
    textColor,
    handleButtonClick,
	handleButtonUnClick
  };
}

export default useColorChange;
