import * as React from "react";
import { Card, CardContent, Typography} from '@mui/material';
import { useTheme } from "@emotion/react";

export default function KeyCard({ sentence, text }) {
  const theme = useTheme();


  return (
    <Card sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.neutral.light }}>
      <CardContent>
        <Typography variant="h5" >
          {sentence.id + ") " + text}
        </Typography>
      </CardContent>
    </Card>
  );
}