import { Typography, Button, Box, IconButton, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import Tooltip from "@mui/material/Tooltip";
import { useFollowing } from "../firebase/useFollowing";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import { firebaseClient } from "../firebase/firebaseClient";

const BuySellHeader = ({ isPremium, user, row }) => {
	const theme = useTheme();

	const db = getFirestore(firebaseClient);

	const { userFollowing } = useFollowing(user);

	function isUserFollowing() {
		return userFollowing.includes(row.ticker);
	}

	async function toggleFollow() {
		const userRef = user && doc(db, "users", user.uid);
		//premium user only
		if (isPremium && user && userFollowing) {
			//check that user isn't already following
			if (!isUserFollowing()) {
				//if not following, then follow
				await updateDoc(userRef, {
					following: userFollowing.concat(row.ticker),
				});
			} else {
				//if following, then unfollow
				await updateDoc(userRef, {
					following: userFollowing.filter((ticker) => ticker !== row.ticker),
				});
			}
		} else {
			return;
		}
	}

	return (
		<Box display="flex" justifyContent="space-between"  sx={{pt: 2, // padding top
        pr: 2, // padding right
        pb: 0, // padding bottom (set to 0 or whatever you need)
        pl: 2, // padding left
	}}>
			<Box mb="30px">
				<Typography
					variant="h2"
					color={theme.palette.neutral.light}
					fontWeight="bold"
					sx={{ m: "10px 10px 5px 10px" }}
				>
					{row.title}
				</Typography>

				<Typography
					variant="h3"
					sx={{ m: "10px 10px 5px 10px" }}
					color={theme.palette.secondary.main}
				>
					{"$" + row.ticker}
				</Typography>
				<Box display="flex" justifyContent="left">
					<Tooltip
						title={
							isPremium && user
								? !isUserFollowing()
									? "Follow"
									: "Unfollow"
								: "Must be a signed in premium user to follow"
						}
					>
						<IconButton
							onClick={() => {
								toggleFollow();
							}}
						>
							{!isUserFollowing() ? <AddCircleIcon /> : <RemoveCircleIcon />}
						</IconButton>
					</Tooltip>
					<Typography
						lineHeight={1}
						variant="h5"
						sx={{ m: "10px 10px 5px 10px" }}
						color={theme.palette.neutral.light}
					>
						{!isUserFollowing()
							? "Follow " + row.ticker
							: "Following " + row.ticker}
					</Typography>
				</Box>
			</Box>
			<Box
				p="15px"
				display="flex "
				flexDirection={"column"}
				spacing={15}
				justifyContent="space-between"
			>
				<Button
					sx={{
						backgroundColor: theme.palette.primary.accent,
						color: theme.palette.neutral.light,
						fontSize: "14px",
						fontWeight: "bold",
						padding: "10px 10px",
						"&:hover": {
							backgroundColor: theme.palette.secondary.main,
							color: theme.palette.neutral.dark,
						},
					}}
					component={Link}
					to={row.url}
					target="_blank"
					rel="noopener noreferrer"
				>
					SEC Filing
					<LaunchIcon />
				</Button>
			</Box>
		</Box>
	);
};

export default BuySellHeader;
