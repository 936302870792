import { initializeApp } from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = process.env.REACT_APP_DEV
	? {
			apiKey: "AIzaSyAFbHsAQdlU4FqF8GmbmpWyJoedkzgFRxc",
			authDomain: "abridgeddd-dev-c65fa.firebaseapp.com",
			projectId: "abridgeddd-dev-c65fa",
			storageBucket: "abridgeddd-dev-c65fa.appspot.com",
			messagingSenderId: "31074086927",
			appId: "1:31074086927:web:69c7455b24452f65f2737a",
			measurementId: "G-QQ6RKG6B53",
	  }
	: {
			apiKey: "AIzaSyAmCny9-Gv0ObjM-Kll-otQA2vhisBlzXw",
			authDomain: "abridgeddd.com",
			projectId: "financial-analysis-482db",
			storageBucket: "financial-analysis-482db.appspot.com",
			messagingSenderId: "770424873900",
			appId: "1:770424873900:web:217dc079e7b752bc0bd009",
			measurementId: "G-3PBYCFH30J",
	  };

export const firebaseClient = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseClient);
export function logAnalyticsEvent(message, optionalDetails = null){
	logEvent(analytics, message, optionalDetails);
};

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAmCny9-Gv0ObjM-Kll-otQA2vhisBlzXw",
//   authDomain: "financial-analysis-482db.firebaseapp.com",
//   projectId: "financial-analysis-482db",
//   storageBucket: "financial-analysis-482db.appspot.com",
//   messagingSenderId: "770424873900",
//   appId: "1:770424873900:web:91a40a62c11b96dd0bd009",
//   measurementId: "G-FDZ8ZQ4TBJ"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
