import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridToolbarExport,
} from "@mui/x-data-grid-pro";

const CustomGridToolbar = ({ isPremium }) => {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			{isPremium && <GridToolbarExport />}
		</GridToolbarContainer>
	);
};

export default CustomGridToolbar;
