import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { BuySellMap } from "../theme";

const BarChart = ({ row }) => {
	const theme = useTheme();

	const data = [
		{
			class: "Strong",
			score: row.strong_buy_score,
			StrongColor: BuySellMap["Strong"],
		},
		{
			class: "Good",
			score: row.buy_score,
			GoodColor: BuySellMap["Good"],
		},
		{
			class: "Average",
			score: row.hold_score,
			AverageColor: BuySellMap["Average"],
		},
		{ class: "Poor", score: row.sell_score, PoorColor: BuySellMap["Poor"] },
		{
			class: "Weak",
			score: row.strong_sell_score,
			WeakColor: BuySellMap["Weak"],
		},
	];

	return (
		<ResponsiveBar
			data={data}
			theme={{
				// added
				axis: {
					domain: {
						line: {
							stroke: theme.palette.neutral.light,
						},
					},
					legend: {
						text: {
							fill: theme.palette.neutral.light,
						},
					},
					ticks: {
						line: {
							stroke: theme.palette.neutral.light,
							strokeWidth: 1,
						},
						text: {
							fill: theme.palette.neutral.light,
						},
					},
				},
				grid: {
					line: {
						stroke: theme.palette.neutral.light,
					},
				},
				legends: {
					text: {
						fill: theme.palette.neutral.light,
					},
				},
			}}
			keys={["score"]}
			margin={{ top: 35, right: 15, bottom: 35, left: 45 }}
			padding={0.3}
			indexBy="class"
			valueScale={{ type: "linear" }}
			indexScale={{ type: "band", round: true }}
			colors={({ data }) => String(data[`${data["class"]}Color`])}
			borderColor={{
				from: "color",
				modifiers: [["darker", "1.6"]],
			}}
			isInteractive={false}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			enableLabel={false}
			role="application"
		/>
	);
};

export default BarChart;
