import { useTheme } from "@mui/material";

import { ResponsiveBar } from '@nivo/bar';
export default function BSGraph({ data }) {
	const graphData = Object.values(data).filter(value => value !== null);
	const theme = useTheme();
	const formatTickValue = value => `${value / 1000000}m`;
	const colorMapping = {
		CurrentValue: theme.palette.secondary.main, // Red
		LastYear: theme.palette.neutral.main, // Blue
	};

	if (graphData.length < 2) {
		return null;
	} else {
	return (
		<div style={{ height: 400 }}>
			<ResponsiveBar
				data={graphData}
				theme={{
					// added
					axis: {
						domain: {
							line: {
								stroke: theme.palette.neutral.light,
							},
						},
						legend: {
							text: {
								fill: theme.palette.neutral.light,
							},
						},
						ticks: {
							line: {
								stroke: theme.palette.neutral.light,
								strokeWidth: 1,
							},
							text: {
								fill: theme.palette.neutral.light,
							},
						},
					},
					grid: {
						line: {
							stroke: theme.palette.neutral.light,
						},
					},
					legends: {
						text: {
							fill: theme.palette.neutral.light,
						},
					},
				}}
				keys={["LastYear", "CurrentValue"]}
				margin={{ top: 35, right: 15, bottom: 60, left: 45 }}
				padding={0.3}
				indexBy="DisplayName"
				groupMode="grouped"
				valueScale={{ type: "linear" }}
				indexScale={{ type: "band", round: true }}
				colors={bar => {
					return colorMapping[bar.id];
				}}
				borderColor={{
					from: "color",
					modifiers: [["darker", "1.6"]],
				}}
				isInteractive={false}
				axisTop={null}
				axisRight={null}
				axisBottom={
					{
						tickPadding: 5,
						tickRotation: 0,
						tickSize: 2
					}
				}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					format: formatTickValue,
				}}
				enableLabel={false}
				role="application"

			/>
		</div>
	);
}}