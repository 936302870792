import { Box, Typography } from "@mui/material";

import ProgressCircle from "./ProgressCircle";

const StatBox = ({
	title,
	icon,
	progress,
	increase,

	backgroundColor,
}) => {
	return (
		<Box
			display="flex"
			flexWrap="wrap"
			justifyContent="space-between"
			alignItems="center"
			gridColumn={{
				xs: "span 10",
				sm: "span 10",
				md: "span 2",
				lg: "span 2",
				xl: "span 2",
			}}
			backgroundColor={backgroundColor}
		>
			<Box width="50%" m="10px">
				{icon}
				<Typography variant="h4" fontWeight="bold" sx={{ color: "#141414" }}>
					{title}
				</Typography>
			</Box>
			<Box gridColumn="span 1" m="10px">
				<ProgressCircle backgroundColor={backgroundColor} progress={progress} />
				<Typography variant="h5" fontStyle="italic" sx={{ color: "#141414" }}>
					{increase}
				</Typography>
			</Box>
		</Box>
	);
};

export default StatBox;
