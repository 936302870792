import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { panelSwitchThemeSettings } from "../../theme";
import { useAccessLevel } from "../../firebase/useAccessLevel";
import { useAuthState } from "react-firebase-hooks/auth";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { getAuth } from "firebase/auth";
import { firebaseClient, logAnalyticsEvent } from "../../firebase/firebaseClient";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { doc } from "firebase/firestore";

import { getFirestore } from "firebase/firestore";

import BuySellHeader from "../../components/BuySellHeader";
import BarChart from "../../components/BarChart";

import Error from "../error";
import BuySellCards from "./BuySellCards";
import TitleCard from "./TitleCard";

import TextualContainer from "./textualContainer";
import FinancialContainer from "./financialContainer";

import CircularProgress from "@mui/material/CircularProgress";

const hasFinancials = (obj) => {
    return obj && typeof obj.has_financials === 'boolean' ? obj.has_financials : false;
  };


function Details() {
	const theme = useTheme();

	const { id } = useParams();
	const db = getFirestore(firebaseClient);
	const [summaryValue, summaryLoading, summaryError] = useDocumentOnce(
		doc(db, "summaries", id)
	);
	const [reportsValue, reportLoading, reportError] = useDocumentOnce(
		doc(db, "reports", id)
	);

	return (

		<Box>
			{(summaryError || reportError) && <Error />}
			{(summaryLoading || reportLoading) && (
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					height="100vh"
				>
					<Box textAlign={"center"}>
						<CircularProgress
							style={{
								color: theme.palette.secondary.main,
							}}
						/>
					</Box>
				</Grid>
			)}
			{summaryValue && reportsValue && (
				<DetailsBody
					theme={theme}
					row={reportsValue.data() ?? null}
					summary={summaryValue.data() ?? null} />
			)}
		</Box>
	);
}

function DetailsBody({ theme, row, summary }) {
	const panelSwitchTheme = createTheme(
		panelSwitchThemeSettings(theme.palette.mode)
	);
	const [index, setIndex] = useState("TextView");

	const auth = getAuth(firebaseClient);
	const [user] = useAuthState(auth);
	const { maxDate, loading, isPremium } = useAccessLevel(user);

	const cutoff = maxDate.toDate();
	const date_filed = row.date_filed.toDate();

	if (!row || !summary || (!loading && date_filed > cutoff)) {
		return <Error />;
	}

	logAnalyticsEvent("Details Page viewed", row);

	return (
		<Box>
			<Helmet>
				<title>{row.title}</title>
				<meta
					name="description"
					content={
						summary.summary
							? summary.summary[0]
							: ""
					}
				/>
			</Helmet>

			<ThemeProvider theme={panelSwitchTheme}>
				<Box p={2}>
					<ToggleButtonGroup
						color="primary"
						value={index}
						exclusive
						onChange={(event, newIndex) => {
							setIndex(newIndex);
						}}
						aria-label="Platform"
					>
						<ToggleButton value="TextView">Text Tools</ToggleButton>
						<ToggleButton disabled = {! hasFinancials(row)} value="FinancialsView">Financial Tools</ToggleButton>
					</ToggleButtonGroup>
				</Box>
			</ThemeProvider>



			<Box p={2}>
				{/* GRID & CHARTS */}
				<Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap="20px">
					<Box
						gridColumn={{
							xs: "span 10",
							sm: "span 10",
							md: "span 10",
							lg: "span 4",
							xl: "span 4",
						}}
						gridRow="span 1"
						backgroundColor={theme.palette.primary.accent}
						overflow="auto"
					>
						<BuySellHeader isPremium={isPremium} user={user} row={row} />
						<TitleCard row={row} />
					</Box>
					{index === "TextView" && <TextView
						row={row}
						summary={summary}
						theme={theme}
						isPremium={isPremium}
						user={user}
					/>}
					{index === "FinancialsView" && <FinancialContainer row={row} />}

				</Box>
			</Box>
		</Box>);
}

function TextView({ row, summary, theme}) {


	return (
		<>
			<Box
				display="grid"
				gridTemplateColumns="repeat(10, 1fr)"
				gridRow="span 1"
				gridColumn={{
					xs: "span 10",
					sm: "span 10",
					md: "span 10",
					lg: "span 6",
					xl: "span 6",
				}}
				gap="20px"
			>
				<BuySellCards row={row} />

				{/* ROW 2 */}
				<Box
					gridColumn="span 10"
					gridRow="span 2"
					backgroundColor={theme.palette.primary.accent}
				>
					<Typography
						variant="h5"
						fontWeight="600"
						sx={{ padding: "30px 30px 0 30px" }}
					>
						Confidence by Group
					</Typography>
					<Box height="250px" >
						<BarChart row={row} isDashboard={false} />
					</Box>
				</Box>
			</Box>

			<TextualContainer row={row} summary={summary} />

		</>

	);
}

export default Details;
