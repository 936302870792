import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export const placeholderRow = {
	title: "Loading...",
	ticker: "Loading...",
	url: "Loading...",
	strong_buy_score: 0.0,
	buy_score: 0.0,
	hold_score: 0.0,
	sell_score: 0.0,
	key_sentences: ["Loading..."],
	price: { price: 0.0, date: "Loading..." },
	strong_sell_score: 0.0,
	date_filed: "Loading...",
	"acc-no": "Loading...",
	cik: "Loading...",
};
const scoremap = ["Strong", "Good", "Average", "Poor", "Weak"];
const labelComparator = (v1, v2) => scoremap.indexOf(v1) - scoremap.indexOf(v2);
function urlCellRenderer(params) {
	const url = params.value;
	return (
		<Button component={Link} to={url} target="_blank" rel="noopener noreferrer">
			<LaunchIcon sx={{ color: "#ffffff", mr: "10px" }} />
		</Button>
	);
}

export const mockDataReportsColumns = [
	{
		field: "acc-no",
		headerName: "Accession No.",
		filterable: false,
	},
	{
		field: "date_filed",
		type: "dateTime",
		valueGetter: ({ value }) => value && value.toDate(),
		headerName: "Filing Date",
	},
	{
		field: "ticker",
		headerName: "Ticker",
		flex: 0.5,
	},
	{
		field: "title",
		headerName: "Company Name",
		width: 200,
	},
	{
		field: "industry",
		headerName: "Industry",
		width: 150,
	},
	{
		field: "cik",
		headerName: "CIK",
		headerAlign: "left",
		align: "left",
	},
	{
		field: "type",
		headerName: "Form Type",
		flex: 1,
	},
	{
		field: "price",
		headerName: "Price At Filing",
		type: "number",
		valueGetter: ({ value }) => Number(value["price"].toPrecision(6)),
		flex: 1,
	},
	{
		field: "top_rating",
		headerName: "Top Rating",
		sortComparator: labelComparator,
	},
	{
		field: "strong_buy_score",
		headerName: "Strong",
		type: "number",
		valueGetter: ({ value }) => (value * 100).toPrecision(4),
	},
	{
		field: "buy_score",
		headerName: "Good",
		type: "number",
		valueGetter: ({ value }) => (value * 100).toPrecision(4),
	},
	{
		field: "hold_score",
		headerName: "Average",
		type: "number",
		valueGetter: ({ value }) => (value * 100).toPrecision(4),
	},
	{
		field: "sell_score",
		headerName: "Poor",
		type: "number",
		valueGetter: ({ value }) => (value * 100).toPrecision(4),
	},
	{
		field: "strong_sell_score",
		headerName: "Weak",
		type: "number",
		valueGetter: ({ value }) => (value * 100).toPrecision(4),
	},
	{
		field: "url",
		headerName: "Link to SEC filing",
		renderCell: urlCellRenderer,
		sortable: false,
	},
];

export const line_item_order = [
	"IncomeStatementAbstract",
	"StatementTable",
	"StatementLineItems",
	"NetIncomeLossAvailableToCommonStockholdersBasicAbstract",
	"NetIncomeLossAbstract",
	"NetIncomeLossAttributableToReportingEntityAbstract",
	"IncomeLossFromContinuingOperationsAbstract",
	"IncomeLossFromContinuingOperationsBeforeIncomeTaxesExtraordinaryItemsNoncontrollingInterestAbstract",
	"IncomeLossFromContinuingOperationsBeforeIncomeTaxesMinorityInterestAndIncomeLossFromEquityMethodInvestmentsAbstract",
	"OperatingIncomeLossAbstract",
	"RevenuesAbstract",
	"RevenueFromContractWithCustomerIncludingAssessedTax",
	"SubleaseIncome",
	"PremiumsEarnedNet",
	"Revenues",
	"CostsAndExpensesAbstract",
	"CostOfRevenueAbstract",
	"CostOfGoodsAndServicesSoldAbstract",
	"CostOfGoodsAndServiceExcludingDepreciationDepletionAndAmortizationAbstract",
	"CostDirectMaterial",
	"CostDirectLabor",
	"CostOfGoodsAndServicesSoldOverhead",
	"CostMaintenance",
	"DirectTaxesAndLicensesCosts",
	"CostOfTrustAssetsSoldToPayExpenses",
	"CostOfPropertyRepairsAndMaintenance",
	"CostOfOtherPropertyOperatingExpense",
	"DirectOperatingCostsAbstract",
	"WaterProductionCosts",
	"CostOfPurchasedWater",
	"RecoveryOfDirectCosts",
	"ManufacturingCosts",
	"DirectOperatingMaintenanceSuppliesCosts",
	"OperatingInsuranceAndClaimsCostsProduction",
	"FuelCosts",
	"DirectCommunicationsAndUtilitiesCostsAbstract",
	"DirectOperatingCommunicationsCosts",
	"DirectCommunicationsAndUtilitiesCosts",
	"AircraftRentalAndLandingFees",
	"DirectOperatingCosts",
	"LossOnContracts",
	"AffiliateCosts",
	"InventoryFirmPurchaseCommitmentLoss",
	"ExciseAndSalesTaxes",
	"ProductionRelatedImpairmentsOrChargesAbstract",
	"InventoryWriteDown",
	"ProductionRelatedImpairmentsOrCharges",
	"CostOfGoodsAndServiceExcludingDepreciationDepletionAndAmortization",
	"CostDepreciationAmortizationAndDepletionAbstract",
	"CostOfGoodsAndServicesSoldDepreciationAndAmortizationAbstract",
	"CostOfGoodsAndServicesSoldDepreciation",
	"CostOfGoodsAndServicesSoldAmortization",
	"CostOfGoodsAndServicesSoldDepreciationAndAmortization",
	"CostDepletion",
	"CostDepreciationAmortizationAndDepletion",
	"CostOfGoodsAndServicesSold",
	"FinancialServicesCostsAbstract",
	"FinancingInterestExpense",
	"PolicyholderBenefitsAndClaimsIncurredNet",
	"MerchantMarineOperatingDifferentialSubsidy",
	"OtherCostOfOperatingRevenue",
	"CostOfRevenue",
	"OperatingExpensesAbstract",
	"OperatingCostsAndExpensesAbstract",
	"ResearchAndDevelopmentExpenseAbstract",
	"ResearchAndDevelopmentExpenseExcludingAcquiredInProcessCost",
	"ResearchAndDevelopmentExpenseSoftwareExcludingAcquiredInProcessCost",
	"ResearchAndDevelopmentInProcess",
	"ResearchAndDevelopmentAssetAcquiredOtherThanThroughBusinessCombinationWrittenOff",
	"ResearchAndDevelopmentExpense",
	"DepreciationDepletionAndAmortizationNonproductionAbstract",
	"DepreciationNonproduction",
	"AmortizationOfDeferredChargesAbstract",
	"AmortizationOfAcquisitionCosts",
	"AmortizationOfIntangibleAssets",
	"AmortizationOfDeferredSalesCommissions",
	"AmortizationOfRegulatoryAsset",
	"FinanceLeaseRightOfUseAssetAmortization",
	"AmortizationOfPowerContractsEmissionCredits",
	"AmortizationOfNuclearFuelLease",
	"AmortizationOfAdvanceRoyalty",
	"AmortizationOfDeferredPropertyTaxes",
	"AmortizationOfRateDeferral",
	"AmortizationOfDeferredHedgeGains",
	"OtherAmortizationOfDeferredCharges",
	"AmortizationAndDepreciationOfDecontaminatingAndDecommissioningAssets",
	"AmortizationOfDeferredCharges",
	"OtherDepreciationAndAmortization",
	"DepletionOfOilAndGasProperties",
	"DepreciationAndAmortization",
	"ExplorationExpense",
	"RecapitalizationCosts",
	"CarryingCostsPropertyAndExplorationRights",
	"RestructuringSettlementAndImpairmentProvisionsAbstract",
	"RestructuringChargesAbstract",
	"BusinessExitCosts1",
	"SeveranceCosts1",
	"OtherRestructuringCosts",
	"RestructuringCharges",
	"BusinessCombinationAcquisitionRelatedCosts",
	"DefinedBenefitPlanRecognizedNetGainLossDueToSettlements1",
	"InventoryRecallExpense",
	"GainOnBusinessInterruptionInsuranceRecovery",
	"EnvironmentalRemediationExpense",
	"AssetImpairmentChargesAbstract",
	"ImpairmentOfLongLivedAssetsToBeDisposedOf",
	"ImpairmentOfLongLivedAssetsHeldForUse",
	"ImpairmentOfIntangibleAssetsIndefinitelivedExcludingGoodwill",
	"GoodwillImpairmentLoss",
	"FinanceLeaseImpairmentLoss",
	"OperatingLeaseImpairmentLoss",
	"ImpairmentOfRealEstate",
	"ImpairmentOfOngoingProject",
	"ImpairmentOfLeasehold",
	"ImpairmentOfIntangibleAssetsFinitelived",
	"ExplorationAbandonmentAndImpairmentExpense",
	"ImpairmentOfOilAndGasProperties",
	"ImpairmentLossesRelatedToRealEstatePartnerships",
	"DisposalGroupNotDiscontinuedOperationLossGainOnWriteDown",
	"OtherAssetImpairmentCharges",
	"AssetImpairmentCharges",
	"OtherNonrecurringIncomeExpenseAbstract",
	"OtherNonrecurringIncome",
	"OtherNonrecurringExpense",
	"OtherNonrecurringIncomeExpense",
	"GainLossRelatedToLitigationSettlement",
	"RestructuringSettlementAndImpairmentProvisions",
	"AccretionExpenseIncludingAssetRetirementObligationsAbstract",
	"AssetRetirementObligationAccretionExpense",
	"AccretionExpense",
	"AccretionExpenseIncludingAssetRetirementObligations",
	"BusinessCombinationIntegrationRelatedCosts",
	"CustodyFees",
	"TrusteeFees",
	"SponsorFees",
	"RoyaltyExpense",
	"LoanPortfolioExpense",
	"PreOpeningCosts",
	"LegalFees",
	"MalpracticeLossContingencyClaimsIncurredNet",
	"InsuranceRecoveries",
	"OtherCostAndExpenseOperating",
	"OperatingCostsAndExpenses",
	"SellingGeneralAndAdministrativeExpenseAbstract",
	"SellingAndMarketingExpenseAbstract",
	"SellingExpense",
	"SalesCommissionsAndFees",
	"MarketingAndAdvertisingExpenseAbstract",
	"MarketingExpense",
	"AdvertisingExpense",
	"MarketingAndAdvertisingExpense",
	"FranchisorCostsAbstract",
	"CostsOfFranchisorOwnedOutlets",
	"CostsOfFranchisedOutlets",
	"FranchisorCosts",
	"ProductWarrantyExpense",
	"OtherSellingAndMarketingExpense",
	"SellingAndMarketingExpense",
	"GeneralAndAdministrativeExpenseAbstract",
	"LaborAndRelatedExpense",
	"RealEstateInsurance",
	"GeneralInsuranceExpense",
	"CommunicationsAndInformationTechnology",
	"OperatingLeaseExpense",
	"TravelAndEntertainmentExpense",
	"TaxesExcludingIncomeAndExciseTaxesAbstract",
	"RealEstateTaxExpense",
	"ProductionTaxExpense",
	"PumpTaxes",
	"TaxesOther",
	"TaxesExcludingIncomeAndExciseTaxes",
	"OtherGeneralAndAdministrativeExpense",
	"GeneralAndAdministrativeExpense",
	"OtherSellingGeneralAndAdministrativeExpense",
	"SellingGeneralAndAdministrativeExpense",
	"ProvisionForDoubtfulAccounts",
	"GainLossOnDispositionOfAssetsAbstract",
	"GainLossOnSaleOfPropertyPlantEquipmentAbstract",
	"GainLossOnSaleOfProperty",
	"GainLossOnDispositionOfAssets",
	"GainLossOnSaleOfPropertyPlantEquipment",
	"GainLossOnDispositionOfIntangibleAssets",
	"GainLossOnTerminationOfLease",
	"SaleAndLeasebackTransactionGainLossNet",
	"GainOrLossOnSaleOfStockInSubsidiaryOrEquityMethodInvesteeAbstract",
	"EquityMethodInvestmentRealizedGainLossOnDisposal",
	"GainOrLossOnSaleOfStockInSubsidiary",
	"GainLossOnSaleOfStockInSubsidiaryOrEquityMethodInvestee",
	"GainLossOnSaleOfBusiness",
	"GainLossOnSaleOfOtherAssets",
	"GainLossOnDispositionOfAssets1",
	"SalesTypeLeaseSellingProfitLoss",
	"DirectFinancingLeaseSellingLoss",
	"OtherGeneralExpense",
	"OperatingExpenses",
	"CostsAndExpenses",
	"OtherOperatingIncome",
	"OperatingIncomeLoss",
	"NonoperatingIncomeExpenseAbstract",
	"InvestmentIncomeNonoperatingAbstract",
	"NonoperatingGainsLossesAbstract",
	"GainLossOnInvestments",
	"GainLossOnSecuritizationOfFinancialAssets",
	"DisposalGroupNotDiscontinuedOperationGainLossOnDisposal",
	"GainOrLossOnSaleOfPreviouslyUnissuedStockBySubsidiaryOrEquityInvesteeNonoperatingIncomeAbstract",
	"GainOrLossOnSaleOfPreviouslyUnissuedStockBySubsidiary",
	"GainOrLossOnSaleOfPreviouslyUnissuedStockByEquityInvestee",
	"GainLossOnSaleOfPreviouslyUnissuedStockBySubsidiaryOrEquityInvesteeNonoperatingIncome",
	"GainLossOnSaleOfInterestInProjects",
	"GainLossOnDerivativeInstrumentsNetPretaxAbstract",
	"GainLossFromPriceRiskManagementActivity",
	"GainLossOnOilAndGasHedgingActivity",
	"GainLossOnDerivativeInstrumentsHeldForTradingPurposesNet",
	"DerivativeInstrumentsNotDesignatedAsHedgingInstrumentsGainLossNet",
	"GainLossOnDerivativeInstrumentsNetPretax",
	"NonoperatingGainsLosses",
	"RoyaltyIncomeNonoperating",
	"RentalIncomeNonoperating",
	"DevelopmentProfitsNonoperating",
	"RecoveryStrandedCosts",
	"LeveragedLeasesIncomeStatementNetIncomeFromLeveragedLeases",
	"InvestmentIncomeNetAbstract",
	"InvestmentIncomeInterestAndDividendAbstract",
	"InvestmentIncomeInterest",
	"InvestmentIncomeDividend",
	"InvestmentIncomeInterestAndDividend",
	"InvestmentIncomeNetAmortizationOfDiscountAndPremiumAbstract",
	"InvestmentIncomeAmortizationOfDiscount",
	"InvestmentIncomeAmortizationOfPremium",
	"InvestmentIncomeNetAmortizationOfDiscountAndPremium",
	"InvestmentIncomeInvestmentExpense",
	"InvestmentIncomeNet",
	"InvestmentIncomeNonoperating",
	"GainLossOnContractTermination",
	"GainLossOnCondemnation",
	"LossFromCatastrophes",
	"PublicUtilitiesAllowanceForFundsUsedDuringConstructionAdditions",
	"ForeignCurrencyTransactionGainLossBeforeTax",
	"NetPeriodicDefinedBenefitsExpenseReversalOfExpenseExcludingServiceCostComponent",
	"OtherNonoperatingIncomeExpenseAbstract",
	"OtherNonoperatingIncome",
	"OtherNonoperatingExpense",
	"OtherNonoperatingIncomeExpense",
	"NonoperatingIncomeExpense",
	"InterestAndDebtExpenseAbstract",
	"InterestExpenseAbstract",
	"InterestExpenseDebt",
	"InterestExpenseCustomerDeposits",
	"DebtRelatedCommitmentFeesAndDebtIssuanceCosts",
	"FinanceLeaseInterestExpense",
	"InterestExpenseRelatedParty",
	"InterestExpenseOther",
	"InterestExpense",
	"GainsLossesOnExtinguishmentOfDebtAbstract",
	"GainsLossesOnExtinguishmentOfDebtBeforeWriteOffOfDeferredDebtIssuanceCost",
	"WriteOffOfDeferredDebtIssuanceCost",
	"GainsLossesOnExtinguishmentOfDebt",
	"InducedConversionOfConvertibleDebtExpense",
	"GainsLossesOnRestructuringOfDebt",
	"GainLossOnRepurchaseOfDebtInstrument",
	"DistributionsOnMandatorilyRedeemableSecurities",
	"InterestAndDebtExpense",
	"IncomeLossFromContinuingOperationsBeforeIncomeTaxesMinorityInterestAndIncomeLossFromEquityMethodInvestments",
	"IncomeLossFromEquityMethodInvestments",
	"IncomeLossFromContinuingOperationsBeforeIncomeTaxesExtraordinaryItemsNoncontrollingInterest",
	"IncomeTaxExpenseBenefit",
	"IncomeLossFromContinuingOperationsIncludingPortionAttributableToNoncontrollingInterest",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxAbstract",
	"DiscontinuedOperationIncomeLossFromDiscontinuedOperationDuringPhaseOutPeriodNetOfTaxAbstract",
	"DiscontinuedOperationIncomeLossFromDiscontinuedOperationDuringPhaseOutPeriodBeforeIncomeTax",
	"DiscontinuedOperationTaxEffectOfIncomeLossFromDiscontinuedOperationDuringPhaseOutPeriod",
	"DiscontinuedOperationIncomeLossFromDiscontinuedOperationDuringPhaseOutPeriodNetOfTax",
	"DiscontinuedOperationGainLossOnDisposalOfDiscontinuedOperationNetOfTaxAbstract",
	"DiscontinuedOperationGainLossFromDisposalOfDiscontinuedOperationBeforeIncomeTax",
	"DiscontinuedOperationTaxEffectOfIncomeLossFromDisposalOfDiscontinuedOperation",
	"DiscontinuedOperationGainLossOnDisposalOfDiscontinuedOperationNetOfTax",
	"DiscontinuedOperationProvisionForLossGainOnDisposalNetOfTaxAbstract",
	"DiscontinuedOperationProvisionForLossGainOnDisposalBeforeIncomeTax",
	"DiscontinuedOperationTaxExpenseBenefitFromProvisionForGainLossOnDisposal",
	"DiscontinuedOperationProvisionForLossGainOnDisposalNetOfTax",
	"DiscontinuedOperationAmountOfAdjustmentToPriorPeriodGainLossOnDisposalNetOfTaxAbstract",
	"DiscontinuedOperationAmountOfAdjustmentToPriorPeriodGainLossOnDisposalBeforeIncomeTax",
	"DiscontinuedOperationTaxEffectOfAdjustmentToPriorPeriodGainLossOnDisposal",
	"DiscontinuedOperationAmountOfAdjustmentToPriorPeriodGainLossOnDisposalNetOfTax",
	"DiscontinuedOperationNatureOfAdjustmentToPriorPeriodGainLossOnDisposal",
	"DiscontinuedOperationIncomeLossFromDiscontinuedOperationBeforeIncomeTax",
	"DiscontinuedOperationTaxEffectOfDiscontinuedOperation",
	"IncomeLossFromDiscontinuedOperationsNetOfTax",
	"ProfitLoss",
	"MinorityInterestInNetIncomeLossOfConsolidatedEntitiesAbstract",
	"NetIncomeLossAttributableToNonredeemableNoncontrollingInterestAbstract",
	"NoncontrollingInterestInNetIncomeLossJointVenturePartnersNonredeemable",
	"NoncontrollingInterestInNetIncomeLossLimitedPartnershipsNonredeemable",
	"NoncontrollingInterestInNetIncomeLossOperatingPartnershipsNonredeemable",
	"NoncontrollingInterestInNetIncomeLossOtherNoncontrollingInterestsNonredeemable",
	"NoncontrollingInterestInNetIncomeLossPreferredUnitHoldersNonredeemable",
	"NetIncomeLossAttributableToNonredeemableNoncontrollingInterest",
	"NetIncomeLossAttributableToRedeemableNoncontrollingInterestAbstract",
	"NoncontrollingInterestInNetIncomeLossJointVenturePartnersRedeemable",
	"NoncontrollingInterestInNetIncomeLossLimitedPartnershipsRedeemable",
	"NoncontrollingInterestInNetIncomeLossOperatingPartnershipsRedeemable",
	"NoncontrollingInterestInNetIncomeLossOtherNoncontrollingInterestsRedeemable",
	"NoncontrollingInterestInNetIncomeLossPreferredUnitHoldersRedeemable",
	"NetIncomeLossAttributableToRedeemableNoncontrollingInterest",
	"NetIncomeLossAttributableToNoncontrollingInterest",
	"NetIncomeLoss",
	"PreferredStockDividendsAndOtherAdjustmentsAbstract",
	"PreferredStockDividendsIncomeStatementImpact",
	"RedeemablePreferredStockDividends",
	"ConvertiblePreferredStockConvertedToOtherSecurities",
	"PreferredStockRedemptionPremium",
	"PreferredStockRedemptionDiscount",
	"PreferredStockConversionsInducements",
	"TemporaryEquityDividendsAdjustment",
	"TemporaryEquityAccretionToRedemptionValueAdjustment",
	"GeneralPartnerDistributions",
	"WarrantDownRoundFeatureDecreaseInNetIncomeLossToCommonShareholderAmount",
	"StockOptionDownRoundFeatureDecreaseInNetIncomeLossToCommonShareholderAmount",
	"PreferredStockConvertibleDownRoundFeatureDecreaseInNetIncomeLossToCommonShareholderAmount",
	"EquityClassifiedWrittenCallOptionModificationDecreaseInNetIncomeLossToCommonShareholderAmount",
	"OtherPreferredStockDividendsAndAdjustments",
	"PreferredStockDividendsAndOtherAdjustments",
	"UndistributedEarningsLossAllocatedToParticipatingSecuritiesBasic",
	"NetIncomeLossAvailableToCommonStockholdersBasic",
	"IncomeAmountsAttributableToReportingEntityDisclosuresAbstract",
	"IncomeLossFromContinuingOperationsAttributableToParentAbstract",
	"IncomeLossFromContinuingOperationsAttributableToNoncontrollingEntity",
	"IncomeLossFromContinuingOperations",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxAttributableToReportingEntityAbstract",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxAttributableToNoncontrollingInterest",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxAttributableToReportingEntity",
	"EarningsPerShareAbstract",
	"EarningsPerShareBasicAbstract",
	"IncomeLossFromContinuingOperationsPerBasicShare",
	"IncomeLossFromDiscontinuedOperationsAndDisposalOfDiscontinuedOperationsNetOfTaxPerBasicShareAbstract",
	"DiscontinuedOperationIncomeLossFromDiscontinuedOperationNetOfTaxPerBasicShare",
	"DiscontinuedOperationGainLossOnDisposalOfDiscontinuedOperationNetOfTaxPerBasicShare",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxPerBasicShare",
	"EarningsPerShareBasic",
	"EarningsPerShareBasicOtherDisclosuresAbstract",
	"WeightedAverageNumberOfSharesOutstandingBasic",
	"EarningsPerShareDilutedAbstract",
	"IncomeLossFromContinuingOperationsPerDilutedShare",
	"IncomeLossFromDiscontinuedOperationsAndDisposalOfDiscontinuedOperationsNetOfTaxPerDilutedShareAbstract",
	"DiscontinuedOperationIncomeLossFromDiscontinuedOperationNetOfTaxPerDilutedShare",
	"DiscontinuedOperationGainLossOnDisposalOfDiscontinuedOperationNetOfTaxPerDilutedShare",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxPerDilutedShare",
	"EarningsPerShareDiluted",
	"EarningsPerShareDilutedOtherDisclosuresAbstract",
	"AntidilutiveSecuritiesExcludedFromComputationOfEarningsPerShareAmount",
	"WeightedAverageNumberOfDilutedSharesOutstanding",
	"WeightedAverageNumberDilutedSharesOutstandingAdjustment",
	"CommonStockDividendsPerShareDeclared",
	"CommonStockDividendsPerShareCashPaid",
	"PartnershipIncomeAbstract",
	"IncomeLossAttributableToParentAbstract",
	"IncomeLossIncludingPortionAttributableToNoncontrollingInterestAbstract",
	"IncomeLossIncludingPortionAttributableToNoncontrollingInterest",
	"IncomeLossAttributableToNoncontrollingInterest",
	"IncomeLossAttributableToParent",
	"NetIncomeLossAllocatedToGeneralPartners",
	"NetIncomeLossAllocatedToLimitedPartners",
	"EarningsPerUnitAbstract",
	"EarningsPerGeneralPartnershipUnitAbstract",
	"IncomeLossFromContinuingOperationsPerOutstandingGeneralPartnershipUnitNetOfTax",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxPerOutstandingGeneralPartnershipUnit1",
	"NetIncomeLossPerOutstandingGeneralPartnershipUnitNetOfTax",
	"EarningsPerGeneralPartnershipUnitOtherDisclosuresAbstract",
	"DistributionsPerGeneralPartnershipUnitOutstanding",
	"WeightedAverageGeneralPartnershipUnitsOutstanding",
	"EarningsPerLimitedPartnershipUnitAbstract",
	"IncomeLossFromContinuingOperationsPerOutstandingLimitedPartnershipUnitBasicNetOfTax",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxPerOutstandingLimitedPartnershipUnitBasic",
	"NetIncomeLossPerOutstandingLimitedPartnershipUnitBasicNetOfTax",
	"NetIncomeLossPerOutstandingLimitedPartnershipUnitOtherDisclosuresAbstract",
	"DistributionsPerLimitedPartnershipUnitOutstanding",
	"WeightedAverageLimitedPartnershipUnitsOutstanding",
	"NetIncomeLossPerOutstandingLimitedPartnershipUnitDilutedAbstract",
	"IncomeLossFromContinuingOperationsNetOfTaxPerOutstandingLimitedPartnershipUnitDiluted",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxPerOutstandingLimitedPartnershipUnitDiluted1",
	"NetIncomeLossNetOfTaxPerOutstandingLimitedPartnershipUnitDiluted",
	"NetIncomeLossPerOutstandingLimitedPartnershipUnitDilutedOtherDisclosuresAbstract",
	"AntidilutiveSecuritiesExcludedFromComputationOfNetIncomePerOutstandingUnitAmount",
	"WeightedAverageLimitedPartnershipUnitsOutstandingDiluted",
	"WeightedAverageNumerDilutedLimitedPartnershipUnitsOutstandingAdjustment",
	"NetIncomeLossPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasicAbstract",
	"IncomeLossFromContinuingOperationsPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasic",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasic",
	"NetIncomeLossPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasic",
	"NetIncomeLossPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasicOtherDisclosuresAbstract",
	"DistributionsPerLimitedPartnershipAndGeneralPartnershipUnitOutstandingBasic",
	"WeightedAverageLimitedPartnershipAndGeneralPartnershipUnitsOutstandingBasic",
	"NetIncomeLossPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasicAndDilutedAbstract",
	"IncomeLossFromContinuingOperationsPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasicAndDiluted",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasicAndDiluted",
	"NetIncomeLossPerOutstandingLimitedPartnershipAndGeneralPartnershipUnitBasicAndDiluted",
	"WeightedAverageNumberOfLimitedPartnershipAndGeneralPartnershipUnitOutstandingBasicAndDiluted",
	"StatementOfFinancialPositionAbstract",
	"StatementTable",
	"StatementClassOfStockAxis",
	"ClassOfStockDomain",
	"CommonClassAMember",
	"CommonClassBMember",
	"CommonClassCMember",
	"CumulativePreferredStockMember",
	"NoncumulativePreferredStockMember",
	"RedeemablePreferredStockMember",
	"NonredeemablePreferredStockMember",
	"ConvertiblePreferredStockMember",
	"NonredeemableConvertiblePreferredStockMember",
	"RedeemableConvertiblePreferredStockMember",
	"ContingentConvertiblePreferredStockMember",
	"PreferredClassAMember",
	"PreferredClassBMember",
	"SeriesAPreferredStockMember",
	"SeriesBPreferredStockMember",
	"SeriesCPreferredStockMember",
	"SeriesDPreferredStockMember",
	"SeriesEPreferredStockMember",
	"SeriesFPreferredStockMember",
	"SeriesGPreferredStockMember",
	"SeriesHPreferredStockMember",
	"StatementLineItems",
	"AssetsAbstract",
	"AssetsCurrentAbstract",
	"CashCashEquivalentsAndShortTermInvestmentsAbstract",
	"CashAndCashEquivalentsAtCarryingValueAbstract",
	"Cash",
	"CashEquivalentsAtCarryingValue",
	"CashAndCashEquivalentsAtCarryingValue",
	"CashAndCashEquivalentsPledgedStatusExtensibleEnumeration",
	"CashAndCashEquivalentsPledgingPurposeExtensibleEnumeration",
	"RestrictedCashAndInvestmentsCurrentAbstract",
	"RestrictedCashAndCashEquivalentsCurrentAbstract",
	"RestrictedCashCurrent",
	"RestrictedCashEquivalentsCurrent",
	"RestrictedCashAndCashEquivalentsAtCarryingValue",
	"RestrictedInvestmentsCurrent",
	"OtherRestrictedAssetsCurrent",
	"RestrictedCashAndInvestmentsCurrent",
	"ShortTermInvestmentsAbstract",
	"EquitySecuritiesFvNi",
	"DebtSecuritiesCurrentAbstract",
	"TradingSecuritiesDebt",
	"AvailableForSaleSecuritiesDebtSecuritiesCurrent",
	"DebtSecuritiesHeldToMaturityAmortizedCostAfterAllowanceForCreditLossCurrentAbstract",
	"HeldToMaturitySecuritiesCurrent",
	"DebtSecuritiesHeldToMaturityAllowanceForCreditLossCurrent",
	"DebtSecuritiesHeldToMaturityAmortizedCostAfterAllowanceForCreditLossCurrent",
	"DebtSecuritiesCurrent",
	"MarketableSecuritiesCurrent",
	"OtherShortTermInvestments",
	"ShortTermInvestments",
	"CashCashEquivalentsAndShortTermInvestments",
	"ReceivablesNetCurrentAbstract",
	"AccountsNotesAndLoansReceivableNetCurrentAbstract",
	"AccountsReceivableNetCurrentAbstract",
	"AccountsReceivableGrossCurrent",
	"AllowanceForDoubtfulAccountsReceivableCurrent",
	"AccountsReceivableNetCurrent",
	"NotesAndLoansReceivableNetCurrentAbstract",
	"NotesAndLoansReceivableGrossCurrent",
	"AllowanceForNotesAndLoansReceivableCurrent",
	"NotesAndLoansReceivableNetCurrent",
	"AccountsNotesAndLoansReceivableNetCurrent",
	"NontradeReceivablesCurrent",
	"UnbilledReceivablesCurrent",
	"DueFromRelatedPartiesCurrent",
	"ReceivablesLongTermContractsOrPrograms",
	"AccountsReceivableFromSecuritization",
	"AccountsAndOtherReceivablesNetCurrent",
	"ReceivablesNetCurrent",
	"FinancingReceivableExcludingAccruedInterestAfterAllowanceForCreditLossCurrentAbstract",
	"FinancingReceivableExcludingAccruedInterestBeforeAllowanceForCreditLossCurrent",
	"FinancingReceivableAllowanceForCreditLossExcludingAccruedInterestCurrent",
	"FinancingReceivableExcludingAccruedInterestAfterAllowanceForCreditLossCurrent",
	"DebtSecuritiesHeldToMaturityExcludingAccruedInterestAfterAllowanceForCreditLossCurrentAbstract",
	"DebtSecuritiesHeldToMaturityExcludingAccruedInterestBeforeAllowanceForCreditLossCurrent",
	"DebtSecuritiesHeldToMaturityAllowanceForCreditLossExcludingAccruedInterestCurrent",
	"DebtSecuritiesHeldToMaturityExcludingAccruedInterestAfterAllowanceForCreditLossCurrent",
	"SalesTypeLeaseNetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossCurrentAbstract",
	"SalesTypeLeaseNetInvestmentInLeaseExcludingAccruedInterestBeforeAllowanceForCreditLossCurrent",
	"SalesTypeLeaseNetInvestmentInLeaseAllowanceForCreditLossExcludingAccruedInterestCurrent",
	"SalesTypeLeaseNetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossCurrent",
	"DirectFinancingLeaseNetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossCurrentAbstract",
	"DirectFinancingLeaseNetInvestmentInLeaseExcludingAccruedInterestBeforeAllowanceForCreditLossCurrent",
	"DirectFinancingLeaseNetInvestmentInLeaseAllowanceForCreditLossExcludingAccruedInterestCurrent",
	"DirectFinancingLeaseNetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossCurrent",
	"NetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossCurrentAbstract",
	"NetInvestmentInLeaseExcludingAccruedInterestBeforeAllowanceForCreditLossCurrent",
	"NetInvestmentInLeaseAllowanceForCreditLossExcludingAccruedInterestCurrent",
	"NetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossCurrent",
	"DebtSecuritiesAvailableForSaleAmortizedCostExcludingAccruedInterestAfterAllowanceForCreditLossCurrentAbstract",
	"DebtSecuritiesAvailableForSaleAmortizedCostExcludingAccruedInterestBeforeAllowanceForCreditLossCurrent",
	"DebtSecuritiesAvailableForSaleAmortizedCostAllowanceForCreditLossExcludingAccruedInterestCurrent",
	"DebtSecuritiesAvailableForSaleAmortizedCostExcludingAccruedInterestAfterAllowanceForCreditLossCurrent",
	"DebtSecuritiesAvailableForSaleExcludingAccruedInterestCurrent",
	"InventoryNetAbstract",
	"InventoryFinishedGoods",
	"InventoryForLongTermContractsOrPrograms",
	"InventoryWorkInProcess",
	"InventoryRawMaterialsAndSupplies",
	"InventoryValuationReserves",
	"InventoryLIFOReserve",
	"InventoryNet",
	"PrepaidExpenseCurrentAbstract",
	"PrepaidInsurance",
	"PrepaidRent",
	"PrepaidAdvertising",
	"PrepaidRoyalties",
	"Supplies",
	"PrepaidInterest",
	"PrepaidTaxes",
	"OtherPrepaidExpenseCurrent",
	"PrepaidExpenseCurrent",
	"ContractWithCustomerAssetNetCurrentAbstract",
	"ContractWithCustomerAssetGrossCurrent",
	"ContractWithCustomerAssetAccumulatedAllowanceForCreditLossCurrent",
	"ContractWithCustomerAssetNetCurrent",
	"CapitalizedContractCostNetCurrent",
	"OtherAssetsCurrent",
	"DeferredCostsCurrentAbstract",
	"DeferredCostsLeasingNetCurrent",
	"DeferredFuelCost",
	"DeferredGasCost",
	"DeferredStormAndPropertyReserveDeficiencyCurrent",
	"DeferredOfferingCosts",
	"OtherDeferredCostsNet",
	"DeferredCostsCurrent",
	"DerivativeInstrumentsAndHedgesAbstract",
	"DerivativeAssetsCurrent",
	"HedgingAssetsCurrent",
	"CommodityContractAssetCurrent",
	"EnergyMarketingContractsAssetsCurrent",
	"DerivativeInstrumentsAndHedges",
	"AssetsOfDisposalGroupIncludingDiscontinuedOperationCurrentAbstract",
	"DisposalGroupIncludingDiscontinuedOperationCashAndCashEquivalents",
	"DisposalGroupIncludingDiscontinuedOperationAccountsNotesAndLoansReceivableNet",
	"DisposalGroupIncludingDiscontinuedOperationInventoryCurrent",
	"DisposalGroupIncludingDiscontinuedOperationOtherCurrentAssets",
	"DisposalGroupIncludingDiscontinuedOperationPrepaidAndOtherAssetsCurrent",
	"DisposalGroupIncludingDiscontinuedOperationGoodwillCurrent",
	"DisposalGroupIncludingDiscontinuedOperationPropertyPlantAndEquipmentCurrent",
	"DisposalGroupIncludingDiscontinuedOperationIntangibleAssetsCurrent",
	"AssetsOfDisposalGroupIncludingDiscontinuedOperationCurrent",
	"AssetsHeldForSaleNotPartOfDisposalGroupCurrentAbstract",
	"TradeAndLoansReceivablesHeldForSaleNetNotPartOfDisposalGroup",
	"AssetsHeldForSaleNotPartOfDisposalGroupCurrentOther",
	"AssetsHeldForSaleNotPartOfDisposalGroupCurrent",
	"NetInvestmentInLeaseCurrent",
	"RegulatoryAssetsCurrent",
	"DebtSecuritiesAvailableForSaleAccruedInterestAfterAllowanceForCreditLossCurrent",
	"FundsHeldForClients",
	"DeferredRentAssetNetCurrent",
	"AdvancesOnInventoryPurchases",
	"AdvanceRoyaltiesCurrent",
	"DepositsAssetsCurrent",
	"BusinessCombinationContingentConsiderationAssetCurrent",
	"AssetsCurrent",
	"AssetsNoncurrentAbstract",
	"InventoryNoncurrentAbstract",
	"InventoryGasInStorageUndergroundNoncurrent",
	"InventoryDrillingNoncurrent",
	"OtherInventoryNoncurrent",
	"InventoryNoncurrent",
	"OperatingLeaseRightOfUseAsset",
	"FinanceLeaseRightOfUseAsset",
	"DebtSecuritiesAvailableForSaleAccruedInterestAfterAllowanceForCreditLossNoncurrent",
	"LeveragedLeasesNetInvestmentInLeveragedLeasesDisclosureInvestmentInLeveragedLeasesNet",
	"PropertyPlantAndEquipmentNetAbstract",
	"PropertyPlantAndEquipmentGrossAbstract",
	"LandAndLandImprovementsAbstract",
	"Land",
	"LandImprovements",
	"LandAndLandImprovements",
	"BuildingsAndImprovementsGross",
	"MachineryAndEquipmentGross",
	"FurnitureAndFixturesGross",
	"CapitalizedComputerSoftwareGross",
	"ConstructionInProgressGross",
	"LeaseholdImprovementsGross",
	"TimberAndTimberlands",
	"PropertyPlantAndEquipmentOther",
	"PropertyPlantAndEquipmentGross",
	"AccumulatedDepreciationDepletionAndAmortizationPropertyPlantAndEquipment",
	"PropertyPlantAndEquipmentNet",
	"PropertyPlantAndEquipmentCollectionsNotCapitalized",
	"OilAndGasPropertySuccessfulEffortMethodNet",
	"OilAndGasPropertyFullCostMethodNet",
	"LongTermInvestmentsAndReceivablesNetAbstract",
	"LongTermInvestmentsAbstract",
	"InvestmentsInAffiliatesSubsidiariesAssociatesAndJointVenturesAbstract",
	"EquityMethodInvestments",
	"AdvancesToAffiliate",
	"InvestmentsInAffiliatesSubsidiariesAssociatesAndJointVentures",
	"DebtSecuritiesNoncurrentAbstract",
	"AvailableForSaleSecuritiesDebtSecuritiesNoncurrent",
	"DebtSecuritiesHeldToMaturityAmortizedCostAfterAllowanceForCreditLossNoncurrentAbstract",
	"HeldToMaturitySecuritiesNoncurrent",
	"DebtSecuritiesHeldToMaturityAllowanceForCreditLossNoncurrent",
	"DebtSecuritiesHeldToMaturityAmortizedCostAfterAllowanceForCreditLossNoncurrent",
	"DebtSecuritiesNoncurrent",
	"EquitySecuritiesFVNINoncurrent",
	"MarketableSecuritiesNoncurrent",
	"InvestmentInPhysicalCommodities",
	"OtherLongTermInvestments",
	"AuctionRateSecuritiesNoncurrent",
	"LongTermInvestments",
	"LongTermAccountsNotesAndLoansReceivableNetNoncurrentAbstract",
	"AccountsReceivableNetNoncurrentAbstract",
	"AccountsReceivableGrossNoncurrent",
	"AllowanceForDoubtfulAccountsReceivableNoncurrent",
	"AccountsReceivableNetNoncurrent",
	"NotesAndLoansReceivableNetNoncurrentAbstract",
	"NotesAndLoansReceivableGrossNoncurrent",
	"AllowanceForNotesAndLoansReceivableNoncurrent",
	"NotesAndLoansReceivableNetNoncurrent",
	"LongTermAccountsNotesAndLoansReceivableNetNoncurrent",
	"DueFromRelatedPartiesNoncurrent",
	"LongTermInvestmentsAndReceivablesNet",
	"AccountsReceivableExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrentAbstract",
	"AccountsReceivableExcludingAccruedInterestBeforeAllowanceForCreditLossNoncurrent",
	"AccountsReceivableAllowanceForCreditLossExcludingAccruedInterestNoncurrent",
	"AccountsReceivableExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrent",
	"FinancingReceivableExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrentAbstract",
	"FinancingReceivableExcludingAccruedInterestBeforeAllowanceForCreditLossNoncurrent",
	"FinancingReceivableAllowanceForCreditLossExcludingAccruedInterestNoncurrent",
	"FinancingReceivableExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrent",
	"DebtSecuritiesHeldToMaturityExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrentAbstract",
	"DebtSecuritiesHeldToMaturityExcludingAccruedInterestBeforeAllowanceForCreditLossNoncurrent",
	"DebtSecuritiesHeldToMaturityAllowanceForCreditLossExcludingAccruedInterestNoncurrent",
	"DebtSecuritiesHeldToMaturityExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrent",
	"SalesTypeLeaseNetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrentAbstract",
	"SalesTypeLeaseNetInvestmentInLeaseExcludingAccruedInterestBeforeAllowanceForCreditLossNoncurrent",
	"SalesTypeLeaseNetInvestmentInLeaseAllowanceForCreditLossExcludingAccruedInterestNoncurrent",
	"SalesTypeLeaseNetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrent",
	"DirectFinancingLeaseNetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrentAbstract",
	"DirectFinancingLeaseNetInvestmentInLeaseExcludingAccruedInterestBeforeAllowanceForCreditLossNoncurrent",
	"DirectFinancingLeaseNetInvestmentInLeaseAllowanceForCreditLossExcludingAccruedInterestNoncurrent",
	"DirectFinancingLeaseNetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrent",
	"NetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrentAbstract",
	"NetInvestmentInLeaseExcludingAccruedInterestBeforeAllowanceForCreditLossNoncurrent",
	"NetInvestmentInLeaseAllowanceForCreditLossExcludingAccruedInterestNoncurrent",
	"NetInvestmentInLeaseExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrent",
	"DebtSecuritiesAvailableForSaleAmortizedCostExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrentAbstract",
	"DebtSecuritiesAvailableForSaleAmortizedCostExcludingAccruedInterestBeforeAllowanceForCreditLossNoncurrent",
	"DebtSecuritiesAvailableForSaleAmortizedCostAllowanceForCreditLossExcludingAccruedInterestNoncurrent",
	"DebtSecuritiesAvailableForSaleAmortizedCostExcludingAccruedInterestAfterAllowanceForCreditLossNoncurrent",
	"DebtSecuritiesAvailableForSaleExcludingAccruedInterestNoncurrent",
	"NetInvestmentInLeaseNoncurrent",
	"Goodwill",
	"IntangibleAssetsNetExcludingGoodwillAbstract",
	"IndefiniteLivedIntangibleAssetsExcludingGoodwill",
	"FiniteLivedIntangibleAssetsNetAbstract",
	"FiniteLivedIntangibleAssetsGross",
	"FiniteLivedIntangibleAssetsAccumulatedAmortization",
	"FiniteLivedIntangibleAssetsNet",
	"IntangibleAssetsNetExcludingGoodwill",
	"PrepaidExpenseNoncurrentAbstract",
	"PrepaidExpenseOtherNoncurrent",
	"PrepaidMineralRoyaltiesNoncurrent",
	"PrepaidExpenseNoncurrent",
	"ContractWithCustomerAssetNetNoncurrentAbstract",
	"ContractWithCustomerAssetGrossNoncurrent",
	"ContractWithCustomerAssetAccumulatedAllowanceForCreditLossNoncurrent",
	"ContractWithCustomerAssetNetNoncurrent",
	"CapitalizedContractCostNetNoncurrent",
	"OtherAssetsNoncurrent",
	"DerivativeInstrumentsAndHedgesNoncurrentAbstract",
	"DerivativeAssetsNoncurrent",
	"HedgingAssetsNoncurrent",
	"CommodityContractAssetNoncurrent",
	"EnergyMarketingContractsAssetsNoncurrent",
	"DerivativeInstrumentsAndHedgesNoncurrent",
	"RegulatedEntityOtherAssetsNoncurrentAbstract",
	"RegulatoryAssetsNoncurrent",
	"SecuritizedRegulatoryTransitionAssetsNoncurrent",
	"DemandSideManagementProgramCostsNoncurrent",
	"UnamortizedLossReacquiredDebtNoncurrent",
	"DecommissioningFundInvestments",
	"AssetRecoveryDamagedPropertyCostsNoncurrent",
	"DeferredStormAndPropertyReserveDeficiencyNoncurrent",
	"InvestmentsInPowerAndDistributionProjects",
	"PhaseInPlanAmountOfCostsDeferredForRateMakingPurposes",
	"UnamortizedDebtIssuanceExpense",
	"RegulatedEntityOtherAssetsNoncurrent",
	"InvestmentsAndOtherNoncurrentAssets",
	"DeferredCostsNoncurrentAbstract",
	"DeferredCostsLeasingNetNoncurrent",
	"DeferredCosts",
	"DepositsAssetsNoncurrent",
	"InsuranceReceivableForMalpracticeNoncurrent",
	"DeferredIncomeTaxAssetsNet",
	"DeferredRentReceivablesNetNoncurrent",
	"AssetsHeldInTrustNoncurrent",
	"DefinedBenefitPlanAssetsForPlanBenefitsNoncurrent",
	"RestrictedCashAndInvestmentsNoncurrentAbstract",
	"RestrictedCashAndCashEquivalentsNoncurrentAbstract",
	"RestrictedCashNoncurrent",
	"RestrictedCashEquivalentsNoncurrent",
	"RestrictedCashAndCashEquivalentsNoncurrent",
	"RestrictedInvestmentsNoncurrent",
	"OtherRestrictedAssetsNoncurrent",
	"RestrictedCashAndInvestmentsNoncurrent",
	"AssetsOfDisposalGroupIncludingDiscontinuedOperationNoncurrentAbstract",
	"DisposalGroupIncludingDiscontinuedOperationOtherNoncurrentAssets",
	"DisposalGroupIncludingDiscontinuedOperationInventoryNoncurrent",
	"DisposalGroupIncludingDiscontinuedOperationDeferredTaxAssets",
	"DisposalGroupIncludingDiscontinuedOperationIntangibleAssetsNoncurrent",
	"DisposalGroupIncludingDiscontinuedOperationGoodwillNoncurrent",
	"DisposalGroupIncludingDiscontinuedOperationPropertyPlantAndEquipmentNoncurrent",
	"DisposalGroupIncludingDiscontinuedOperationAssetsNoncurrent",
	"AdvanceRoyaltiesNoncurrent",
	"BusinessCombinationContingentConsiderationAssetNoncurrent",
	"AmortizationMethodQualifiedAffordableHousingProjectInvestments",
	"AssetsNoncurrent",
	"Assets",
	"AssetsPledgingPurposeExtensibleEnumeration",
	"LiabilitiesAndStockholdersEquityAbstract",
	"LiabilitiesAbstract",
	"LiabilitiesCurrentAbstract",
	"AccountsPayableAndAccruedLiabilitiesCurrentAbstract",
	"AccountsPayableCurrent",
	"AccruedLiabilitiesCurrentAbstract",
	"EmployeeRelatedLiabilitiesCurrent",
	"TaxesPayableCurrentAbstract",
	"AccruedIncomeTaxesCurrent",
	"SalesAndExciseTaxPayableCurrent",
	"AccrualForTaxesOtherThanIncomeTaxesCurrent",
	"TaxesPayableCurrent",
	"InterestAndDividendsPayableCurrent",
	"AccruedLiabilitiesCurrent",
	"AccountsPayableAndAccruedLiabilitiesCurrent",
	"DeferredRevenueAndCreditsCurrentAbstract",
	"ContractWithCustomerLiabilityCurrent",
	"DeferredIncomeCurrent",
	"DeferredRevenueCurrent",
	"DebtCurrentAbstract",
	"ShortTermBorrowingsAbstract",
	"BankOverdrafts",
	"CommercialPaper",
	"BridgeLoan",
	"ConstructionLoan",
	"ShortTermBankLoansAndNotesPayable",
	"ShortTermNonBankLoansAndNotesPayable",
	"OtherShortTermBorrowings",
	"ShortTermBorrowings",
	"ShortTermDebtRecourseStatusExtensibleEnumeration",
	"LongTermDebtAndCapitalLeaseObligationsCurrentAbstract",
	"LongTermDebtCurrentAbstract",
	"SecuredDebtCurrent",
	"ConvertibleDebtCurrent",
	"UnsecuredDebtCurrent",
	"SubordinatedDebtCurrent",
	"ConvertibleSubordinatedDebtCurrent",
	"LongTermCommercialPaperCurrent",
	"LongTermConstructionLoanCurrent",
	"LongtermTransitionBondCurrent",
	"LongtermPollutionControlBondCurrent",
	"JuniorSubordinatedDebentureOwedToUnconsolidatedSubsidiaryTrustCurrent",
	"OtherLongTermDebtCurrent",
	"LinesOfCreditCurrent",
	"NotesAndLoansPayableCurrentAbstract",
	"NotesPayableCurrentAbstract",
	"MediumtermNotesCurrent",
	"ConvertibleNotesPayableCurrent",
	"NotesPayableToBankCurrent",
	"SeniorNotesCurrent",
	"JuniorSubordinatedNotesCurrent",
	"OtherNotesPayableCurrent",
	"NotesPayableCurrent",
	"LoansPayableCurrentAbstract",
	"LoansPayableToBankCurrent",
	"OtherLoansPayableCurrent",
	"LoansPayableCurrent",
	"NotesAndLoansPayableCurrent",
	"SpecialAssessmentBondCurrent",
	"FederalHomeLoanBankAdvancesCurrent",
	"LongTermDebtCurrent",
	"LongTermDebtCurrentRecourseStatusExtensibleEnumeration",
	"FinanceLeaseLiabilityCurrent",
	"LongTermDebtAndCapitalLeaseObligationsCurrent",
	"DebtCurrent",
	"DeferredCompensationLiabilityCurrentAbstract",
	"DeferredCompensationShareBasedArrangementsLiabilityCurrent",
	"DeferredCompensationCashBasedArrangementsLiabilityCurrent",
	"OtherDeferredCompensationArrangementsLiabilityCurrent",
	"DeferredCompensationLiabilityCurrent",
	"DeferredRentCreditCurrent",
	"DerivativeInstrumentsAndHedgesLiabilitiesAbstract",
	"DerivativeLiabilitiesCurrent",
	"HedgingLiabilitiesCurrent",
	"EnergyMarketingContractLiabilitiesCurrent",
	"DerivativeInstrumentsAndHedgesLiabilities",
	"LiabilityForUncertainTaxPositionsCurrent",
	"PostemploymentBenefitsLiabilityCurrent",
	"SecuritiesLoaned",
	"RegulatoryLiabilityCurrent",
	"ProvisionForLossOnContracts",
	"LitigationReserveCurrent",
	"AccruedEnvironmentalLossContingenciesCurrent",
	"AssetRetirementObligationCurrent",
	"AccruedCappingClosurePostClosureAndEnvironmentalCosts",
	"AccruedReclamationCostsCurrent",
	"ProgramRightsObligationsCurrent",
	"DueToRelatedPartiesCurrent",
	"LiabilitiesOfDisposalGroupIncludingDiscontinuedOperationCurrent",
	"DeferredGasPurchasesCurrent",
	"LiabilitiesOfBusinessTransferredUnderContractualArrangementCurrent",
	"OtherLiabilitiesCurrent",
	"CustomerRefundLiabilityCurrent",
	"SelfInsuranceReserveCurrent",
	"AssetAcquisitionContingentConsiderationLiabilityCurrent",
	"BusinessCombinationContingentConsiderationLiabilityCurrent",
	"EntertainmentLicenseAgreementForProgramMaterialLiabilityCurrent",
	"LiabilitiesCurrent",
	"LiabilitiesNoncurrentAbstract",
	"LongTermDebtAndCapitalLeaseObligationsAbstract",
	"LongTermDebtNoncurrentAbstract",
	"LongTermLineOfCredit",
	"CommercialPaperNoncurrent",
	"ConstructionLoanNoncurrent",
	"SecuredLongTermDebt",
	"SubordinatedLongTermDebt",
	"UnsecuredLongTermDebt",
	"ConvertibleDebtNoncurrent",
	"ConvertibleSubordinatedDebtNoncurrent",
	"LongTermTransitionBond",
	"LongTermPollutionControlBond",
	"JuniorSubordinatedDebentureOwedToUnconsolidatedSubsidiaryTrustNoncurrent",
	"LongTermNotesAndLoansAbstract",
	"LongTermNotesPayableAbstract",
	"MediumtermNotesNoncurrent",
	"JuniorSubordinatedLongTermNotes",
	"SeniorLongTermNotes",
	"ConvertibleLongTermNotesPayable",
	"NotesPayableToBankNoncurrent",
	"OtherLongTermNotesPayable",
	"LongTermNotesPayable",
	"LongTermLoansPayableAbstract",
	"LongTermLoansFromBank",
	"OtherLoansPayableLongTerm",
	"LongTermLoansPayable",
	"LongTermNotesAndLoans",
	"SpecialAssessmentBondNoncurrent",
	"LongtermFederalHomeLoanBankAdvancesNoncurrent",
	"OtherLongTermDebtNoncurrent",
	"LongTermDebtNoncurrent",
	"LongTermDebtNoncurrentRecourseStatusExtensibleEnumeration",
	"FinanceLeaseLiabilityNoncurrent",
	"LongTermDebtAndCapitalLeaseObligations",
	"LiabilitiesOtherThanLongTermDebtNoncurrentAbstract",
	"AccountsPayableAndAccruedLiabilitiesNoncurrent",
	"DeferredRevenueAndCreditsNoncurrentAbstract",
	"ContractWithCustomerLiabilityNoncurrent",
	"DeferredIncomeNoncurrent",
	"DeferredRevenueNoncurrent",
	"DeferredCompensationLiabilityClassifiedNoncurrent",
	"PensionAndOtherPostretirementDefinedBenefitPlansLiabilitiesNoncurrentAbstract",
	"DefinedBenefitPensionPlanLiabilitiesNoncurrent",
	"OtherPostretirementDefinedBenefitPlanLiabilitiesNoncurrent",
	"PensionAndOtherPostretirementDefinedBenefitPlansLiabilitiesNoncurrent",
	"AccumulatedDeferredInvestmentTaxCredit",
	"DeferredRentCreditNoncurrent",
	"AssetRetirementObligationsNoncurrentAbstract",
	"MineReclamationAndClosingLiabilityNoncurrent",
	"OilAndGasReclamationLiabilityNoncurrent",
	"AccruedCappingClosurePostClosureAndEnvironmentalCostsNoncurrent",
	"DecommissioningLiabilityNoncurrent",
	"SpentNuclearFuelObligationNoncurrent",
	"AssetRetirementObligationsNoncurrent",
	"DerivativeInstrumentsAndHedgesLiabilitiesNoncurrentAbstract",
	"DerivativeLiabilitiesNoncurrent",
	"HedgingLiabilitiesNoncurrent",
	"EnergyMarketingContractLiabilitiesNoncurrent",
	"DerivativeInstrumentsAndHedgesLiabilitiesNoncurrent",
	"DeferredIncomeTaxLiabilitiesNet",
	"LiabilityForUncertainTaxPositionsNoncurrent",
	"PostemploymentBenefitsLiabilityNoncurrent",
	"AccruedEnvironmentalLossContingenciesNoncurrent",
	"CustomerRefundLiabilityNoncurrent",
	"OffMarketLeaseUnfavorable",
	"LeaseDepositLiability",
	"SharesSubjectToMandatoryRedemptionSettlementTermsAmountNoncurrent",
	"LitigationReserveNoncurrent",
	"RegulatoryLiabilityNoncurrent",
	"RestructuringReserveNoncurrent",
	"DueToRelatedPartiesNoncurrent",
	"LiabilitiesOfDisposalGroupIncludingDiscontinuedOperationNoncurrent",
	"ProgramRightsObligationsNoncurrent",
	"LiabilitiesOfBusinessTransferredUnderContractualArrangementNoncurrent",
	"SelfInsuranceReserveNoncurrent",
	"OtherLiabilitiesNoncurrent",
	"AssetAcquisitionContingentConsiderationLiabilityNoncurrent",
	"BusinessCombinationContingentConsiderationLiabilityNoncurrent",
	"EntertainmentLicenseAgreementForProgramMaterialLiabilityNoncurrent",
	"OperatingLeaseLiabilityNoncurrent",
	"QualifiedAffordableHousingProjectInvestmentsCommitment",
	"LiabilitiesOtherThanLongtermDebtNoncurrent",
	"LiabilitiesNoncurrent",
	"Liabilities",
	"CommitmentsAndContingencies",
	"TemporaryEquityAbstract",
	"TemporaryEquityCarryingAmountIncludingPortionAttributableToNoncontrollingInterestsAbstract",
	"RedeemableNoncontrollingInterestEquityCarryingAmountAbstract",
	"RedeemableNoncontrollingInterestEquityCommonCarryingAmount",
	"RedeemableNoncontrollingInterestEquityPreferredCarryingAmount",
	"RedeemableNoncontrollingInterestEquityOtherCarryingAmount",
	"RedeemableNoncontrollingInterestEquityCarryingAmount",
	"TemporaryEquityCarryingAmountAttributableToParent",
	"TemporaryEquityCarryingAmountIncludingPortionAttributableToNoncontrollingInterests",
	"TemporaryEquityNumberOfSharesRedemptionValueAndOtherDisclosuresAbstract",
	"TemporaryEquityParOrStatedValuePerShare",
	"TemporaryEquityValueExcludingAdditionalPaidInCapital",
	"TemporaryEquitySharesAuthorized",
	"TemporaryEquitySharesIssued",
	"TemporaryEquitySharesOutstanding",
	"TemporaryEquityRedemptionPricePerShare",
	"TemporaryEquityAggregateAmountOfRedemptionRequirement",
	"TemporaryEquityLiquidationPreferencePerShare",
	"TemporaryEquityLiquidationPreference",
	"TemporaryEquityShareSubscriptions",
	"TemporaryEquitySharesSubscribedButUnissued",
	"TemporaryEquitySharesSubscribedButUnissuedSubscriptionsReceivable",
	"StockholdersEquityIncludingPortionAttributableToNoncontrollingInterestAbstract",
	"StockholdersEquityAbstract",
	"PreferredStockValue",
	"PreferredStockSharesSubscribedButUnissuedSubscriptionsReceivable",
	"CommonStockValue",
	"CommonStockHeldBySubsidiary",
	"CommonStockShareSubscribedButUnissuedSubscriptionsReceivable",
	"AdditionalPaidInCapitalAbstract",
	"AdditionalPaidInCapitalCommonStock",
	"AdditionalPaidInCapitalPreferredStock",
	"AdditionalPaidInCapital",
	"OtherAdditionalCapital",
	"TreasuryStockValueAbstract",
	"TreasuryStockCommonValue",
	"TreasuryStockPreferredValue",
	"TreasuryStockValue",
	"TreasuryStockDeferredEmployeeStockOwnershipPlan",
	"DeferredCompensationEquity",
	"AccumulatedOtherComprehensiveIncomeLossNetOfTaxAbstract",
	"AccumulatedOtherComprehensiveIncomeLossForeignCurrencyTranslationAdjustmentNetOfTax",
	"AccumulatedOtherComprehensiveIncomeLossAvailableForSaleSecuritiesAdjustmentNetOfTax",
	"AociLossCashFlowHedgeCumulativeGainLossAfterTax",
	"AccumulatedOtherComprehensiveIncomeLossDefinedBenefitPensionAndOtherPostretirementPlansNetOfTax",
	"AccumulatedOtherComprehensiveIncomeLossFinancialLiabilityFairValueOptionAfterTax",
	"AociDerivativeQualifyingAsHedgeExcludedComponentAfterTax",
	"AccumulatedOtherComprehensiveIncomeLossNetOfTax",
	"RetainedEarningsAccumulatedDeficitAbstract",
	"RetainedEarningsAppropriated",
	"RetainedEarningsUnappropriated",
	"RetainedEarningsAccumulatedDeficit",
	"UnearnedESOPShares",
	"ReceivableFromOfficersAndDirectorsForIssuanceOfCapitalStock",
	"ReceivableFromShareholdersOrAffiliatesForIssuanceOfCapitalStock",
	"StockholdersEquityNoteSubscriptionsReceivable",
	"WarrantsAndRightsOutstanding",
	"StockholdersEquity",
	"MinorityInterest",
	"StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest",
	"StockholdersEquityNumberOfSharesParValueAndOtherDisclosuresAbstract",
	"PreferredStockNumberOfSharesParValueAndOtherDisclosuresAbstract",
	"PreferredStockParOrStatedValuePerShare",
	"PreferredStockNoParValue",
	"PreferredStockSharesSubscribedButUnissuedValue",
	"PreferredStockShareSubscriptions",
	"PreferredStockSharesAuthorized",
	"PreferredStockSharesAuthorizedUnlimited",
	"PreferredStockSharesIssued",
	"PreferredStockSharesOutstanding",
	"PreferredStockValueOutstanding",
	"PreferredStockRedemptionAmount",
	"PreferredStockRedemptionAmountFutureRedeemableSecurities",
	"PreferredStockLiquidationPreferenceValue",
	"PreferredStockLiquidationPreference",
	"PreferredStockConversionBasis",
	"CommonStockNumberOfSharesParValueAndOtherDisclosuresAbstract",
	"CommonStockParOrStatedValuePerShare",
	"CommonStockNoParValue",
	"CommonStockSharesSubscriptions",
	"CommonStockSharesSubscribedButUnissued",
	"CommonStockSharesAuthorized",
	"CommonStockSharesAuthorizedUnlimited",
	"CommonStockSharesIssued",
	"CommonStockSharesOutstanding",
	"CommonStockValueOutstanding",
	"CommonStockOtherSharesOutstanding",
	"CommonStockOtherValueOutstanding",
	"CommonStockConversionBasis",
	"CommonStockIssuedEmployeeStockTrust",
	"CommonStockIssuedEmployeeTrustDeferred",
	"CommonStockHeldInTrust",
	"TreasuryStockNumberOfSharesAndRestrictionDisclosuresAbstract",
	"TreasuryStockSharesAbstract",
	"TreasuryStockCommonShares",
	"TreasuryStockPreferredShares",
	"TreasuryStockShares",
	"TreasuryStockRestrictions",
	"RetainedEarningsDeficitEliminated",
	"PartnersCapitalIncludingPortionAttributableToNoncontrollingInterestAbstract",
	"PartnersCapitalAbstract",
	"GeneralPartnersCapitalAccount",
	"LimitedPartnersCapitalAccount",
	"PreferredUnitsPreferredPartnersCapitalAccounts",
	"OtherPartnersCapital",
	"PartnersCapitalAllocatedForIncomeTaxAndOtherWithdrawals",
	"OtherOwnershipInterestsCapitalAccount",
	"OfferingCostsPartnershipInterests",
	"PartnersCapital",
	"PartnersCapitalAttributableToNoncontrollingInterest",
	"PartnersCapitalIncludingPortionAttributableToNoncontrollingInterest",
	"PartnersCapitalNumberOfUnitsParValueAndOtherDisclosuresAbstract",
	"GeneralPartnersCapitalAccountAbstract",
	"GeneralPartnersCapitalAccountUnitsAuthorized",
	"GeneralPartnersCapitalAccountUnitsIssued",
	"GeneralPartnersCapitalAccountUnitsOutstanding",
	"LimitedPartnersCapitalAccountAbstract",
	"LimitedPartnersCapitalAccountUnitsAuthorized",
	"LimitedPartnersCapitalAccountUnitsIssued",
	"LimitedPartnersCapitalAccountUnitsOutstanding",
	"PreferredUnitsPreferredPartnersCapitalAccountAbstract",
	"PreferredUnitsAuthorized",
	"PreferredUnitsIssued",
	"PreferredUnitsOutstanding",
	"OtherUnitsOtherOwnershipInterestsCapitalAccountAbstract",
	"OtherOwnershipInterestsUnitsAuthorized",
	"OtherOwnershipInterestsUnitsIssued",
	"OtherOwnershipInterestsUnitsOutstanding",
	"LimitedLiabilityCompanyLLCMembersEquityIncludingPortionAttributableToNoncontrollingInterestAbstract",
	"LimitedLiabilityCompanyLLCMembersEquityAbstract",
	"CommonUnitIssued",
	"CommonUnitAuthorized",
	"CommonUnitOutstanding",
	"CommonUnitIssuanceValue",
	"LimitedLiabilityCompanyLLCPreferredUnitIssued",
	"LimitedLiabilityCompanyLLCPreferredUnitAuthorized",
	"LimitedLiabilityCompanyLLCPreferredUnitOutstanding",
	"LimitedLiabilityCompanyLLCPreferredUnitIssuanceValue",
	"MembersEquityAbstract",
	"MembersCapital",
	"NotesReceivableByOwnerToLimitedLiabilityCompanyLLC",
	"MembersEquity",
	"MembersEquityAttributableToNoncontrollingInterest",
	"LimitedLiabilityCompanyLlcMembersEquityIncludingPortionAttributableToNoncontrollingInterest",
	"LiabilitiesAndStockholdersEquity",
	"StatementOfCashFlowsAbstract",
	"StatementTable",
	"StatementLineItems",
	"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseIncludingExchangeRateEffectAbstract",
	"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffectAbstract",
	"NetCashProvidedByUsedInOperatingActivitiesAbstract",
	"NetCashProvidedByUsedInOperatingActivitiesContinuingOperationsAbstract",
	"NetIncomeLossAttributableToReportingEntityAbstract",
	"IncomeLossIncludingPortionAttributableToNoncontrollingInterest",
	"IncomeTaxExpenseBenefitContinuingOperationsDiscontinuedOperationsExtraordinaryItems",
	"ProfitLoss",
	"IncomeLossFromDiscontinuedOperationsNetOfTaxAbstract",
	"DiscontinuedOperationIncomeLossFromDiscontinuedOperationBeforeIncomeTax",
	"DiscontinuedOperationTaxEffectOfDiscontinuedOperation",
	"IncomeLossFromDiscontinuedOperationsNetOfTax",
	"AdjustmentsToReconcileNetIncomeLossToCashProvidedByUsedInOperatingActivitiesAbstract",
	"AdjustmentsNoncashItemsToReconcileNetIncomeLossToCashProvidedByUsedInOperatingActivitiesAbstract",
	"DepreciationAndAmortizationAbstract",
	"DepreciationAbstract",
	"CostOfGoodsAndServicesSoldDepreciation",
	"DepreciationNonproduction",
	"Depreciation",
	"AdjustmentForAmortizationAbstract",
	"CostOfGoodsAndServicesSoldAmortization",
	"AmortizationOfIntangibleAssets",
	"AmortizationOfAcquisitionCosts",
	"AmortizationOfDeferredSalesCommissions",
	"FinanceLeaseRightOfUseAssetAmortization",
	"OperatingLeaseRightOfUseAssetAmortizationExpense",
	"AmortizationOfPowerContractsEmissionCredits",
	"AmortizationOfNuclearFuelLease",
	"AmortizationOfRegulatoryAsset",
	"AmortizationOfAdvanceRoyalty",
	"AmortizationOfDeferredPropertyTaxes",
	"AmortizationOfRateDeferral",
	"AmortizationOfDeferredHedgeGains",
	"AmortizationAndDepreciationOfDecontaminatingAndDecommissioningAssets",
	"OtherAmortizationOfDeferredCharges",
	"AmortizationOfFinancingCostsAndDiscountsAbstract",
	"AmortizationOfDebtDiscountPremium",
	"AmortizationOfFinancingCosts",
	"AmortizationOfFinancingCostsAndDiscounts",
	"AdjustmentForAmortization",
	"OtherDepreciationAndAmortization",
	"DepletionAbstract",
	"CostDepletion",
	"DepletionOfOilAndGasProperties",
	"Depletion",
	"DepreciationDepletionAndAmortization",
	"AccretionExpenseIncludingAssetRetirementObligationsAbstract",
	"AssetRetirementObligationAccretionExpense",
	"AccretionExpense",
	"AccretionExpenseIncludingAssetRetirementObligations",
	"PaidInKindInterest",
	"ProvisionForDoubtfulAccounts",
	"ProductWarrantyExpense",
	"InventoryWriteDown",
	"InventoryLIFOReserveEffectOnIncomeNetAbstract",
	"InventoryLIFOReservePeriodCharge",
	"EffectOfLIFOInventoryLiquidationOnIncome",
	"InventoryLIFOReserveEffectOnIncomeNet",
	"ProvisionForLoanLeaseAndOtherLossesAbstract",
	"ProvisionForOtherCreditLosses",
	"ProvisionForOtherLosses",
	"ProvisionForLoanLeaseAndOtherLosses",
	"EmployeeBenefitsAndShareBasedCompensationAbstract",
	"PensionAndOtherPostretirementBenefitsExpenseReversalOfExpenseNoncashAbstract",
	"PensionExpenseReversalOfExpenseNoncash",
	"OtherPostretirementBenefitsExpenseReversalOfExpenseNoncash",
	"PensionAndOtherPostretirementBenefitsExpenseReversalOfExpenseNoncash",
	"ShareBasedCompensationAbstract",
	"StockOptionPlanExpense",
	"RestrictedStockExpense",
	"ShareBasedCompensation",
	"EmployeeBenefitsAndShareBasedCompensationNoncash",
	"IssuanceOfStockAndWarrantsForServicesOrClaims",
	"AdjustmentOfWarrantsGrantedForServices",
	"FairValueAdjustmentOfWarrants",
	"ResearchAndDevelopmentInProcess",
	"NoncashMergerRelatedCosts",
	"RecapitalizationCosts",
	"ForeignCurrencyTransactionGainLossBeforeTaxAbstract",
	"ForeignCurrencyTransactionGainBeforeTax",
	"ForeignCurrencyTransactionLossBeforeTax",
	"ForeignCurrencyTransactionGainLossBeforeTax",
	"RestructuringCostsAndAssetImpairmentChargesAbstract",
	"RestructuringCostsAbstract",
	"RestructuringChargesAbstract",
	"BusinessExitCosts1",
	"SeveranceCosts1",
	"OtherRestructuringCosts",
	"RestructuringCharges",
	"PaymentsForRestructuring",
	"RestructuringCosts",
	"AssetImpairmentChargesAbstract",
	"ImpairmentOfLongLivedAssetsToBeDisposedOf",
	"ImpairmentOfLongLivedAssetsHeldForUse",
	"ImpairmentOfIntangibleAssetsFinitelived",
	"ImpairmentOfIntangibleAssetsIndefinitelivedExcludingGoodwill",
	"GoodwillImpairmentLoss",
	"FinanceLeaseImpairmentLoss",
	"OperatingLeaseImpairmentLoss",
	"ImpairmentOfOilAndGasProperties",
	"ExplorationAbandonmentAndImpairmentExpense",
	"ImpairmentOfRealEstate",
	"ImpairmentLossesRelatedToRealEstatePartnerships",
	"ImpairmentOfOngoingProject",
	"ImpairmentOfLeasehold",
	"DisposalGroupNotDiscontinuedOperationLossGainOnWriteDown",
	"OtherAssetImpairmentCharges",
	"AssetImpairmentCharges",
	"RestructuringCostsAndAssetImpairmentCharges",
	"NoncashProjectAbandonmentCosts",
	"EnvironmentalExpenseAndLiabilitiesAbstract",
	"EnvironmentalRemediationExpense",
	"PaymentsForEnvironmentalLiabilities",
	"OtherIncreaseDecreaseInEnvironmentalLiabilities",
	"EnvironmentalExpenseAndLiabilities",
	"DisposalGroupNotDiscontinuedOperationGainLossOnDisposal",
	"GainLossOnDispositionOfAssetsAbstract",
	"GainLossOnSaleOfPropertyPlantEquipmentAbstract",
	"GainLossOnSaleOfProperty",
	"GainLossOnDispositionOfAssets",
	"GainLossOnSaleOfPropertyPlantEquipment",
	"GainLossOnSaleOfInvestmentsAbstract",
	"GainOnSaleOfInvestments",
	"LossOnSaleOfInvestments",
	"GainLossOnSaleOfInvestments",
	"GainLossOnDispositionOfIntangibleAssets",
	"GainLossOnTerminationOfLease",
	"SaleAndLeasebackTransactionGainLossNet",
	"GainLossOnSaleOfBusiness",
	"GainLossOnSaleOfOtherAssets",
	"GainLossOnDispositionOfAssets1",
	"SalesTypeLeaseSellingProfitLoss",
	"DirectFinancingLeaseSellingLoss",
	"UnrealizedGainLossOnInvestments",
	"GainLossOnSaleOfProject",
	"PublicUtilitiesAllowanceForFundsUsedDuringConstructionAdditions",
	"GainsLossesOnExtinguishmentOfDebt",
	"IncomeLossFromEquityMethodInvestmentsNetOfDividendsOrDistributionsAbstract",
	"IncomeLossFromEquityMethodInvestments",
	"EquityMethodInvestmentDividendsOrDistributions",
	"IncomeLossFromEquityMethodInvestmentsNetOfDividendsOrDistributions",
	"LeveragedLeasesIncomeStatementNetIncomeFromLeveragedLeases",
	"GainLossOnSaleOfPreviouslyUnissuedStockBySubsidiaryOrEquityInvesteeNonoperatingIncome",
	"DeferredIncomeTaxesAndTaxCreditsAbstract",
	"DeferredIncomeTaxExpenseBenefit",
	"IncomeTaxCreditsAndAdjustments",
	"InvestmentTaxCredit",
	"DeferredTaxExpenseFromStockOptionsExercised",
	"OtherNoncashIncomeTaxExpense",
	"DeferredIncomeTaxesAndTaxCredits",
	"DeferredPurchasedPowerCosts",
	"CarryingCostsPropertyAndExplorationRights",
	"StormDamageProvision",
	"GainLossOnContractTermination",
	"GainLossOnCondemnation",
	"ReclamationAndMineShutdownProvision",
	"ReimbursementFromLimitedPartnershipInvestment",
	"NoncashContributionExpense",
	"OtherNoncashIncomeExpenseAbstract",
	"OtherNoncashIncome",
	"OtherNoncashExpense",
	"OtherNoncashIncomeExpense",
	"BusinessCombinationContingentConsiderationArrangementsChangeInAmountOfContingentConsiderationAsset1",
	"BusinessCombinationContingentConsiderationArrangementsChangeInAmountOfContingentConsiderationLiability1",
	"AdjustmentsNoncashItemsToReconcileNetIncomeLossToCashProvidedByUsedInOperatingActivities",
	"IncreaseDecreaseInOperatingCapitalAbstract",
	"IncreaseDecreaseInOperatingAssetsAbstract",
	"IncreaseDecreaseInContractWithCustomerAsset",
	"IncreaseDecreaseInReceivablesAbstract",
	"IncreaseDecreaseInAccountsAndNotesReceivable",
	"IncreaseDecreaseInAccountsReceivable",
	"IncreaseDecreaseInAccountsAndOtherReceivables",
	"IncreaseDecreaseInNotesReceivableCurrent",
	"IncreaseDecreaseInLongTermReceivablesCurrent",
	"IncreaseDecreaseInLeasingReceivables",
	"IncreaseDecreaseInIncomeTaxesReceivable",
	"IncreaseDecreaseInInterestsContinuedToBeHeldByTransferorCurrent",
	"IncreaseDecreaseInAccruedInterestReceivableNet",
	"IncreaseDecreaseInDueFromRelatedPartiesCurrentAbstract",
	"IncreaseDecreaseInAccountsReceivableRelatedParties",
	"IncreaseDecreaseInNotesReceivableRelatedPartiesCurrent",
	"IncreaseDecreaseInDueFromEmployeeCurrent",
	"IncreaseDecreaseInDueFromOfficersAndStockholdersCurrent",
	"IncreaseDecreaseInDueFromAffiliatesCurrent",
	"IncreaseDecreaseInDueFromOtherRelatedPartiesCurrent",
	"IncreaseDecreaseInDueFromRelatedPartiesCurrent",
	"IncreaseDecreaseInFinanceReceivables",
	"IncreaseDecreaseInInsuranceSettlementsReceivable",
	"IncreaseDecreaseInAccountsReceivableFromSecuritization",
	"IncreaseDecreaseInOtherReceivables",
	"IncreaseDecreaseInReceivables",
	"IncreaseDecreaseInInventoriesAbstract",
	"IncreaseDecreaseInFuelInventoriesAbstract",
	"IncreaseDecreaseInFossilFuelInventoriesAbstract",
	"IncreaseDecreaseInCoalInventories",
	"IncreaseDecreaseInOtherFossilFuelInventories",
	"IncreaseDecreaseInFossilFuelInventories",
	"IncreaseDecreaseInFuelInventories",
	"IncreaseDecreaseInFinishedGoodsAndWorkInProcessInventories",
	"IncreaseDecreaseInInventoryForLongTermContractsOrPrograms",
	"IncreaseDecreaseInRawMaterialsPackagingMaterialsAndSuppliesInventories",
	"IncreaseDecreaseInRetailRelatedInventories",
	"IncreaseDecreaseInInventories",
	"IncreaseDecreaseInMaterialsAndSupplies",
	"IncreaseDecreaseInTradingSecuritiesAbstract",
	"IncreaseDecreaseInEquitySecuritiesFvNi",
	"IncreaseDecreaseInDebtSecuritiesTrading",
	"IncreaseDecreaseInTradingSecurities",
	"IncreaseDecreaseInMortgageLoansHeldForSaleAbstract",
	"ProceedsFromSaleOfMortgageLoansHeldForSale",
	"ProceedsFromCollectionOfMortgageLoansHeldForSale",
	"PaymentsForOriginationOfMortgageLoansHeldForSale",
	"IncreaseDecreaseInMortgageLoansHeldForSale",
	"IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssetsAbstract",
	"IncreaseDecreaseInPrepaidExpense",
	"IncreaseDecreaseInPrepaidRoyalties",
	"IncreaseDecreaseInPrepaidInsurance",
	"IncreaseDecreaseInPrepaidRent",
	"IncreaseDecreaseInPrepaidAdvertising",
	"IncreaseDecreaseInPrepaidSupplies",
	"IncreaseDecreaseInPrepaidInterest",
	"IncreaseDecreaseInPrepaidTaxes",
	"IncreaseDecreaseInPrepaidExpensesOther",
	"IncreaseDecreaseInAssetsHeldForSale",
	"IncreaseDecreaseInFinancialInstrumentsUsedInOperatingActivitiesAbstract",
	"IncreaseDecreaseInRiskManagementAssetsAndLiabilities",
	"IncreaseDecreaseInOtherFinancialInstrumentsUsedInOperatingActivities",
	"IncreaseDecreaseInFinancialInstrumentsUsedInOperatingActivities",
	"IncreaseDecreaseInIntangibleAssetsCurrent",
	"IncreaseDecreaseInDepositOtherAssetsAbstract",
	"IncreaseDecreaseInDepositsOutstanding",
	"IncreaseDecreaseInMarginDepositsOutstanding",
	"IncreaseDecreaseInDepositOtherAssets",
	"IncreaseDecreaseInDeferredIncomeTaxes",
	"IncreaseDecreaseInDeferredFuelCostsAbstract",
	"IncreaseDecreaseInDeferredGasCost",
	"IncreaseDecreaseInDeferredElectricCost",
	"IncreaseDecreaseInDeferredFuelCosts",
	"IncreaseDecreaseInDeferredCharges",
	"IncreaseDecreaseInDeferredLeasingFees",
	"IncreaseDecreaseInPrepaidDeferredExpenseAndOtherAssets",
	"IncreaseDecreaseInDerivativeAssets",
	"IncreaseDecreaseInRecoverablePropertyDamageCosts",
	"IncreaseDecreaseInAccruedCostOfOilAndGasReclamation",
	"IncreaseDecreaseInEnergyTradingActivity",
	"IncreaseDecreaseInRecoverableRefundableGasCosts",
	"IncreaseDecreaseInUnprovedOilAndGasProperty",
	"IncreaseDecreaseInGasImbalanceReceivable",
	"IncreaseDecreaseInOtherRegulatoryAssets",
	"IncreaseDecreaseInBalancingAccountUtility",
	"IncreaseDecreaseInAllowanceForEquityFundsUsedDuringConstruction",
	"IncreaseDecreaseInOtherOperatingAssetsAbstract",
	"IncreaseDecreaseInOtherCurrentAssets",
	"IncreaseDecreaseInOtherNoncurrentAssets",
	"IncreaseDecreaseInOtherOperatingAssets",
	"IncreaseDecreaseInOperatingAssets",
	"IncreaseDecreaseInOperatingLiabilitiesAbstract",
	"IncreaseDecreaseInContractWithCustomerLiability",
	"IncreaseDecreaseInDeferredRevenue",
	"IncreaseDecreaseInAccountsPayableAndAccruedLiabilitiesAbstract",
	"IncreaseDecreaseInAccountsPayableAbstract",
	"IncreaseDecreaseInAccountsPayableTrade",
	"IncreaseDecreaseInNotesPayableCurrent",
	"IncreaseDecreaseInDueToRelatedPartiesCurrentAbstract",
	"IncreaseDecreaseInAccountsPayableRelatedParties",
	"IncreaseDecreaseInNotesPayableRelatedPartiesCurrent",
	"IncreaseDecreaseInDueToOfficersAndStockholdersCurrent",
	"IncreaseDecreaseInDueToEmployeesCurrent",
	"IncreaseDecreaseInDueToAffiliatesCurrent",
	"IncreaseDecreaseInDueToOtherRelatedPartiesCurrent",
	"IncreaseDecreaseInDueToRelatedPartiesCurrent",
	"IncreaseDecreaseInRoyaltiesPayable",
	"IncreaseDecreaseInRetainagePayable",
	"IncreaseDecreaseInConstructionPayables",
	"IncreaseDecreaseInOtherAccountsPayable",
	"IncreaseDecreaseInAccountsPayable",
	"IncreaseDecreaseInAccruedLiabilitiesAbstract",
	"IncreaseDecreaseInInterestPayableNet",
	"IncreaseDecreaseInEmployeeRelatedLiabilitiesAbstract",
	"IncreaseDecreaseInAccruedSalaries",
	"IncreaseDecreaseInWorkersCompensationLiabilities",
	"IncreaseDecreaseInPostemploymentObligations",
	"IncreaseDecreaseInPensionAndPostretirementObligationsAbstract",
	"IncreaseDecreaseInPensionPlanObligations",
	"IncreaseDecreaseInPostretirementObligations",
	"IncreaseDecreaseInPensionAndPostretirementObligations",
	"IncreaseDecreaseInPneumoconiosisBenefitObligationsNoncurrent",
	"IncreaseDecreaseInOtherEmployeeRelatedLiabilities",
	"IncreaseDecreaseInEmployeeRelatedLiabilities",
	"IncreaseDecreaseInOtherAccruedLiabilities",
	"IncreaseDecreaseInAccruedLiabilities",
	"IncreaseDecreaseInOtherAccountsPayableAndAccruedLiabilities",
	"IncreaseDecreaseInAccountsPayableAndAccruedLiabilities",
	"IncreaseDecreaseInDeferredLiabilitiesAbstract",
	"IncreaseDecreaseInDeferredCompensation",
	"IncreaseDecreaseInOtherDeferredLiability",
	"IncreaseDecreaseInDeferredLiabilities",
	"IncreaseDecreaseInDerivativeLiabilities",
	"IncreaseDecreaseInAccruedTaxesPayableAbstract",
	"IncreaseDecreaseInAccruedIncomeTaxesPayable",
	"IncreaseDecreaseInPropertyAndOtherTaxesPayable",
	"IncreaseDecreaseInAccruedTaxesPayable",
	"IncreaseDecreaseInSelfInsuranceReserve",
	"IncreaseDecreaseInRestructuringReserve",
	"IncreaseDecreaseInAssetRetirementObligations",
	"IncreaseDecreaseInProductionParticipationPlanLiability",
	"IncreaseDecreaseInRegulatoryLiabilities",
	"IncreaseDecreaseInRegulatoryClauseRevenue",
	"IncreaseDecreaseInOutstandingChecksOperatingActivities",
	"IncreaseDecreaseInOptionPremiumContractsPayable",
	"IncreaseDecreaseInOperatingLeaseLiability",
	"IncreaseDecreaseInOtherOperatingLiabilitiesAbstract",
	"IncreaseDecreaseInOtherCurrentLiabilities",
	"IncreaseDecreaseInOtherNoncurrentLiabilities",
	"IncreaseDecreaseInOtherOperatingLiabilities",
	"IncreaseDecreaseInOperatingLiabilities",
	"IncreaseDecreaseInCommodityContractAssetsAndLiabilities",
	"IncreaseDecreaseInIncomeTaxesPayableNetOfIncomeTaxesReceivable",
	"IncreaseDecreaseInOverUnderEnergyRecoveryAbstract",
	"IncreaseDecreaseInOverEnergyRecovery",
	"IncreaseDecreaseInUnderEnergyRecovery",
	"IncreaseDecreaseInOverUnderEnergyRecovery",
	"IncreaseDecreaseInRegulatoryAssetsAndLiabilities",
	"IncreaseDecreaseInDerivativeAssetsAndLiabilities",
	"IncreaseDecreaseInOtherOperatingAssetsAndLiabilitiesNetAbstract",
	"IncreaseDecreaseInOtherCurrentAssetsAndLiabilitiesNet",
	"IncreaseDecreaseInOtherNoncurrentAssetsAndLiabilitiesNet",
	"IncreaseDecreaseInOtherOperatingCapitalNet",
	"IncreaseDecreaseInOperatingCapital",
	"OtherOperatingActivitiesCashFlowStatement",
	"AdjustmentsToReconcileNetIncomeLossToCashProvidedByUsedInOperatingActivities",
	"NetCashProvidedByUsedInOperatingActivitiesContinuingOperations",
	"CashProvidedByUsedInOperatingActivitiesDiscontinuedOperations",
	"NetCashProvidedByUsedInOperatingActivities",
	"NetCashProvidedByUsedInInvestingActivitiesAbstract",
	"NetCashProvidedByUsedInInvestingActivitiesContinuingOperationsAbstract",
	"PaymentsForProceedsFromProductiveAssetsAbstract",
	"PaymentsToAcquireProductiveAssetsAbstract",
	"PaymentsToAcquirePropertyPlantAndEquipmentAbstract",
	"PaymentsToAcquireLandHeldForUse",
	"PaymentsToAcquireBuildings",
	"PaymentsForCapitalImprovements",
	"PaymentsToAcquireMachineryAndEquipment",
	"PaymentsToAcquireFurnitureAndFixtures",
	"PaymentsToAcquireOilAndGasPropertyAndEquipmentAbstract",
	"PaymentsToAcquireOilAndGasProperty",
	"PaymentsToAcquireOilAndGasEquipment",
	"PaymentsToAcquireOilAndGasPropertyAndEquipment",
	"PaymentsToExploreAndDevelopOilAndGasProperties",
	"PaymentsToAcquireMiningAssets",
	"PaymentsToAcquireTimberlands",
	"PaymentsToAcquireWaterAndWasteWaterSystemsAbstract",
	"PaymentsToAcquireWaterSystems",
	"PaymentsToAcquireWasteWaterSystems",
	"PaymentsToAcquireWaterAndWasteWaterSystems",
	"PaymentsToAcquireOtherPropertyPlantAndEquipment",
	"PaymentsToAcquirePropertyPlantAndEquipment",
	"PaymentsForSoftwareAbstract",
	"PaymentsToAcquireSoftware",
	"PaymentsToDevelopSoftware",
	"PaymentsForSoftware",
	"PaymentsToAcquireIntangibleAssets",
	"PaymentsToAcquireMineralRights",
	"PaymentsToAcquireEquipmentOnLease",
	"PaymentsToAcquireOtherProductiveAssets",
	"PaymentsToAcquireProductiveAssets",
	"ProceedsFromSaleOfProductiveAssetsAbstract",
	"ProceedsFromSaleOfPropertyPlantAndEquipmentAbstract",
	"ProceedsFromSaleOfLandHeldForUse",
	"ProceedsFromSaleOfBuildings",
	"ProceedsFromSaleOfMachineryAndEquipment",
	"ProceedsFromSaleOfFurnitureAndFixtures",
	"ProceedsFromSaleOfOilAndGasPropertyAndEquipment",
	"ProceedsFromSaleOfWaterSystems",
	"ProceedsFromSaleOfOtherPropertyPlantAndEquipment",
	"ProceedsFromSaleOfPropertyPlantAndEquipment",
	"ProceedsFromSaleOfIntangibleAssets",
	"ProceedsFromSaleOfOtherProductiveAssets",
	"ProceedsFromSaleOfProductiveAssets",
	"PaymentsForProceedsFromProductiveAssets",
	"PaymentsForProceedsFromInvestmentsAbstract",
	"PaymentsToAcquireInvestmentsAbstract",
	"PaymentsToAcquireAvailableForSaleSecuritiesDebt",
	"PaymentsToAcquireEquitySecuritiesFvNi",
	"PaymentsToAcquireHeldToMaturitySecurities",
	"PaymentsToAcquireMarketableSecurities",
	"PaymentsToAcquireReceivablesAbstract",
	"PaymentsToAcquireNotesReceivable",
	"PaymentsToAcquireLeaseReceivables",
	"PaymentsToAcquireLoansReceivable",
	"PaymentsToAcquireFinanceReceivables",
	"PaymentsToAcquireRetainedInterestInSecuritizedReceivables",
	"PaymentsForAdvanceToAffiliate",
	"PaymentsToAcquireOtherReceivables",
	"PaymentsToAcquireReceivables",
	"PaymentsToAcquireRestrictedInvestmentsAbstract",
	"PaymentsToAcquireRestrictedCertificatesOfDeposit",
	"PaymentsToAcquireRestrictedInvestments",
	"PaymentsToAcquireProjects",
	"PaymentsToInvestInDecommissioningFund",
	"PaymentsToAcquireOtherInvestments",
	"PaymentsToAcquireInvestments",
	"ProceedsFromSaleMaturityAndCollectionsOfInvestmentsAbstract",
	"ProceedsFromSaleAndMaturityOfAvailableForSaleSecuritiesAbstract",
	"ProceedsFromSaleOfAvailableForSaleSecuritiesDebt",
	"ProceedsFromMaturitiesPrepaymentsAndCallsOfAvailableForSaleSecurities",
	"ProceedsFromSaleAndMaturityOfAvailableForSaleSecurities",
	"ProceedsFromSaleAndMaturityOfHeldToMaturitySecuritiesAbstract",
	"ProceedsFromSaleOfHeldToMaturitySecurities",
	"ProceedsFromMaturitiesPrepaymentsAndCallsOfHeldToMaturitySecurities",
	"ProceedsFromSaleAndMaturityOfHeldToMaturitySecurities",
	"ProceedsFromSaleAndMaturityOfMarketableSecurities",
	"ProceedsFromSaleAndCollectionOfReceivablesAbstract",
	"ProceedsFromSaleAndCollectionOfNotesReceivablesAbstract",
	"ProceedsFromSaleOfNotesReceivable",
	"ProceedsFromCollectionOfNotesReceivable",
	"ProceedsFromSaleAndCollectionOfNotesReceivable",
	"ProceedsFromSaleAndCollectionOfLeaseReceivablesAbstract",
	"ProceedsFromSaleOfLeaseReceivables",
	"ProceedsFromCollectionOfLeaseReceivables",
	"ProceedsFromSaleAndCollectionOfLeaseReceivables",
	"ProceedsFromSaleAndCollectionOfLoansReceivableAbstract",
	"ProceedsFromSaleOfLoansReceivable",
	"ProceedsFromCollectionOfLoansReceivable",
	"ProceedsFromSaleAndCollectionOfLoansReceivable",
	"ProceedsFromSaleAndCollectionOfFinanceReceivablesAbstract",
	"ProceedsFromSaleOfFinanceReceivables",
	"ProceedsFromCollectionOfFinanceReceivables",
	"ProceedsFromSaleAndCollectionOfFinanceReceivables",
	"ProceedsFromCollectionOfRetainedInterestInSecuritizedReceivables",
	"ProceedsFromCollectionOfAdvanceToAffiliate",
	"ProceedsFromSaleAndCollectionOfOtherReceivablesAbstract",
	"ProceedsFromSaleOfOtherReceivables",
	"ProceedsFromCollectionOfOtherReceivables",
	"ProceedsFromSaleAndCollectionOfOtherReceivables",
	"ProceedsFromSaleAndCollectionOfReceivables",
	"ProceedsFromSaleOfEquitySecuritiesFvNi",
	"ProceedsFromSaleOfRestrictedInvestments",
	"ProceedsFromSaleOfInsuranceInvestments",
	"ProceedsFromSaleOfInvestmentProjects",
	"ProceedsFromDecommissioningFund",
	"ProceedsFromSaleAndMaturityOfOtherInvestments",
	"ProceedsFromSaleMaturityAndCollectionsOfInvestments",
	"PaymentsForProceedsFromLoansReceivableAlternativeAbstract",
	"PaymentsForProceedsFromLoansReceivable",
	"PaymentsForProceedsFromInvestments",
	"PaymentsForProceedsFromBusinessesAndInterestInAffiliatesAbstract",
	"PaymentsToAcquireBusinessesAndInterestInAffiliatesAbstract",
	"PaymentsToAcquireBusinessesNetOfCashAcquiredAbstract",
	"PaymentsToAcquireBusinessesGross",
	"PaymentsToAcquireInProcessResearchAndDevelopment",
	"PaymentsForProceedsFromPreviousAcquisitionAbstract",
	"ProceedsFromPreviousAcquisition",
	"PaymentsForPreviousAcquisition",
	"PaymentsForProceedsFromPreviousAcquisition",
	"OtherPaymentsToAcquireBusinesses",
	"CashAcquiredFromAcquisition",
	"PaymentsToAcquireBusinessesNetOfCashAcquired",
	"PaymentsToAcquireInterestInSubsidiariesAndAffiliatesAbstract",
	"PaymentsToAcquireAdditionalInterestInSubsidiaries",
	"PaymentsToAcquireEquityMethodInvestments",
	"PaymentsToAcquireInterestInJointVenture",
	"PaymentsToAcquireInterestInSubsidiariesAndAffiliates",
	"PaymentsToAcquireBusinessesAndInterestInAffiliates",
	"ProceedsFromDivestitureOfBusinessesAndInterestsInAffiliatesAbstract",
	"ProceedsFromDivestitureOfBusinessesNetOfCashDivestedAbstract",
	"ProceedsFromDivestitureOfBusinesses",
	"CashDivestedFromDeconsolidation",
	"ProceedsFromDivestitureOfBusinessesNetOfCashDivested",
	"ProceedsFromDivestitureOfInterestInSubsidiariesAndAffiliatesAbstract",
	"ProceedsFromDivestitureOfInterestInConsolidatedSubsidiaries",
	"ProceedsFromSaleOfEquityMethodInvestments",
	"ProceedsFromDivestitureOfInterestInJointVenture",
	"ProceedsFromDivestitureOfInterestInSubsidiariesAndAffiliates",
	"ProceedsFromDivestitureOfBusinessesAndInterestsInAffiliates",
	"PaymentsForProceedsFromBusinessesAndInterestInAffiliates",
	"PaymentsForProceedsFromLifeInsurancePoliciesAbstract",
	"PaymentsToAcquireLifeInsurancePolicies",
	"ProceedsFromLifeInsurancePolicies",
	"PaymentsForProceedsFromLifeInsurancePolicies",
	"PaymentsForProceedsFromRemovalCostsAbstract",
	"ProceedsFromRemovalCosts",
	"PaymentsForRemovalCosts",
	"PaymentsForProceedsFromRemovalCosts",
	"PaymentsForProceedsFromNuclearFuelAbstract",
	"ProceedsFromNuclearFuel",
	"PaymentsForNuclearFuel",
	"PaymentsForProceedsFromNuclearFuel",
	"ProceedsFromCollectionOfPaymentsToFundLongtermLoansToRelatedPartiesAbstract",
	"ProceedsFromCollectionOfLongtermLoansToRelatedParties",
	"PaymentsToFundLongtermLoansToRelatedParties",
	"ProceedsFromPaymentsForLongTermLoansForRelatedParties",
	"PaymentsForProceedsFromHedgeInvestingActivitiesAbstract",
	"PaymentsForHedgeInvestingActivities",
	"ProceedsFromHedgeInvestingActivities",
	"PaymentsForProceedsFromHedgeInvestingActivities",
	"PaymentsForProceedsFromDerivativeInstrumentInvestingActivitiesAbstract",
	"PaymentsForDerivativeInstrumentInvestingActivities",
	"ProceedsFromDerivativeInstrumentInvestingActivities",
	"PaymentsForProceedsFromDerivativeInstrumentInvestingActivities",
	"ProceedsFromEquityMethodInvestmentDividendsOrDistributionsReturnOfCapital",
	"PaymentsForInvestmentInRestrictedCashFromPollutionControlBond",
	"ProceedsFromDistributionOfRestrictedCashFromPollutionControlBond",
	"ProceedsFromAdvancesForConstruction",
	"RepaymentsOfAdvancesForConstruction",
	"ProceedsFromContributionInAidOfConstruction",
	"AllowanceForFundsUsedDuringConstructionInvestingActivities",
	"PaymentsToAcquireRoyaltyInterestsInMiningProperties",
	"ProceedsFromInsuranceSettlementInvestingActivities",
	"PaymentsToAcquireManagementContractRights",
	"ProceedsFromSaleOfOtherAssetsInvestingActivities",
	"PaymentsForProceedsFromOtherInvestingActivities",
	"OriginationOfNotesReceivableFromRelatedParties",
	"RepaymentOfNotesReceivableFromRelatedParties",
	"PaymentForContingentConsiderationLiabilityInvestingActivities",
	"OperatingLeasePaymentsUse",
	"ProceedsFromLeasePaymentSalesTypeAndDirectFinancingLeasesInvestingActivity",
	"NetCashProvidedByUsedInInvestingActivitiesContinuingOperations",
	"CashProvidedByUsedInInvestingActivitiesDiscontinuedOperations",
	"NetCashProvidedByUsedInInvestingActivities",
	"NetCashProvidedByUsedInFinancingActivitiesAbstract",
	"NetCashProvidedByUsedInFinancingActivitiesContinuingOperationsAbstract",
	"ProceedsFromRepaymentsOfDebtAbstract",
	"ProceedsFromRepaymentsOfShortTermDebtAbstract",
	"ProceedsFromRepaymentsOfBankOverdrafts",
	"IncreaseDecreaseInBookOverdrafts",
	"PaymentsForProceedsFromDepositOnLoan",
	"IncreaseDecreaseInOutstandingChecksFinancingActivities",
	"ProceedsFromRepaymentsOfLinesOfCredit",
	"ProceedsFromRepaymentsOfCommercialPaper",
	"ProceedsFromShortTermDebtAbstract",
	"ProceedsFromLinesOfCredit",
	"ProceedsFromBankDebt",
	"ProceedsFromIssuanceOfCommercialPaper",
	"ProceedsFromSubordinatedShortTermDebt",
	"ProceedsFromNotesPayable",
	"ProceedsFromConstructionLoansPayable",
	"ProceedsFromOtherShortTermDebt",
	"ProceedsFromShortTermDebt",
	"RepaymentsOfShortTermDebtAbstract",
	"RepaymentsOfLinesOfCredit",
	"RepaymentsOfBankDebt",
	"RepaymentsOfCommercialPaper",
	"RepaymentsOfSubordinatedShortTermDebt",
	"RepaymentsOfNotesPayable",
	"RepaymentsOfConstructionLoansPayable",
	"RepaymentsOfOtherShortTermDebt",
	"RepaymentsOfShortTermDebt",
	"ProceedsFromRepaymentsOfShortTermDebtMaturingInThreeMonthsOrLessAlternativeAbstract",
	"ProceedsFromShortTermDebtMaturingInThreeMonthsOrLess",
	"RepaymentsOfShortTermDebtMaturingInThreeMonthsOrLess",
	"ProceedsFromRepaymentsOfShortTermDebtMaturingInThreeMonthsOrLess",
	"ProceedsFromRepaymentsOfShortTermDebtMaturingInMoreThanThreeMonthsAlternativeAbstract",
	"ProceedsFromShortTermDebtMaturingInMoreThanThreeMonths",
	"RepaymentsOfShortTermDebtMaturingInMoreThanThreeMonths",
	"ProceedsFromRepaymentsOfShortTermDebtMaturingInMoreThanThreeMonths",
	"ProceedsFromRepaymentsOfShortTermDebt",
	"ProceedsFromRepaymentsOfLongTermDebtAndCapitalSecuritiesAbstract",
	"ProceedsFromIssuanceOfLongTermDebtAndCapitalSecuritiesNetAbstract",
	"ProceedsFromIssuanceOfLongTermDebtAbstract",
	"ProceedsFromLongTermLinesOfCredit",
	"ProceedsFromIssuanceOfMediumTermNotes",
	"ProceedsFromIssuanceOfSeniorLongTermDebt",
	"ProceedsFromIssuanceOfSubordinatedLongTermDebt",
	"ProceedsFromConvertibleDebt",
	"ProceedsFromRelatedPartyDebt",
	"ProceedsFromIssuanceOfSecuredTaxExemptDebt",
	"ProceedsFromIssuanceOfSecuredDebt",
	"ProceedsFromIssuanceOfUnsecuredTaxExemptDebt",
	"ProceedsFromIssuanceOfUnsecuredDebt",
	"ProceedsFromIssuanceOfFirstMortgageBond",
	"ProceedsFromPollutionControlBond",
	"ProceedsFromIssuanceOfFinancialServicesObligations",
	"ProceedsFromIssuanceOfOtherLongTermDebt",
	"ProceedsFromIssuanceOfLongTermDebt",
	"ProceedsFromIssuanceOfMandatoryRedeemableCapitalSecurities",
	"ProceedsFromIssuanceOfLongTermDebtAndCapitalSecuritiesNet",
	"RepaymentsOfLongTermDebtAndCapitalSecuritiesAbstract",
	"RepaymentsOfLongTermDebtAbstract",
	"RepaymentsOfLongTermLinesOfCredit",
	"RepaymentsOfMediumTermNotes",
	"RepaymentsOfSeniorDebtAbstract",
	"MaturitiesOfSeniorDebt",
	"EarlyRepaymentOfSeniorDebt",
	"RepaymentsOfSeniorDebt",
	"RepaymentsOfSubordinatedDebtAbstract",
	"MaturitiesOfSubordinatedDebt",
	"EarlyRepaymentOfSubordinatedDebt",
	"RepaymentsOfSubordinatedDebt",
	"RepaymentsOfConvertibleDebt",
	"RepaymentsOfRelatedPartyDebt",
	"RepaymentsOfAssumedDebt",
	"RepaymentsOfUnsecuredTaxExemptDebt",
	"RepaymentsOfSecuredDebt",
	"RepaymentsOfUnsecuredDebt",
	"RepaymentsOfFirstMortgageBond",
	"RepaymentsOfPollutionControlBond",
	"RepaymentsOfOtherLongTermDebt",
	"RepaymentsOfLongTermDebt",
	"FinanceLeasePrincipalPayments",
	"RepaymentsOfMandatoryRedeemableCapitalSecurities",
	"RepaymentsOfLongTermDebtAndCapitalSecurities",
	"ProceedsFromRepaymentsOfSecuredDebt",
	"ProceedsFromRepaymentsOfNotesPayable",
	"ProceedsFromRepaymentsOfFirstMortgageBond",
	"ProceedsFromRepaymentsOfPollutionControlBond",
	"ProceedsFromRepaymentsOfRelatedPartyDebt",
	"ProceedsFromRepaymentsOfOtherLongTermDebt",
	"ProceedsFromRepaymentsOfLongTermDebtAndCapitalSecurities",
	"ProceedsFromRepaymentsOfOtherDebtAbstract",
	"ProceedsFromOtherDebt",
	"RepaymentsOfOtherDebt",
	"ProceedsFromRepaymentsOfOtherDebt",
	"ProceedsFromPaymentsForDepositsAppliedToDebtRetirementsAbstract",
	"ProceedsFromDepositsAppliedToDebtRetirements",
	"PaymentsForDepositsAppliedToDebtRetirements",
	"ProceedsFromPaymentsForDepositsAppliedToDebtRetirements",
	"RepaymentsOfLongtermLoansFromVendors",
	"ProceedsFromRepaymentsOfDebt",
	"PaymentOfFinancingAndStockIssuanceCostsAbstract",
	"PaymentsOfFinancingCostsAbstract",
	"PaymentsOfDebtIssuanceCosts",
	"PaymentsOfLoanCosts",
	"PaymentsOfFinancingCosts",
	"PaymentsOfStockIssuanceCosts",
	"PaymentOfFinancingAndStockIssuanceCosts",
	"PaymentsForProceedsFromHedgeFinancingActivitiesAbstract",
	"PaymentsForHedgeFinancingActivities",
	"ProceedsFromHedgeFinancingActivities",
	"PaymentsForProceedsFromHedgeFinancingActivities",
	"PaymentsForProceedsFromDerivativeInstrumentFinancingActivitiesAbstract",
	"PaymentsForDerivativeInstrumentFinancingActivities",
	"ProceedsFromDerivativeInstrumentFinancingActivities",
	"PaymentsForProceedsFromDerivativeInstrumentFinancingActivities",
	"ProceedsFromPaymentsToMinorityShareholdersAbstract",
	"ProceedsFromMinorityShareholders",
	"PaymentsToMinorityShareholders",
	"ProceedsFromPaymentsToMinorityShareholders",
	"PaymentsForRepurchaseOfRedeemableNoncontrollingInterest",
	"ProceedsFromRepurchaseOfRedeemablePreferredStockAbstract",
	"ProceedsFromIssuanceOfRedeemablePreferredStock",
	"ProceedsFromIssuanceOfRedeemableConvertiblePreferredStock",
	"PaymentsForRepurchaseOfRedeemablePreferredStock",
	"PaymentsForRepurchaseOfRedeemableConvertiblePreferredStock",
	"ProceedsFromRepurchaseOfRedeemablePreferredStock",
	"ProceedsFromRepurchaseOfEquityAbstract",
	"ProceedsFromIssuanceOrSaleOfEquityAbstract",
	"ProceedsFromIssuanceOfCommonStock",
	"ProceedsFromSaleOfTreasuryStock",
	"ProceedsFromIssuanceOfPreferredStockAndPreferenceStock",
	"ProceedsFromIssuanceOfConvertiblePreferredStock",
	"ProceedsFromIssuanceOfPrivatePlacement",
	"ProceedsFromIssuanceInitialPublicOffering",
	"ProceedsFromIssuanceOfSharesUnderIncentiveAndShareBasedCompensationPlansIncludingStockOptionsAbstract",
	"ProceedsFromIssuanceOfSharesUnderIncentiveAndShareBasedCompensationPlans",
	"ProceedsFromStockOptionsExercised",
	"ProceedsFromIssuanceOfSharesUnderIncentiveAndShareBasedCompensationPlansIncludingStockOptions",
	"ProceedsFromStockPlans",
	"ProceedsFromIssuanceOfWarrants",
	"ProceedsFromWarrantExercises",
	"ProceedsFromIssuanceOfCommonLimitedPartnersUnits",
	"ProceedsFromIssuanceOfPreferredLimitedPartnersUnits",
	"ProceedsFromSaleOfInterestInPartnershipUnit",
	"ProceedsFromSaleOfInterestInCorporateUnit",
	"ProceedsFromContributedCapital",
	"ProceedsFromOtherEquity",
	"ProceedsFromIssuanceOrSaleOfEquity",
	"PaymentsForRepurchaseOfEquityAbstract",
	"PaymentsForRepurchaseOfCommonStock",
	"PaymentsForRepurchaseOfPreferredStockAndPreferenceStock",
	"PaymentsForRepurchaseOfConvertiblePreferredStock",
	"PaymentsForRepurchaseOfPrivatePlacement",
	"PaymentsForRepurchaseOfInitialPublicOffering",
	"PaymentsForRepurchaseOfWarrants",
	"PaymentsForRepurchaseOfOtherEquity",
	"PaymentsForRepurchaseOfEquity",
	"ProceedsFromRepurchaseOfEquity",
	"PaymentsOfDividendsAbstract",
	"PaymentsOfOrdinaryDividendsAbstract",
	"PaymentsOfDividendsCommonStock",
	"PaymentsOfDividendsPreferredStockAndPreferenceStock",
	"PaymentsOfDividendsMinorityInterest",
	"PaymentsOfOrdinaryDividends",
	"PaymentsOfCapitalDistribution",
	"PaymentsOfDividends",
	"OriginationOfLoansToEmployeeStockOwnershipPlans",
	"ProceedsFromRepaymentOfLoansByEmployeeStockOwnershipPlans",
	"PaymentsOfDebtRestructuringCosts",
	"PaymentsOfDebtExtinguishmentCosts",
	"PaymentsRelatedToTaxWithholdingForShareBasedCompensation",
	"PaymentsOfDistributionsToAffiliates",
	"ProceedsFromContributionsFromAffiliates",
	"ProceedsFromContributionsFromParent",
	"ProceedsFromPartnershipContribution",
	"PaymentsOfMergerRelatedCostsFinancingActivities",
	"PaymentForContingentConsiderationLiabilityFinancingActivities",
	"ProceedsFromPaymentsForOtherFinancingActivities",
	"NetCashProvidedByUsedInFinancingActivitiesContinuingOperations",
	"CashProvidedByUsedInFinancingActivitiesDiscontinuedOperations",
	"NetCashProvidedByUsedInFinancingActivities",
	"NetCashProvidedByUsedInDiscontinuedOperationsAndContinuingOperationsAbstract",
	"NetCashProvidedByUsedInContinuingOperationsAbstract",
	"NetCashProvidedByUsedInContinuingOperations",
	"NetCashProvidedByUsedInDiscontinuedOperationsAbstract",
	"NetCashProvidedByUsedInDiscontinuedOperations",
	"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseExcludingExchangeRateEffect",
	"EffectOfExchangeRateOnCashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsIncludingDisposalGroupAndDiscontinuedOperationsAbstract",
	"EffectOfExchangeRateOnCashCashEquivalentsRestrictedCashAndRestrictedCashEquivalents",
	"EffectOfExchangeRateOnCashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsDisposalGroupIncludingDiscontinuedOperations",
	"EffectOfExchangeRateOnCashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsIncludingDisposalGroupAndDiscontinuedOperations",
	"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsPeriodIncreaseDecreaseIncludingExchangeRateEffect",
	"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsIncludingDisposalGroupAndDiscontinuedOperations",
	"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalents",
	"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsIncludingDisposalGroupAndDiscontinuedOperationsAbstract",
	"CashCashEquivalentsRestrictedCashAndRestrictedCashEquivalentsDisposalGroupIncludingDiscontinuedOperations",
];
