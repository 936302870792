
import { Box,  Typography, useTheme } from "@mui/material";

export default function TitleCard({ row }) {
	const theme = useTheme();
	return (
		<>
			
			<Box
				p="15px"
				display="flex "
				flexDirection={"column"}
				spacing={15}
				justifyContent="space-between"
				alignItems="left"
			>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={1}
				>
					<Typography
						variant="h5"
						fontWeight="600"
						color={theme.palette.neutral.light}
					>
						Form Type:
					</Typography>
					<Typography
						variant="h4"
						fontWeight="bold"
						color={theme.palette.secondary.main}
					>
						{row.type}
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={1}
				>
					<Typography
						variant="h5"
						fontWeight="600"
						color={theme.palette.neutral.light}
					>
						Date Filed:
					</Typography>
					<Typography
						variant="h4"
						fontWeight="bold"
						color={theme.palette.secondary.main}
					>
						{row.date_filed.toDate().toLocaleString()}
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={1}
				>
					<Typography
						variant="h5"
						fontWeight="600"
						color={theme.palette.neutral.light}

					>
						Price at filing:
					</Typography>
					<Typography
						variant="h4"
						fontWeight="bold"
						color={theme.palette.secondary.main}
					>
						{"$" + row.price["price"].toPrecision(4)}
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={1}
				>
					<Typography
						variant="h5"
						fontWeight="600"
						color={theme.palette.neutral.light}
						mr="10px"
					>
						Industry:
					</Typography>
					<Typography
						variant="h4"
						fontWeight="bold"
						color={theme.palette.secondary.main}
						align="right"
					>
						{row.industry}
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={1}
				>
					<Typography
						variant="h5"
						fontWeight="600"
						color={theme.palette.neutral.light}
					>
						CIK:
					</Typography>
					<Typography
						variant="h4"
						fontWeight="bold"
						color={theme.palette.secondary.main}
					>
						{row.cik}
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={1}
				>
					<Typography
						variant="h5"
						fontWeight="600"
						color={theme.palette.neutral.light}
					>
						Accession Number:
					</Typography>
					<Typography
						variant="h4"
						fontWeight="bold"
						color={theme.palette.secondary.main}
					>
						{row["acc-no"]}
					</Typography>
				</Box>
			</Box>
			
		</>
	)
}