import { firebaseClient, logAnalyticsEvent } from "../firebase/firebaseClient";
import getStripe from "./initializeStripe";
import {
	getFirestore,
	addDoc,
	doc,
	collection,
	onSnapshot,
} from "firebase/firestore";

const type_map = {
	annual: process.env.REACT_APP_ANNUAL_PRICE_CODE,
	monthly: process.env.REACT_APP_MONTHLY_PRICE_CODE,
};
async function createCheckoutSession(uid, type) {
	const db = getFirestore(firebaseClient);
	const userRef = doc(db, "users", uid);
	logAnalyticsEvent("checkout session created", {user_id_uid: uid})

	const checkoutSessionRef = collection(userRef, "checkout_sessions");

	const checkoutSession = await addDoc(checkoutSessionRef, {
		price: type_map[type],
		success_url: window.location.origin + "/checkout-splash",
		cancel_url: window.location.origin,
	});

	onSnapshot(checkoutSession, async (snap) => {
		const { sessionId } = snap.data();
		if (sessionId) {
			const stripe = await getStripe();
			await stripe.redirectToCheckout({ sessionId });
		}
	});
}

export default createCheckoutSession;
