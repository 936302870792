import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { HelmetProvider } from 'react-helmet-async';

import Topbar from "./scenes/global/Sidebar2";
import Discover from "./scenes/discover";
import Pricing from "./scenes/pricing";

import FAQ from "./scenes/faq";
import Following from "./scenes/following";
import Error from "./scenes/error";
import SignIn from "./scenes/signin";
import Details from "./scenes/details";
import Landing from "./scenes/landing";
import CheckoutSplash from "./scenes/checkout-splash";

import {
	doc,
	getDoc,
	setDoc,
	updateDoc,
	getFirestore,
	serverTimestamp,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { firebaseClient, logAnalyticsEvent } from "./firebase/firebaseClient";
import { useAccessLevel } from "./firebase/useAccessLevel";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(firebaseClient);
const db = getFirestore(firebaseClient);

logAnalyticsEvent("Initialization Event");

function App() {
	const [theme, colorMode] = useMode();
	const [selectedRow, setSelectedRow] = useState(null);
	const [user, userLoading] = useAuthState(auth);
	//Date lockouts
	const { maxDate, startDate, setStartDate, endDate, setEndDate, isPremium } =
		useAccessLevel(user);

	useEffect(() => {
		async function updateUserDocument() {
			if (user) {
				const userRef = doc(db, "users", user.uid);
				const userDoc = await getDoc(userRef);

				if (userDoc.exists()) {
					// User already exists, update their info
					await updateDoc(userRef, {
						uid: user.uid,
						email: user.email,
						name: user.displayName,
						provider: user.providerData[0].providerId,
						photo_url: user.photoURL,
						last_login: serverTimestamp(),
					});
				} else {
					// User doesn't exist, create a new document
					await setDoc(doc(db, "users", user.uid), {
						uid: user.uid,
						email: user.email,
						name: user.displayName,
						provider: user.providerData[0].providerId,
						photo_url: user.photoURL,
						created_at: serverTimestamp(),
					});
				}
			}
		}

		updateUserDocument();
	}, [user]);

	return (
		<HelmetProvider>
			<ColorModeContext.Provider value={colorMode}>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Topbar auth={auth} signOut={signOut} />
						<CssBaseline />
						<div className="app">
							<main className="content">
								<Routes>
									<Route path="/" element={<Landing />} />
									<Route
										path="/discover"
										element={
											<Discover
												setSelectedRow={setSelectedRow}
												endDate={endDate}
												maxDate={maxDate}
												isPremium={isPremium}
												startDate={startDate}
												setStartDate={setStartDate}
												setEndDate={setEndDate}
											/>
										}
									/>
									<Route
										path="/details/:id"
										element={
											<Details
												row={selectedRow}
												setSelectedRow={setSelectedRow}
												db={db}
											/>
										}
										errorElement={<Error />}
									/>
									<Route
										path="/pricing"
										element={<Pricing />}
									/>
									<Route path="/faq" element={<FAQ />} />
									<Route
										path="/following"
										element={
											<Following
												user={user}
												userLoading={userLoading}
												setSelectedRow={setSelectedRow}
												maxDate={maxDate}
												isPremium={isPremium}
											/>
										}
									/>
									<Route
										path="/checkout-splash"
										element={<CheckoutSplash />}
									/>
									<Route
										path="/signin"
										element={<SignIn />}
									/>
									<Route path="/*" component={<Error />} />
								</Routes>
							</main>
						</div>
					</LocalizationProvider>
				</ThemeProvider>
			</ColorModeContext.Provider>
		</HelmetProvider>
	);
}

export default App;
