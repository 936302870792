import {
	Box,
	Alert,
  } from "@mui/material";

export default function noData(name) {
	return (
	  <Box p={2}>
		<Alert severity="info">
		  {name} Not Available For This Organization
		</Alert>
	  </Box>
	);
  }